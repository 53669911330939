import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

export const HiddenField = (props) => {
    const { input, ...remainingProps } = props;
    const serverOnlyProps = {};

    return (
        <input {...remainingProps} {...input} id={input.name} type="hidden" {...serverOnlyProps} />
    );
};

const WrappedHiddenField = (props) => <Field component={HiddenField} {...props} />;

WrappedHiddenField.propTypes = {
    name: PropTypes.string.isRequired,
};

export default WrappedHiddenField;
