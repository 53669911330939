import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

// TODO: Refactor rendering of component to better indicate missing props instead of just rendering nothing
const DangerZone = ({
    deleteUrl,
    isDeletable,
    deleteWarning,
    undeletableReason,
    deleteButtonText,
}) => {
    if (
        deleteUrl != undefined ||
        isDeletable != undefined ||
        deleteWarning != undefined ||
        undeletableReason != undefined
    ) {
        return (
            <div className="danger_zone dp-c-danger-zone gs-u-mb0">
                <h2 className="dp-h2 gs-u-mt0">Danger Zone</h2>
                {isDeletable && (
                    <div className="dp-c-deletable">
                        <p>{deleteWarning}</p>
                        <Link to={`${deleteUrl}`} className="dp-o-button--delete-secondary">
                            {deleteButtonText || 'Delete'}
                        </Link>
                    </div>
                )}
                {!isDeletable && (
                    <div>
                        <p>{undeletableReason}</p>
                        <button className="dp-o-button--delete-secondary" disabled type="submit">
                            {deleteButtonText || 'Delete'}
                        </button>
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
};

export default DangerZone;

DangerZone.propTypes = {
    deleteUrl: PropTypes.string.isRequired,
    isDeletable: PropTypes.bool.isRequired,
    deleteWarning: PropTypes.string.isRequired,
    undeletableReason: PropTypes.string,
    deleteButtonText: PropTypes.string.isRequired,
};
