export function pageChangeAnnouncement() {
    let accessibilityHookMessage;
    let myH1;
    let myH1Contents;
    let accessibilityDiv;

    if (typeof window !== 'undefined') {
        myH1 = document.getElementsByTagName('h1')[0];
        if (typeof myH1 !== 'undefined') {
            myH1Contents = myH1.innerText;
            accessibilityHookMessage = `BBC Developer Portal, ${myH1Contents} , View loaded`;
            accessibilityDiv = document.getElementById('accessibility-message');

            if (accessibilityDiv) {
                accessibilityDiv.innerText = accessibilityHookMessage;
            }
        }
    } else {
        accessibilityHookMessage = 'BBC Developer Portal, page change, View loaded';
    }
}
