import React from 'react';
import { SubmitButton, HiddenField } from 'forms';

const DeleteKeyForm = () => {
    return (
        <React.Fragment>
            <SubmitButton
                name="confirm"
                submittingText="Deleting"
                className="dp-o-button dp-o-button--delete"
            >
                Delete API key
            </SubmitButton>

            <HiddenField name="id" data-testid="key_id_field" />
            <HiddenField name="appId" data-testid="app_id_field" />
        </React.Fragment>
    );
};

export default DeleteKeyForm;
