import * as projectService from 'projects/services/projectService';
import {
    loadingProject,
    loadedProject,
    storeProject,
    storeProjectMembers,
    loadingProjectMembers,
    loadedProjectMembers,
} from 'projects/state';
import { throwError } from 'errors/state';

export function fetchProject(projectId) {
    return async (dispatch) => {
        dispatch(loadingProject(projectId));

        try {
            const project = await projectService.fetchProject(projectId);

            dispatch(storeProject(project));
        } catch (error) {
            dispatch(throwError(error.properties.statusCode, error.properties.message));
        }

        dispatch(loadedProject(projectId));
    };
}
export function fetchProjectBySlug(projectSlug) {
    return async (dispatch) => {
        dispatch(loadingProject(projectSlug));

        try {
            const project = await projectService.fetchProjectBySlug(projectSlug);

            dispatch(storeProject(project));
        } catch (error) {
            dispatch(throwError(error.properties.statusCode, error.properties.message));
        }

        dispatch(loadedProject(projectSlug));
    };
}

export function fetchProjectMembers(project) {
    return async (dispatch) => {
        dispatch(loadingProjectMembers());

        try {
            const members = await projectService.fetchProjectMembers(project);

            dispatch(storeProjectMembers(project.slug, members));
        } catch (error) {
            dispatch(throwError(error.properties.statusCode, error.properties.message));
        }

        dispatch(loadedProjectMembers());
    };
}

export function fetchProjectMembersWithAccessCheck(project) {
    return async (dispatch, getState) => {
        dispatch(loadingProjectMembers());

        try {
            const members = await projectService.fetchProjectMembers(project);

            const userId = getState().session.session.user.id;

            if (!members.find((member) => member.id === userId)) {
                const error = {
                    properties: {
                        statusCode: 403,
                        message: 'User is not a project member',
                    },
                };

                throw error;
            }

            dispatch(storeProjectMembers(project.slug, members));
        } catch (error) {
            dispatch(throwError(error.properties.statusCode, error.properties.message));
        }

        dispatch(loadedProjectMembers());
    };
}
