const sortKeysAlphabetically = (keyList) => {
    return keyList.sort((a, b) => {
        if (a.app.displayName < b.app.displayName) {
            return -1;
        }
        if (a.app.displayName > b.app.displayName) {
            return 1;
        }
        return 0;
    });
};

export default (keyList) => {
    let pendingKeys = [];
    let approvedKeys = [];
    let rejectedKeys = [];

    keyList.forEach((key) => {
        switch (key.status) {
            case 'pending':
                pendingKeys.push(key);
                break;
            case 'approved':
                approvedKeys.push(key);
                break;
            case 'revoked':
            case 'rejected':
                rejectedKeys.push(key);
                break;
        }
    });

    pendingKeys = sortKeysAlphabetically(pendingKeys);
    approvedKeys = sortKeysAlphabetically(approvedKeys);
    rejectedKeys = sortKeysAlphabetically(rejectedKeys);
    const newKeyList = pendingKeys.concat(approvedKeys, rejectedKeys);

    return newKeyList;
};
