import React from 'react';

function pluralise(count, noun, suffix = 's') {
    return `${noun}${parseInt(count) !== 1 ? suffix : ''}`;
}

function allPropsAvailable({ requestCount, timeUnit, timePeriods }) {
    const isDefined = (value) => value !== undefined && value !== null && value !== '';

    return isDefined(requestCount) && isDefined(timeUnit) && isDefined(timePeriods);
}

function QuotaDescription({ requestCount, timeUnit, timePeriods, ...props }) {
    const defaultMessage = 'Your API clients will be allowed an unlimited number of requests.';
    const descriptionMessage = `Your API clients will be allowed ${requestCount} ${pluralise(
        requestCount,
        'request',
    )} every ${timePeriods} ${pluralise(timePeriods, timeUnit)}.`;

    const messageOutput = allPropsAvailable({
        requestCount,
        timeUnit,
        timePeriods,
    })
        ? descriptionMessage
        : defaultMessage;

    return (
        <div {...props}>
            <span>{messageOutput}</span>
        </div>
    );
}

export default QuotaDescription;
