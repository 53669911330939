import config from './config';

function getFeatures() {
    const featuresString = process.env['FEATURES'] || config.features || '';
    const features = featuresString.split(',').map((str) => str.trim());
    return features;
}

function isFeatureActive(featureName) {
    const features = getFeatures();
    return features.includes(featureName);
}

export { getFeatures };

export default isFeatureActive;
