/**
 * Load in middleware for the client-side entry point.
 * ADD THE MIDDLEWARE YOU WISH TO LOAD INTO THE CLIENT APPLICATION HERE
 */

import { applyMiddleware } from 'redux';

// Helpers
import { browserHistory } from 'react-router';

// Middleware
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { storeMiddleware as messagesMiddleware } from 'flashMessages';
import { storeMiddleware as sessionsMiddleware } from 'sessions';
import { storeMiddleware as projectsMiddleware } from 'projects';
import { storeMiddleware as appsMiddleware } from 'apps';
import { storeMiddleware as errorsMiddleware } from 'errors';
import { clientMiddleware as redirectMiddleware } from 'common/state/redirect';

export const getMiddleware = () => {
    return [
        thunk,
        routerMiddleware(browserHistory),
        // Custom middleware
        ...messagesMiddleware,
        ...sessionsMiddleware,
        ...projectsMiddleware,
        ...appsMiddleware,
        ...errorsMiddleware({
            skipLocationChangeOnce: true,
        }),
        redirectMiddleware(),
    ];
};

const clientMiddleware = () => {
    const middleware = getMiddleware();

    return applyMiddleware(...middleware);
};

export default clientMiddleware;
