import React, { useEffect } from 'react';
import DocumentTitle from 'common/components/DocumentTitle';
import AppForm from 'apps/components/AppForm';
import { createApp } from 'apps/handlers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from 'projects/actions';

const VIEW_TITLE = 'Create New Application';

const CreateApp = (props) => {
    const projectId = props.params.projectId;
    const dispatch = useDispatch();

    if (projectId) {
        useEffect(() => {
            fetchProject({ dispatch }, projectId);
        }, []);
    }
    const { personalProject, sharedProjects, isLoaded } = useSelector((state) => {
        return {
            personalProject: Object.values(state.projects.projects).find(
                (project) => project.owner !== null,
            ),
            sharedProjects: Object.values(state.projects.projects).filter(
                (project) => project.owner == null,
            ),
            isLoaded: state.projects.loadedProjects,
        };
    });

    if (!isLoaded) {
        return (
            <div>
                <h2>Loading....</h2>
            </div>
        );
    }

    const redirectUrl = props.location.query.redirectUrl;

    return (
        <DocumentTitle title={VIEW_TITLE}>
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item">
                        <h1 className="gs-u-mb++ dp-h1">{VIEW_TITLE}</h1>
                    </div>
                </div>

                <AppForm
                    onSubmit={createApp}
                    redirectUrl={redirectUrl}
                    projectId={projectId}
                    personalProject={personalProject}
                    sharedProjects={sharedProjects}
                />
            </div>
        </DocumentTitle>
    );
};

export default CreateApp;
