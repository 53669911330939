import React from 'react';
import PropTypes from 'prop-types';

const LogoutButton = ({ linkClass, onClick, onKeyDown }) => (
    <a
        href={'/_ppap/logout/bbclogin'}
        className={`${linkClass} gel-nav-item__link gel-nav-item--fr dp-e-logout-button`}
        onClick={onClick}
        onKeyDown={onKeyDown}
    >
        Log out
    </a>
);

const LoginButton = ({ pathActive = false, linkClass, onClick, onKeyDown }) => (
    <a
        href={'/_ppap/login/bbclogin'}
        className={
            `${linkClass} gel-nav-item__link gel-nav-item--fr dp-e-login-button` +
            (pathActive ? ' gel-nav-item__active' : '')
        }
        onClick={onClick}
        onKeyDown={onKeyDown}
    >
        <i className="dp-o-icon dp-o-icon--signin gs-u-mr"></i>Log in
    </a>
);

const AuthButton = (props) => (
    <div>
        {props.loggedIn ? (
            <LogoutButton {...props}></LogoutButton>
        ) : (
            <LoginButton {...props}></LoginButton>
        )}
    </div>
);

AuthButton.propTypes = {
    pathActive: PropTypes.bool,
    linkClass: PropTypes.string,
    loggedIn: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
};

export default AuthButton;
