import { updateAppAttribute, deleteAppAttribute } from 'appAttributes/actions';
import isFeatureActive from 'common/helpers/features';
import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import EditAttributeRow from './EditAttributeRow';

const AppAttributesTable = ({ attributes }) => {
    const methods = useForm();
    const dispatch = useDispatch();

    const [editId, setEditId] = useState(null);

    function handleEditSelection(e, attribute) {
        e.preventDefault();
        setEditId(attribute.id);
    }
    function handleDeleteSelection(e, attribute) {
        e.preventDefault();
        const message =
            'Warning: deleting attributes is permanent and cannot be undone! \n\n' +
            `Click OK if you are sure you want to delete '${attribute.key}'`;
        if (window.confirm(message) === true) {
            dispatch(deleteAppAttribute(attribute));
        }
    }

    function handleClear(e) {
        e.preventDefault();
        setEditId(null);
    }

    function onSubmit(data) {
        dispatch(updateAppAttribute(data));
        setEditId(null);
    }

    return (
        <React.Fragment>
            <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <table className="dp-table__no-margin" data-testid="attributes-table">
                        <tbody>
                            <tr className="dp-table__row gs-o-list-ui__item">
                                <th className="dp-table__cell gel-4/10">Key</th>
                                <th className="dp-table__cell gel-4/10">Value</th>
                                {isFeatureActive('appAttributes') ? (
                                    <th className="dp-table__cell gel-2/10">Action</th>
                                ) : null}
                            </tr>
                            {attributes.map((attribute) => {
                                const isEditing = attribute.id === editId;

                                return (
                                    <React.Fragment key={attribute.key}>
                                        {isEditing ? (
                                            <EditAttributeRow
                                                attribute={attribute}
                                                handleClear={handleClear}
                                            />
                                        ) : (
                                            <tr>
                                                <td className="dp-table__cell">{attribute.key}</td>
                                                <td className="dp-table__cell">
                                                    {attribute.value}
                                                </td>
                                                {isFeatureActive('appAttributes') ? (
                                                    <td className="dp-table__cell">
                                                        <a
                                                            href="#"
                                                            onClick={(e) =>
                                                                handleEditSelection(e, attribute)
                                                            }
                                                        >
                                                            Edit
                                                        </a>
                                                        &nbsp;
                                                        <a
                                                            href="#"
                                                            onClick={(e) =>
                                                                handleDeleteSelection(e, attribute)
                                                            }
                                                        >
                                                            Delete
                                                        </a>
                                                    </td>
                                                ) : null}
                                            </tr>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                </form>
            </FormContext>
        </React.Fragment>
    );
};

export default AppAttributesTable;
