const STORE_ATTRIBUTES = Symbol('STORE_ATTRIBUTES');
const LOADING_ATTRIBUTE_COLLECTION = Symbol('LOADING_ATTRIBUTE_COLLECTION');
const LOADED_ATTRIBUTE_COLLECTION = Symbol('LOADED_ATTRIBUTE_COLLECTION');
const FORGET_ATTRIBUTE = Symbol('FORGET_ATTRIBUTE');

export const initialState = {
    attributes: {},
    loadingCollection: false,
    loadedCollection: false,
};

export default function reducer(state = initialState, action = { type: false }) {
    const attributes = Object.assign({}, state.attributes);

    switch (action.type) {
        case STORE_ATTRIBUTES:
            action.attributes.forEach((attribute) => (attributes[attribute.id] = attribute));
            return Object.assign({}, state, {
                attributes,
            });

        case LOADING_ATTRIBUTE_COLLECTION:
            return Object.assign({}, state, {
                loadingCollection: true,
                loadedCollection: false,
            });

        case LOADED_ATTRIBUTE_COLLECTION:
            return Object.assign({}, state, {
                loadingCollection: false,
                loadedCollection: true,
            });

        case FORGET_ATTRIBUTE:
            delete attributes[action.attributeId];
            return Object.assign({}, state, { attributes });

        default:
            return state;
    }
}

export function storeAttributes(attributes) {
    return {
        type: STORE_ATTRIBUTES,
        attributes,
    };
}

export function loadingAttributeCollection() {
    return {
        type: LOADING_ATTRIBUTE_COLLECTION,
    };
}

export function loadedAttributeCollection() {
    return {
        type: LOADED_ATTRIBUTE_COLLECTION,
    };
}

export function forgetAttribute(attributeId) {
    return {
        type: FORGET_ATTRIBUTE,
        attributeId,
    };
}
