import React from 'react';
import { Link } from 'react-router';

const AppList = ({ apps, keys, products }) => {
    const sortByDisplayName = (appA, appB) => {
        const displayNameA = appA.displayName.toUpperCase();
        const displayNameB = appB.displayName.toUpperCase();
        return displayNameA < displayNameB ? -1 : displayNameA > displayNameB ? 1 : 0;
    };

    const tableRows = apps.sort(sortByDisplayName).map((app) => _renderRow(products, app, keys));

    return (
        <table className="gs-o-list-ui dp-c-app-list dp-table">
            <thead>
                <tr className="dp-table__header">
                    <td className="dp-table__cell">App Name</td>
                    <td className="dp-table__cell">API Keys</td>
                </tr>
            </thead>
            <tbody>{tableRows}</tbody>
        </table>
    );
};

function _renderRow(products, app, keys) {
    const appKeys = keys.filter((key) => {
        return key.app == app.id;
    });

    appKeys.forEach((key) => {
        key.product = products.find((product) => {
            return product.id == key.apiProduct;
        });
    });

    const appId = 'app-' + app.id;
    const keyList = _renderKeyList(appKeys);

    return (
        <tr id={appId} key={appId} className="dp-table__row gs-o-list-ui__item">
            <td className="dp-table__cell">
                <Link to={'/apps/' + app.id}>{app.displayName}</Link>
            </td>

            <td className="dp-table__cell">
                {appKeys.length > 0 && <span>{keyList}</span>}

                {appKeys.length === 0 && <span className="dp-c-app__no-keys">No API keys</span>}
            </td>
        </tr>
    );
}

function _renderKeyList(appKeys) {
    return (
        <ul className="gs-o-list-ui dp-c-key-list">
            {appKeys.map((key) => (
                <li id={'key-' + key.id} key={'key-' + key.id} className="gs-o-list-ui__item">
                    <span className="dp-c-key__product">
                        API key for {key.product ? key.product.displayName : 'unknown product'}
                    </span>
                    <span className={'dp-c-key__status dp-c-key__' + key.status}>{key.status}</span>
                </li>
            ))}
        </ul>
    );
}

export default AppList;
