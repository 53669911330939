import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router';
import RadioButton from 'form-components/RadioButton';

const SharedProjectList = ({ sharedProjects, selected }) => {
    const { errors } = useFormContext();
    return (
        <div>
            {sharedProjects.length > 0 ? (
                <div>
                    <p>
                        Please select a project to assign this app to or&nbsp;
                        <Link
                            to={`/projects/create?redirectUrl=${window.location.pathname}${window.location.search}`}
                        >
                            create a new one
                        </Link>
                        .
                    </p>
                    {sharedProjects.map((project) => {
                        return (
                            <RadioButton
                                name="projectId"
                                value={project.id}
                                label={project.display_name}
                                key={project.id}
                                registerOptions={{ required: 'This field is required.' }}
                            />
                        );
                    })}
                    {errors.projectId && (
                        <div className="gel-form-field__errors">
                            <span role="alert" className="gel-form-field__error">
                                This field is required.
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <p>
                    Team apps should be assigned to a project so other team members can manage them.
                    Please&nbsp;
                    <Link
                        to={`/projects/create?redirectUrl=${window.location.pathname}${window.location.search}`}
                    >
                        create a project
                    </Link>
                    &nbsp;to continue (you will be redirected to this page after creating a project)
                </p>
            )}
        </div>
    );
};

export default SharedProjectList;
