import React from 'react';

import HomeCard from 'home/components/HomeCard';
import config from 'common/helpers/config';

const HomeResourcesSection = () => {
    return (
        <section className="dp-main">
            <h2 className="gs-u-vh gs-u-vh--focusable">Other BBC developer resources</h2>

            <div className="gel-layout gel-layout--equal">
                <div className="gel-1/1 gel-1/2@s gel-1/4@m gs-u-mb gel-layout__item">
                    <HomeCard
                        title="BBC Open Source"
                        href="http://www.bbc.co.uk/opensource/"
                        image={`${config.assetsBaseUrl}/img/gel-all-teams-icon.svg`}
                    >
                        Open source software designed and developed by the BBC.
                    </HomeCard>
                </div>
                <div className="gel-1/1 gel-1/2@s gel-1/4@m gs-u-mb gel-layout__item">
                    <HomeCard
                        title="BBC R&D"
                        href="http://www.bbc.co.uk/rd/"
                        image={`${config.assetsBaseUrl}/img/gel-labs-icon.svg`}
                    >
                        Research & Development in broadcasting and the electronic distribution of
                        audio, visual and audiovisual material.
                    </HomeCard>
                </div>
                <div className="gel-1/1 gel-1/2@s gel-1/4@m gs-u-mb gel-layout__item">
                    <HomeCard
                        title="BBC GEL"
                        href="http://www.bbc.co.uk/gel/"
                        image={`${config.assetsBaseUrl}/img/gel-brand-logo.svg`}
                    >
                        The BBC&#39;s shared design framework which enables us to create consistent
                        and delightful user experiences across all of our Digital Services.
                    </HomeCard>
                </div>
                <div className="gel-1/1 gel-1/2@s gel-1/4@m gs-u-mb gel-layout__item">
                    <HomeCard
                        title="BBC Internet Blog"
                        image={`${config.assetsBaseUrl}/img/bbc-internet-blog.svg`}
                        href="http://www.bbc.co.uk/blogs/internet"
                    >
                        The latest news from the BBC&#39;s online teams.
                    </HomeCard>
                </div>
            </div>
        </section>
    );
};

export default HomeResourcesSection;
