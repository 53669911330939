import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import DocumentTitle from 'common/components/DocumentTitle';
import ProductForm from 'products/components/ProductForm';

import { registerAPIProduct } from 'products/handlers';

const VIEW_TITLE = 'Register API Product';

const CreateProduct = ({ router }) => {
    const projectId = router.params.projectId;

    const dispatch = useDispatch();

    const onSubmit = useCallback((data) => {
        registerAPIProduct(dispatch, data);
    });

    return (
        <DocumentTitle title={VIEW_TITLE}>
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item">
                        <h1 className="gs-u-mb++ dp-h1">{VIEW_TITLE}</h1>
                    </div>
                </div>

                <ProductForm dispatch={dispatch} projectId={projectId} onSubmit={onSubmit} />
            </div>
        </DocumentTitle>
    );
};

export default withRouter(CreateProduct);
