import { routerActions } from 'react-router-redux';

const REDIRECT = Symbol('REDIRECT');

const initialState = false;

export default function reducer(state = initialState, action = { type: false }) {
    switch (action.type) {
        case REDIRECT:
            return action.location;

        default:
            return state;
    }
}

export function redirect(location) {
    return {
        type: REDIRECT,
        location,
    };
}

export function clientMiddleware() {
    return (store) => (next) => (action) => {
        switch (action.type) {
            case REDIRECT:
                store.dispatch(routerActions.push(action.location));
        }
        return next(action);
    };
}
