import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { SelectBasic } from 'forms';

import { updateKeyStatus } from 'keys/actions';

const NewKeyStatusForm = ({ keyObject }) => {
    const keyStatusOptions = [
        {
            value: 'rejected',
            label: 'Rejected',
        },
        {
            value: 'approved',
            label: 'Approved',
        },
    ];

    if (keyObject.status === 'pending') {
        keyStatusOptions.push({
            value: 'pending',
            label: 'Pending',
        });
    }

    if (keyObject.status === 'revoked') {
        keyStatusOptions.push({
            value: 'revoked',
            label: 'Revoked',
        });
    }

    const methods = useForm({
        defaultValues: {
            status: keyObject.status,
        },
    });

    const dispatch = useDispatch();

    const onSubmit = (data) => {
        data.id = keyObject.id;

        dispatch(updateKeyStatus(data));
    };

    const originalStatus = keyObject.status;
    const currentStatus = methods.watch('status');
    const isOriginalStatusUnchanged = originalStatus === currentStatus;

    return (
        <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <SelectBasic
                    name="status"
                    id="status"
                    options={keyStatusOptions}
                    includeBlank={false}
                    additionalClassNames="gel-form__input--width-auto"
                />
                &nbsp;
                <button
                    type="submit"
                    disabled={isOriginalStatusUnchanged}
                    className="dp-o-button--primary dp-o-button--padded"
                >
                    Update status
                </button>
            </form>
        </FormContext>
    );
};

export default NewKeyStatusForm;
