import React from 'react';
import { useFormContext } from 'react-hook-form';

const SelectBasic = ({ name, options, additionalClassNames, includeBlank = true }) => {
    const { register } = useFormContext();
    const className = 'gel-form__input ' + String(additionalClassNames);

    return (
        <select
            id={name}
            name={name}
            type="text"
            ref={register}
            className={className}
            aria-describedby={`${name}-field-help`}
        >
            {includeBlank && <option value="">Select an option</option>}

            {options.map((option, index) => {
                return (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                );
            })}
        </select>
    );
};

export default SelectBasic;
