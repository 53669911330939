import { storeApp } from 'apps/state';
import {
    loadingKeyCollection,
    loadedKeyCollection,
    storeKey,
    storeKeys,
    loadingKey,
    loadedKey,
} from 'keys/state';
import { addMessage, flushAllMessages } from 'flashMessages/state';

import * as keysService from 'keys/services/keysService';
import { throwError } from 'errors/state';

export function fetchKeysForProject(projectId) {
    return async (dispatch) => {
        dispatch(loadingKeyCollection());

        try {
            const keys = await keysService.fetchKeysForProject(projectId);
            dispatch(storeKeys(keys));
        } catch (error) {
            dispatch(throwError(error.properties.statusCode, error.properties.message));
        }

        dispatch(loadedKeyCollection());
    };
}

export const fetchProductKey = (productId, keyId) => {
    return async (dispatch) => {
        dispatch(loadingKey(keyId));
        try {
            const { app, key } = await keysService.fetchKeyForProductWithApp(productId, keyId);

            dispatch(storeApp(app));
            dispatch(storeKey(key));
        } catch (err) {
            dispatch(throwError(err.properties.statusCode, err.message));
        }

        dispatch(loadedKey(keyId));
    };
};

export const updateKeyStatus = (key) => {
    return async (dispatch) => {
        try {
            const updatedKey = await keysService.updateKeyStatus(key);
            const successMessage = `Successfully updated the key status to '${updatedKey.status}'. It can take up to an hour for this change to propagate to api-management proxy.`;
            dispatch(storeKey(updatedKey));
            dispatch(flushAllMessages());
            dispatch(addMessage(successMessage));
        } catch (err) {
            const message = 'ERROR: Failed to update key status.';
            dispatch(flushAllMessages());
            dispatch(addMessage(message, err));
        }
    };
};
