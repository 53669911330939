// React
import React, { useEffect } from 'react';
import DocumentTitle from 'common/components/DocumentTitle';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { enableForm } from 'forms/helpers/enableForm';
import { moveAppProject } from 'apps/handlers';
import { moveAppProjectRules } from 'apps/validation/rules';
import messages from 'apps/validation/messages';

// State
import { fetchApp } from 'apps/services/appsService';
import { fetchMyProjects } from 'projects/services/projectService';

// Components
import AppMoveProjectForm from 'apps/components/AppMoveProjectForm';
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder';

// Helpers
import filterMineProjects from 'projects/helpers/filterMineProjects';

const formOptions = {
    formId: 'app-move-project',
    rules: moveAppProjectRules,
    messages,
    onValidated: moveAppProject,
};
const FormEnabledAppMoveProject = enableForm(formOptions)(AppMoveProjectForm);

const AppMoveProject = ({ params }) => {
    const dispatch = useDispatch();

    const { app, projects } = useSelector((state) => {
        return {
            app: state.apps.apps[params.id] || {},
            projects: filterMineProjects(state.projects.projects, state.projects.mine),
        };
    });

    useEffect(() => {
        const store = { dispatch };
        const appId = params.id;

        fetchApp(store, appId);
        fetchMyProjects(store);
    }, []);

    const VIEW_TITLE = `Move "${app.displayName || 'app'}" to a different project`;

    return (
        <DocumentTitle title={VIEW_TITLE}>
            <LoadingPlaceholder waitFor={app}>
                <LoadingPlaceholder waitFor={projects}>
                    <div className="dp-main dp-c-create-key">
                        <h1 className="dp-h1">{VIEW_TITLE}</h1>

                        <div className="gel-layout">
                            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                                <FormEnabledAppMoveProject
                                    projects={projects}
                                    app={app}
                                    buttonVerb="Confirm"
                                    submittingText="Processing..."
                                    initialValues={{ id: params.id }}
                                />
                            </div>
                        </div>
                    </div>
                </LoadingPlaceholder>
            </LoadingPlaceholder>
        </DocumentTitle>
    );
};

export default AppMoveProject;
