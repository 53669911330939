import React from 'react';
import { useForm } from 'react-hook-form';

export default function RequestProductKeyForm({
    appsForUserList,
    projectList,
    productId,
    onSubmit,
}) {
    const { handleSubmit, register, watch } = useForm();
    const watchSelectedApp = watch('app');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <table className="gs-o-list-ui dp-table requestKeyAppTable">
                <thead>
                    <tr className="dp-table__header">
                        <td className="dp-table__cell"></td>
                        <td className="dp-table__cell">App name</td>
                        <td className="dp-table__cell">Project</td>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(appsForUserList).map((appName) => {
                        const app = appsForUserList[appName];
                        const projectSlug = Object.keys(projectList).find((name) => {
                            return projectList[name].id === app.project;
                        });
                        const project = projectList[projectSlug];
                        const projectName = project.owner
                            ? 'Personal Project'
                            : project.display_name;
                        return (
                            <tr className="dp-table__row gs-o-list-ui__item" key={app.id}>
                                <td className="dp-table__cell">
                                    <input
                                        type="radio"
                                        name="app"
                                        id={app.id}
                                        value={app.id}
                                        ref={register}
                                    />
                                </td>
                                <td className="dp-table__cell">
                                    <label for={app.id}>{app.displayName}</label>
                                </td>
                                <td className="dp-table__cell">{projectName || 'Unknown'}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <input type="hidden" name="product" value={productId} ref={register} />
            <input
                className="dp-o-button--primary dp-o-button--full"
                aria-label="submit"
                type="submit"
                value="Submit Request"
                disabled={watchSelectedApp === ''}
            />
        </form>
    );
}
