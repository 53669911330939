import React from 'react';

const HomeCard = ({ href, title, image, imageAlt = '', children }) => (
    <div
        className={'dp-o-home-card ' + (href ? 'gs-o-faux-block-link' : 'dp-o-home-card--no-link')}
    >
        {image && (
            <div className="dp-o-home-card__image">
                <img src={image} alt={imageAlt} />
            </div>
        )}

        <div className="dp-o-home-card__text">
            <h3>
                {href && (
                    <a href={href} className="gs-o-faux-block-link__overlay-link">
                        {title}
                    </a>
                )}

                {!href && <span>{title}</span>}
            </h3>

            <p>{children}</p>
        </div>
    </div>
);

export default HomeCard;
