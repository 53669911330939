import UiApiClient from 'common/helpers/uiApiClient';
import { setSession } from 'sessions/state';

export async function isActiveSessionValid({ dispatch, getState }, { Client = UiApiClient } = {}) {
    const hasActiveSession = getState().session.session;

    if (!hasActiveSession) {
        return true;
    }

    const uiApiClient = new Client({ dispatch, getState });

    try {
        const validityResult = await uiApiClient.head('pp-status', {
            fullResponse: true,
        });

        if (validityResult.status !== 204) {
            throw new Error('Unable to validate session');
        }
    } catch (err) {
        return false;
    }

    return true;
}

export async function populateSession(store, { Client = UiApiClient }) {
    const session = store.getState().session.session;

    if (session) {
        return true;
    }

    const uiApiClient = new Client(store);
    const userProfile = await uiApiClient.put('/users/identity');
    store.dispatch(setSession({ user: userProfile.properties }));
}
