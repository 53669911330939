import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

export const InputField = (passedProps) => {
    const {
        input,
        label,
        describedBy = false,
        type = 'text',
        meta,
        required = false,
        placeholder = false,
    } = passedProps;
    const { touched, error } = meta;

    const describedByProp = (describedBy ? describedBy + ' ' : '') + input.name + '-field-alert';
    const fieldInvalid = error ? true : false;

    if (type === 'password') {
        input.autoComplete = 'off';
    }

    return (
        <div className={'gel-form__field' + (touched && error ? ' is-invalid' : '')}>
            <label htmlFor={input.name} className="gel-form__label">
                {label}
            </label>
            <div>
                <input
                    {...input}
                    id={input.name}
                    placeholder={placeholder || label}
                    type={type}
                    className="gel-form__input"
                    aria-required={required}
                    aria-describedby={describedByProp}
                    aria-invalid={fieldInvalid}
                />
                <div className="gel-form-field__errors">
                    {touched && error && (
                        <span
                            role="alert"
                            id={input.name + '-field-alert'}
                            className="gel-form-field__error"
                        >
                            {error}
                        </span>
                    )}
                    <span>&nbsp;</span>
                </div>
            </div>
        </div>
    );
};

const WrappedInputField = (props) => <Field component={InputField} {...props} />;

export default WrappedInputField;

InputField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    meta: PropTypes.object,
};
