import React, { useEffect } from 'react';
import { useStore, useSelector } from 'react-redux';
import DocumentTitle from 'common/components/DocumentTitle';
import { enableForm } from 'forms/helpers/enableForm';
import AppEditForm from 'apps/components/AppEditForm';
import { editAppRules } from 'apps/validation/rules';
import { updateApp } from 'apps/handlers';
import customErrorMessages from 'apps/validation/messages';
import { fetchApp } from 'apps/services/appsService';
import ErrorNotFound from 'errors/components/ErrorNotFound';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';

const VIEW_TITLE = 'Edit Application';

const formOptions = {
    formId: 'edit-app',
    rules: editAppRules,
    messages: customErrorMessages,
    onValidated: updateApp,
};

const FormEnabledEditApp = enableForm(formOptions)(AppEditForm);

const EditApp = ({ params }) => {
    const store = useStore();

    useEffect(() => {
        fetchApp(store, params.id);
    }, []);

    const { app, appLoaded } = useSelector((state) => ({
        app: state.apps.apps && state.apps.apps[params.id] ? state.apps.apps[params.id] : {},
        appLoaded: state.apps.loaded[params.id],
    }));

    if (appLoaded && !app.id) return <ErrorNotFound />;

    const initialValues = {
        id: params.id,
        displayName: app && app.displayName ? app.displayName : '',
        description: app && app.description ? app.description : '',
    };

    return (
        <DocumentTitle title={VIEW_TITLE}>
            <LoadingPlaceholder waitFor={app.id}>
                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item">
                            <h1 className="gs-u-mb++ dp-h1">{VIEW_TITLE}</h1>
                        </div>
                    </div>

                    <FormEnabledEditApp
                        initialValues={initialValues}
                        app={app}
                        buttonVerb="Update"
                        submittingText="Updating app..."
                    />
                </div>
            </LoadingPlaceholder>
        </DocumentTitle>
    );
};

export default EditApp;
