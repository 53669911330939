import React, { useEffect } from 'react';

import DocumentTitle from 'common/components/DocumentTitle';
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder';
import MoveProductForm from '../components/MoveProductForm';
import { fetchProduct } from 'products/services/productsService';
import { fetchMyProjects } from 'projects/services/projectService';
import { useDispatch, useSelector } from 'react-redux';

const MoveProduct = ({ params }) => {
    const dispatch = useDispatch();

    const store = { dispatch };
    const productId = params.productId;

    useEffect(() => {
        fetchProduct(store, productId);
        fetchMyProjects(store);
    }, []);

    const { product, projects } = useSelector((state) => {
        return {
            product: state.products.products[productId],
            projects: state.projects.projects,
        };
    });

    return (
        <DocumentTitle title="Move product">
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item gel-1/1 gel-8/12@l">
                        <LoadingPlaceholder waitFor={product} placeholder="Loading product ...">
                            <LoadingPlaceholder
                                waitFor={projects}
                                placeholder="Loading projects ..."
                            >
                                <MoveProductForm product={product} projects={projects} />
                            </LoadingPlaceholder>
                        </LoadingPlaceholder>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default MoveProduct;
