import React from 'react';
import { connect } from 'react-redux';
import DisplayMessages from 'flashMessages/components/DisplayMessages';

// React components
import ErrorHandler from 'errors/components/ErrorHandler';
import Header from 'layout/components/Header';
import Footer from 'layout/components/Footer';
import NavBar from 'menu/containers/NavBar';
import { pageChangeAnnouncement } from 'common/helpers/accessibility';

class ApplicationLayout extends React.Component {
    render() {
        const { loggedIn, activePathname } = this.props;
        let { errors } = this.props;

        // Why is this on a timeout?
        setTimeout(pageChangeAnnouncement, 1000);

        // The login page is allowed to be displayed when there's a 401 error,
        // so we delete the error or else ErrorHandler will replace
        // the login form with an error message.
        if (errors && errors.code == 401 && activePathname == '/login') errors = {};

        return (
            <div>
                <div aria-live="polite" className="gs-u-vh" id="accessibility-message"></div>
                <div
                    id="accessibility-hook"
                    className="gs-u-vh gs-u-vh--focusable"
                    tabIndex="-1"
                    aria-labelledby="accessibility-message"
                ></div>
                <div>
                    <a
                        href="#main"
                        className="dp-o-button dp-o-button--top-right gs-u-vh gs-u-vh--focusable"
                    >
                        Skip to content
                    </a>
                    <a
                        href="http://www.bbc.co.uk/accessibility/"
                        className="dp-o-button dp-o-button--top-right gs-u-vh gs-u-vh--focusable"
                    >
                        Accessibility help
                    </a>
                </div>

                <Header />

                <NavBar loggedIn={loggedIn} activePathname={activePathname} />

                <div id="flashbox" className="dp-c-sticky-flashbox">
                    <DisplayMessages
                        id="messages"
                        dispatch={this.props.dispatch}
                        messages={this.props.flashMessages}
                    />
                </div>

                <main id="main">
                    <ErrorHandler {...errors}>{this.props.children}</ErrorHandler>
                </main>

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const session = state.session.session;
    const errors = state.errors;

    return {
        loggedIn: !!session,
        activePathname:
            ownProps.location && ownProps.location.pathname ? ownProps.location.pathname : '',
        errors,
        flashMessages: state.messages.messages,
    };
}

export default connect(mapStateToProps)(ApplicationLayout);

export { ApplicationLayout as PureApplicationLayout };
