import React from 'react';
import RemoveMemberLink from 'projects/components/RemoveMemberLink';

const MemberList = ({ members, projectSlug }) => {
    const sortByEmail = (memberA, memberB) => {
        const emailA = memberA.email.toUpperCase();
        const emailB = memberB.email.toUpperCase();
        return emailA < emailB ? -1 : emailA > emailB ? 1 : 0;
    };

    const renderRow = (member) => (
        <tr className="dp-table__row gs-o-list-ui__item" key={member.id}>
            <td className="dp-table__cell">
                <a href={`mailto:${member.email}`}>{member.email}</a>
            </td>
            <td className="dp-table__cell">
                <RemoveMemberLink userId={member.id} slug={projectSlug} />
            </td>
        </tr>
    );

    return (
        <table className="gs-o-list-ui dp-table">
            <thead>
                <tr className="dp-table__header">
                    <td className="dp-table__cell">Member Email</td>
                    <td className="dp-table__cell">Actions</td>
                </tr>
            </thead>
            <tbody>{members.sort(sortByEmail).map((member) => renderRow(member))}</tbody>
        </table>
    );
};

export default MemberList;
