import React from 'react';

const Footer = () => (
    <footer className="dp-c-footer gs-u-pt++ gs-u-pb++">
        <div className="gel-wrap">
            <div>
                <span className="gel-double-pica">Developer Portal</span>
            </div>

            <nav className="gs-u-mt+" aria-labelledby="accessibility-site-links-navigation-label">
                <div className="gs-u-vh" id="accessibility-site-links-navigation-label">
                    BBC site links
                </div>
                <ul className="dp-o-list dp-o-list--inline gel-brevier">
                    <li className="dp-o-list__item">
                        <a href="http://www.bbc.co.uk/aboutthebbc/">About the BBC</a>
                    </li>
                    <li className="dp-o-list__item">
                        <a href="http://www.bbc.co.uk/privacy/">Privacy Policy</a>
                    </li>
                    <li className="dp-o-list__item">
                        <a href="http://www.bbc.co.uk/accessibility/">Accessibility Help</a>
                    </li>
                    <li className="dp-o-list__item">
                        <a href="http://www.bbc.co.uk/contact/">Contact the BBC</a>
                    </li>
                </ul>
            </nav>

            <div className="dp-c-footer__copyright gel-brevier">
                <span>
                    <strong>Copyright &copy; {new Date().getFullYear()} BBC.&nbsp;</strong>
                    The BBC is not responsible for the content of external sites.&nbsp;
                    <strong>
                        <a href="http://www.bbc.co.uk/help/web/links/">
                            Read about our approach to external linking
                        </a>
                    </strong>
                    .
                </span>
            </div>
        </div>
    </footer>
);

export default Footer;
