import React, { useEffect } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DocumentTitle from 'common/components/DocumentTitle';

import { fetchApp } from 'apps/services/appsService';
import { fetchProducts } from 'products/services/productsService';

import { createKeyRules } from 'keys/validation/rules';
import { createKey } from 'keys/validation/handlers';
import CreateKeyForm from 'keys/components/CreateKeyForm';
import { enableForm } from 'forms/helpers/enableForm';
import ErrorNotFound from 'errors/components/ErrorNotFound';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';

const formOptions = {
    formId: 'create-key',
    rules: createKeyRules,
    messages: {
        product: 'You must select an API product',
    },
    onValidated: createKey,
};
const FormEnabledCreateKey = enableForm(formOptions)(CreateKeyForm);

const CreateKey = ({ location }) => {
    const appId = location.query.app_id;
    const dispatch = useDispatch();

    useEffect(() => {
        fetchApp({ dispatch }, appId);
        fetchProducts({ dispatch });
    }, []);

    const { app, appLoaded } = useSelector((state) => ({
        app: state.apps.apps && state.apps.apps[appId] ? state.apps.apps[appId] : {},
        appLoaded: state.apps.loaded[appId],
    }));

    const productsList = useSelector((state) => state.products.products || []);

    if (appLoaded && !app.id) return <ErrorNotFound />;

    return (
        <DocumentTitle title="Request New API Key">
            <div className="dp-main dp-c-create-key">
                <LoadingPlaceholder waitFor={app} placeholder="Loading application details...">
                    <LoadingPlaceholder
                        waitFor={productsList}
                        placeholder="Loading available API products..."
                    >
                        <h1 className="gs-u-mb0 dp-h1">Request New API Key</h1>

                        <p className="gs-u-mt0 gs-u-mb++">
                            for <Link to={`/apps/${app.id}`}>{app.displayName}</Link>
                        </p>

                        <FormEnabledCreateKey
                            productsList={productsList}
                            initialValues={{
                                app: appId,
                            }}
                        />
                    </LoadingPlaceholder>
                </LoadingPlaceholder>
            </div>
        </DocumentTitle>
    );
};

export default CreateKey;

export { CreateKey as PureCreateKey };
