import { loadingApps, loadedApps, storeProjectApps } from 'apps/state';

import * as appsService from 'apps/services/appsService';
import { throwError } from 'errors/state';

export function fetchAppsForProject(projectId) {
    return async function(dispatch) {
        dispatch(loadingApps());

        try {
            const apps = await appsService.fetchAppsForProject(projectId);
            dispatch(storeProjectApps(projectId, apps));
        } catch (error) {
            dispatch(throwError(error.properties.statusCode, error.properties.message));
        }

        dispatch(loadedApps());
    };
}
