import * as appService from 'apps/services/appsService';
import { redirect } from 'common/state/redirect';
import dispatchError from './dispatchError';

export default async function createApp(
    dispatch,
    { displayName, description, projectId, redirectUrl },
) {
    try {
        const developerApp = await appService.createApp(
            { dispatch },
            { displayName, description, project: projectId },
        );

        const defaultLocation = { pathname: `/apps/${developerApp.id}` };
        const location = redirectUrl || defaultLocation;
        dispatch(redirect(location));
    } catch (error) {
        if (
            error.properties &&
            error.properties.entities &&
            error.properties.entities[0].properties
        ) {
            const validationErrrorMessage = Error(
                `Some fields have not been completed. Please complete the ${error.properties.entities[0].properties.field} field.`,
            );

            dispatchError(dispatch, validationErrrorMessage);
        } else {
            dispatchError(dispatch, error);
        }
    }
}
