import React from 'react';
import { useFormContext } from 'react-hook-form';

const ErrorMessage = (props) => {
    const { errors } = useFormContext();
    const { field } = props;

    if (errors[field]) {
        return (
            <div className="gel-form-field__errors" data-testid="error-message">
                <span role="alert" className="gel-form-field__error">
                    {errors[field].message}
                </span>
            </div>
        );
    }

    return null;
};

export default ErrorMessage;
