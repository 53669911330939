import React from 'react';
import { RadioField, HiddenField, SubmitButton } from 'forms';

function isProductVisible(product) {
    return !product.hideFromDevelopers;
}

function productToRadioEntry(product) {
    const descriptionMatchesDisplayName = product.description === product.displayName;
    const optionalDescription = descriptionMatchesDisplayName ? '' : `: ${product.description}`;
    const labelText = `${product.displayName}${optionalDescription}`;

    return {
        value: product.id,
        label: labelText,
    };
}

function sortByLabelProperty(valueA, valueB) {
    const lowerLabelA = valueA.label.toLowerCase();
    const lowerLabelB = valueB.label.toLowerCase();

    return lowerLabelA > lowerLabelB ? 1 : lowerLabelA === lowerLabelB ? 0 : -1;
}

function generateRadioOptions(products) {
    return Object.values(products)
        .filter(isProductVisible)
        .map(productToRadioEntry)
        .sort(sortByLabelProperty);
}

const CreateKeyForm = ({ productsList }) => {
    return (
        <React.Fragment>
            <div className="gel-layout">
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <RadioField
                        label="API Product"
                        name="product"
                        defaultLabel="Loading..."
                        options={generateRadioOptions(productsList)}
                    />
                </div>
            </div>

            <HiddenField name="app" />

            <div className="gel-layout gs-u-mt++">
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <SubmitButton
                        name="submit"
                        className="dp-o-button--primary dp-o-button--full"
                        submittingText="Creating key..."
                    >
                        Request API key
                    </SubmitButton>
                </div>
            </div>
        </React.Fragment>
    );
};
export default CreateKeyForm;
