import getUiError from 'common/helpers/uiError.js';
import checkNestedObject from 'common/helpers/checkNestedObject';
import { Validator } from '@bbc/developer-portal';
import { push as pushClientLocation } from 'react-router-redux';
import { addMessage } from 'flashMessages/state';
import { SubmissionError, startSubmit, stopSubmit, touch, initialize } from 'redux-form';

// TODO: Clean up work around between ES5 and ES6 run-time.
const uiError = typeof getUiError !== 'function' ? getUiError.getUiError : getUiError;

const logger = require('@bbc/developer-portal').logger({
    source: __filename,
});

// TODO: Class needs reviewing. It's pretty confusing at points.
export default class FormValidation {
    constructor(form, rules, customErrorMessages = {}) {
        if (typeof form !== 'string') {
            throw new TypeError('Form ID must be valid string.');
        }

        if (Object.prototype.toString.call(rules) !== '[object Object]') {
            throw new TypeError('The second argument for FormValidation should be a rules object.');
        }

        this.form = form;
        this.rules = rules;
        this.customMessages = customErrorMessages;
        this.validator = new Validator(rules);
        this.onValidatedCallback = this._defaultOnValidatedCallback;
        this.onFailedValidationCallback = this._defaultOnFailedValidationCallback;
        this.customValidationCallback = this._defaultCustomValidationCallback;
        this.beforeCallbacks = [];
    }

    // Hook methods

    before(callback) {
        if (typeof callback !== 'function') {
            throw new TypeError('Validation before hook should be a function.');
        }
        this.beforeCallbacks.push(callback);
    }

    onValidated(callback) {
        if (typeof callback !== 'function') {
            throw new TypeError('onValidated callback should be a function.');
        }

        this.onValidatedCallback = callback;
    }

    onFailedValidation(callback) {
        if (typeof callback !== 'function') {
            throw new TypeError('onFailedValidation callback should be a function.');
        }

        this.onFailedValidationCallback = callback;
    }

    setCustomValidation(callback) {
        if (typeof callback !== 'function') {
            throw new TypeError('Custom validation callback should be a function.');
        }

        this.customValidationCallback = callback;
    }

    validate(rawData) {
        const data = this._nullifyEmptyValues(rawData);
        const arrayFieldErrors = this._validateArrayFields(data, this.rules);
        const flatErrors = this.validator(data);
        const errors = Object.assign(flatErrors, arrayFieldErrors);

        const flattenedErrors = {};

        for (let fieldKey in errors) {
            if (errors[fieldKey].length === 0) {
                continue;
            }

            if (Array.isArray(errors[fieldKey][0])) {
                flattenedErrors[fieldKey] = errors[fieldKey][0].map(
                    (error) => error && this._getErrorMessage(fieldKey, error[0]),
                );
            } else if (errors[fieldKey] !== false) {
                flattenedErrors[fieldKey] = this._getErrorMessage(fieldKey, errors[fieldKey][0]);
            }
        }

        const customValidation = this.customValidationCallback(data);
        if (customValidation) {
            Object.assign(flattenedErrors, customValidation);
        }

        return Object.keys(flattenedErrors).length === 0 ? false : flattenedErrors;
    }

    _nullifyEmptyValues(rawData) {
        const data = Object.assign({}, rawData);

        Object.keys(data).forEach((objectKey) => {
            const value = data[objectKey];

            if (typeof value === 'string' && value.length === 0) {
                data[objectKey] = null;
            }
        });

        return data;
    }

    _validateArrayFields(data, rules) {
        let errors = {};

        const arrayFields = Object.keys(data)
            .filter((fieldName) => {
                return Array.isArray(data[fieldName]);
            })
            .filter((fieldName) => {
                return !!rules[fieldName];
            });

        arrayFields.forEach(function(fieldName) {
            const items = data[fieldName];
            const fieldRules = {};
            const fields = {};

            items.forEach((value, index) => {
                fieldRules[index] = rules[fieldName];
                fields[index] = value;
            });

            const validate = new Validator(fieldRules);
            const fieldErrors = validate(fields);

            if (!fieldErrors) {
                return (errors[fieldName] = false);
            }

            errors[fieldName] = [
                Object.keys(fields).map((index) => {
                    return fieldErrors[index] || undefined;
                }),
            ];
        });

        return errors;
    }

    _getErrorMessage(fieldKey, error) {
        const errorCode = error.code;
        const customFieldMessage = this.customMessages[fieldKey];

        if (customFieldMessage) {
            if (typeof customFieldMessage === 'string') {
                return customFieldMessage;
            }

            const errorCodeSpecificMessage = customFieldMessage[errorCode];

            if (errorCodeSpecificMessage) {
                return errorCodeSpecificMessage;
            }
        }

        return uiError(errorCode).properties.message;
    }

    _hasErrors(validationErrors, onServer, dispatch, data) {
        if (Object.keys(validationErrors).length !== 0 && validationErrors.constructor === Object) {
            if (onServer) {
                dispatch(initialize(this.form, data, true));
                this._touchInvalidFields(validationErrors, dispatch);
                dispatch(stopSubmit(this.form, { errors: validationErrors }));
            } else {
                throw new SubmissionError(validationErrors);
            }

            return true;
        }

        return false;
    }

    _touchInvalidFields(errors, dispatch) {
        const fieldKeys = Object.keys(errors);

        dispatch(touch(this.form, ...fieldKeys));

        fieldKeys.forEach((fieldKey) => {
            if (Array.isArray(errors[fieldKey])) {
                const fieldMap = {};
                errors[fieldKey].map((value, index) => {
                    fieldMap[`${fieldKey}[${index}]`] = value;
                });
                this._touchInvalidFields(fieldMap, dispatch);
            }
        });
    }

    handleSubmission(onServer, data, dispatch, replace, callback) {
        if (data && Object.keys(data).length) {
            this.beforeCallbacks.map((callback) => {
                const dataCopy = Object.assign({}, data);
                const callbackResult = callback(dataCopy, dispatch);
                if (typeof callbackResult !== 'undefined') data = callbackResult;
            });

            const validationErrors = this.validate(data);

            if (validationErrors) {
                try {
                    this.onFailedValidationCallback(dispatch, data, validationErrors);
                } catch (err) {
                    const message = checkNestedObject(err, 'properties', 'message')
                        ? err.properties.message
                        : 'An unexpected error occurred during login. Please try again.';
                    dispatch(addMessage(message, err));
                }
            }

            if (!this._hasErrors(validationErrors, onServer, dispatch, data)) {
                if (this.onValidatedCallback) {
                    try {
                        this.onValidatedCallback(dispatch, data, replace, callback);
                    } catch (err) {
                        const message = checkNestedObject(err, 'properties', 'message')
                            ? err.properties.message
                            : 'An unexpected error occurred during login. Please try again.';
                        dispatch(addMessage(message, err));
                    }
                } else {
                    callback();
                }
            } else {
                callback();
            }
        } else {
            callback();
        }
    }

    handleOnClient() {
        return (data, dispatch) => {
            dispatch(startSubmit(this.form));

            const clientSideReplace = function(locationObj) {
                dispatch(pushClientLocation(locationObj));
            };
            return this.handleSubmission(false, data, dispatch, clientSideReplace, () => {
                dispatch(stopSubmit(this.form));
            });
        };
    }

    convertErrorResponse(errorResponse) {
        const errorMap = {};

        // Who knows when this would happen
        // but apparently we want to return "false"
        // if we get a Siren blob with no errors listed.
        // At a guess, maybe this means something went wrong
        // but we don't want to display it as an invalid form input?
        if (
            typeof errorResponse.entities === 'undefined' ||
            !(errorResponse.entities instanceof Array)
        ) {
            return false;
        }

        errorResponse.entities.forEach((error) => {
            // Check the error is a validation one;
            // if it's not we ignore it for now.
            if (
                typeof error.properties === 'undefined' ||
                typeof error.properties.field === 'undefined'
            ) {
                return;
            }

            // Work out what input field the error is on.
            const fieldKey = error.properties.field;
            // At least for now, we only display one error per field:
            if (errorMap[fieldKey]) return;

            // Display the custom UI error message,
            // or failing that the server error message
            // (or failing that a generic error message)
            const errorCode = error.properties.id,
                uiErrorObject = uiError(errorCode);
            const errorMessage = uiErrorObject
                ? uiErrorObject.properties.message
                : error.properties.message;
            errorMap[fieldKey] = errorMessage || 'Something is wrong with this field.';
        });

        // Return the rejigged error list *if* it isn't empty.
        return Object.keys(errorMap).length === 0 ? false : errorMap;
    }

    // Manually throw errors after form validation process has taken place and passed.
    throwErrors(errors, data, dispatch) {
        if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
            const fieldKeys = Object.keys(data);
            dispatch(initialize(this.form, data, true));
            dispatch(touch(this.form, ...fieldKeys));
            dispatch(stopSubmit(this.form, errors));
        }
    }

    _defaultOnValidatedCallback() {
        logger.warn('Success callback has not be defined.');
    }

    _defaultOnFailedValidationCallback() {
        logger.warn('On failed validation callback has not be defined.');
    }

    _defaultCustomValidationCallback() {
        return false;
    }
}
