import { DESTROY_SESSION } from 'sessions/state';

export const STORE_PROJECT = Symbol('STORE_PROJECT');
export const STORE_MY_PROJECT = Symbol('STORE_MY_PROJECT');
export const STORE_PROJECTS = Symbol('STORE_PROJECTS');
export const STORE_MY_PROJECTS = Symbol('STORE_MY_PROJECTS');
export const IS_PROJECT_MEMBER = Symbol('IS_PROJECT_MEMBER');
export const REMOVE_PROJECT = Symbol('REMOVE_PROJECT');
export const LOADING_PROJECT_MEMBERS = Symbol('LOADING_PROJECT_MEMBERS');
export const STORE_PROJECT_MEMBERS = Symbol('STORE_PROJECT_MEMBERS');
export const LOADED_PROJECT_MEMBERS = Symbol('LOADED_PROJECT_MEMBERS');
export const GET_PERSONAL_PROJECT = Symbol('GET_PERSONAL_PROJECT');
export const GET_PROJECTS_BY_IDS = Symbol('GET_PROJECTS_BY_IDS');
export const LOADING_PROJECTS = Symbol('LOADING_PROJECTS');
export const LOADED_PROJECTS = Symbol('LOADED_PROJECTS');
export const LOADING_PROJECT = Symbol('LOADING_PROJECT');
export const LOADED_PROJECT = Symbol('LOADED_PROJECT');

export const initialState = {
    projects: {},
    mine: {},
    members: {},
    loadingProjects: false,
    loadedProjects: false,
    loading: {},
    loaded: {},
    loadingProjectMembers: false,
    loadedProjectMembers: false,
};

export default function reducer(state = initialState, action = { type: null }) {
    const newState = Object.assign({}, state, {
        projects: {
            ...state.projects,
        },
        mine: {
            ...state.mine,
        },
        members: {
            ...state.members,
        },
    });

    switch (action.type) {
        case DESTROY_SESSION: {
            newState.mine = {};
            return newState;
        }

        case STORE_PROJECT: {
            const project = action.project;
            newState.projects[project.slug] = project;
            return newState;
        }

        case STORE_MY_PROJECT: {
            const myProject = action.project;
            newState.projects[myProject.slug] = myProject;
            newState.mine[myProject.slug] = true;
            return newState;
        }

        case STORE_PROJECTS: {
            action.projects.forEach((project) => {
                newState.projects[project.slug] = project;
            });
            return newState;
        }

        case STORE_MY_PROJECTS: {
            action.projects.forEach((project) => {
                newState.projects[project.slug] = project;
                newState.mine[project.slug] = true;
            });
            return newState;
        }

        case REMOVE_PROJECT: {
            const slug = action.slug;
            delete newState.projects[slug];
            delete newState.mine[slug];
            return newState;
        }

        case STORE_PROJECT_MEMBERS: {
            const { projectSlug, members } = action;
            newState.members[projectSlug] = members;
            return newState;
        }

        case LOADING_PROJECTS: {
            return Object.assign({}, state, {
                loadingProjects: true,
                loadedProjects: false,
            });
        }

        case LOADED_PROJECTS: {
            return Object.assign({}, state, {
                loadingProjects: false,
                loadedProjects: true,
            });
        }

        case LOADING_PROJECT: {
            return Object.assign({}, state, {
                loading: Object.assign({}, state.loading, {
                    [action.slug]: true,
                }),
                loaded: Object.assign({}, state.loaded, {
                    [action.slug]: false,
                }),
            });
        }

        case LOADED_PROJECT: {
            return Object.assign({}, state, {
                loading: Object.assign({}, state.loading, {
                    [action.slug]: false,
                }),
                loaded: Object.assign({}, state.loaded, {
                    [action.slug]: true,
                }),
            });
        }

        case LOADING_PROJECT_MEMBERS: {
            return Object.assign({}, state, {
                loadingProjectMembers: true,
                loadedProjectMembers: false,
            });
        }

        case LOADED_PROJECT_MEMBERS: {
            return Object.assign({}, state, {
                loadingProjectMembers: false,
                loadedProjectMembers: true,
            });
        }

        default: {
            return state;
        }
    }
}

// Pure actions

// TODO - this should accept a slug
export function storeProject(project) {
    return {
        type: STORE_PROJECT,
        project,
    };
}

export function storeMyProject(project) {
    return {
        type: STORE_MY_PROJECT,
        project,
    };
}

export function removeProject(slug) {
    return {
        type: REMOVE_PROJECT,
        slug,
    };
}

export function storeProjects(projects) {
    return {
        type: STORE_PROJECTS,
        projects,
    };
}

export function storeMyProjects(projects) {
    return {
        type: STORE_MY_PROJECTS,
        projects,
    };
}

export function loadingProjectMembers() {
    return {
        type: LOADING_PROJECT_MEMBERS,
    };
}

export function storeProjectMembers(projectSlug, members) {
    return {
        type: STORE_PROJECT_MEMBERS,
        projectSlug,
        members,
    };
}

export function loadedProjectMembers() {
    return {
        type: LOADED_PROJECT_MEMBERS,
    };
}

export function isProjectMember(slug) {
    return {
        type: IS_PROJECT_MEMBER,
        slug,
    };
}

export function getPersonalProject(userId) {
    return {
        type: GET_PERSONAL_PROJECT,
        userId,
    };
}

export function getProjectsByIds(ids = []) {
    return {
        type: GET_PROJECTS_BY_IDS,
        ids,
    };
}

export function loadingProjects() {
    return {
        type: LOADING_PROJECTS,
    };
}

export function loadedProjects() {
    return {
        type: LOADED_PROJECTS,
    };
}

export function loadingProject(projectSlug) {
    return {
        type: LOADING_PROJECT,
        slug: projectSlug,
    };
}

export function loadedProject(projectSlug) {
    return {
        type: LOADED_PROJECT,
        slug: projectSlug,
    };
}

export function projectsMiddleware() {
    return function({ getState }) {
        return function(next) {
            return function(action) {
                switch (action.type) {
                    case IS_PROJECT_MEMBER: {
                        const mine = getState().projects.mine;
                        return mine[action.slug] ? true : false;
                    }
                    case GET_PERSONAL_PROJECT: {
                        return determinePersonalProject(
                            action.userId,
                            getState().projects.projects,
                        );
                    }
                    case GET_PROJECTS_BY_IDS: {
                        const idArray = action.ids;
                        return filterProjectsByIds(getState().projects.projects, idArray);
                    }
                }

                return next(action);
            };
        };
    };
}

// TODO: this is clearly a copy-paste of getPersonalProjectSlug.js
// and they should be combined to reduce the duplication
// and create more consistency in our code.
function determinePersonalProject(userId, projects) {
    if (!userId || !projects) {
        return false;
    }

    const foundPersonalProject = Object.keys(projects)
        .map((projectSlug) => projects[projectSlug])
        .filter((project) => project.owner == userId);

    if (foundPersonalProject.length === 0 || !foundPersonalProject[0].slug) {
        return false;
    }

    return Object.assign({}, foundPersonalProject[0]);
}

function filterProjectsByIds(projects, idArray = []) {
    const projectsById = {};
    const filteredProjects = {};

    Object.keys(projects).forEach((key) => {
        const project = projects[key];
        projectsById[project.id] = project;
    });

    idArray.forEach((id) => {
        const project = projectsById[id];

        if (project) {
            filteredProjects[id] = project;
        }
    });

    return filteredProjects;
}
