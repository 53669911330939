import React from 'react';
import PropTypes from 'prop-types';

const LoadingPlaceholder = ({ children, waitFor, placeholder = 'Loading...' }) => {
    let ready = false;

    if (!waitFor) {
        ready = false;
    } else if (typeof waitFor === 'boolean') {
        ready = waitFor;
    } else if (typeof waitFor === 'number') {
        ready = waitFor > 0;
    } else if (typeof waitFor === 'object') {
        ready = Object.keys(waitFor).length > 0;
    } else {
        ready = waitFor.length > 0;
    }

    return (
        <div>
            {!ready && <span className="loading-placeholder">{placeholder}</span>}

            {ready && <span>{children}</span>}
        </div>
    );
};

export default LoadingPlaceholder;

LoadingPlaceholder.propTypes = {
    children: PropTypes.node,
    waitFor: PropTypes.any,
    placeholder: PropTypes.string,
};
