import React, { useEffect } from 'react';
import { useStore, useSelector } from 'react-redux';

import DocumentTitle from 'common/components/DocumentTitle';
import { Link } from 'react-router';

import { fetchApp } from 'apps/services/appsService';
import { fetchKey } from 'keys/services/keysService';
import { fetchProduct } from 'products/services/productsService';
import ErrorNotFound from 'errors/components/ErrorNotFound';

import { handleDeleteConfirmation } from 'keys/validation/handlers';
import { enableForm } from 'forms/helpers/enableForm';
import DeleteKeyForm from 'keys/components/DeleteKeyForm';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';

const formOptions = {
    formId: 'delete-key',
    rules: {},
    onValidated: handleDeleteConfirmation,
};
const FormEnabledDeleteKey = enableForm(formOptions)(DeleteKeyForm);

const DeleteKey = ({ params }) => {
    const { key, app, product, contentLoaded } = useSelector((state) => {
        const keyId = params.id;

        const key = state.keys.keys[keyId] || {};
        const keyRequestComplete = state.keys.loaded[keyId];
        const appRequestComplete = !key.app || (key.app && state.apps.loaded[key.app]);

        return {
            key,
            product: (key.apiProduct && state.products.products[key.apiProduct]) || {},
            app: (key.app && state.apps.apps[key.app]) || {},
            contentLoaded: keyRequestComplete && appRequestComplete,
        };
    });

    const store = useStore();

    useEffect(() => {
        fetchKey(store, params.id);
    }, []);

    useEffect(() => {
        key.apiProduct && fetchProduct(store, key.apiProduct);
        key.app && fetchApp(store, key.app);
    }, [key]);

    if (contentLoaded && !key.id) return <ErrorNotFound />;

    const title = `Confirm deletion of ${product.displayName} key for ${app.displayName}`;

    return (
        <DocumentTitle title={`${product.displayName} key for ${app.displayName}`}>
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item gel-1/1 gel-8/12@l key-delete dp-c-key-delete">
                        <LoadingPlaceholder waitFor={app}>
                            <h1 className="gs-u-mb0 dp-h1">{title}</h1>

                            <p className="gs-u-mt++ gs-u-mb+">
                                Are you sure you want to delete this key? This action{' '}
                                <strong>cannot</strong> be undone.
                            </p>
                            <p className="gs-u-mt+ gs-u-mb++">
                                The application &#39;{app.displayName}&#39; will no longer be able
                                to use this API key to make requests.
                            </p>

                            <Link
                                to={`/keys/${key.id}`}
                                id="remove-member-cancel-button"
                                className="gs-u-float-left dp-o-button gs-u-p+ gs-u-mr+"
                            >
                                Cancel
                            </Link>

                            <FormEnabledDeleteKey
                                initialValues={{
                                    id: params.id,
                                    appId: key && key.app,
                                }}
                            />
                        </LoadingPlaceholder>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default DeleteKey;
