import React from 'react';
import DocumentTitle from 'common/components/DocumentTitle';
import { Link } from 'react-router';

const ErrorAccessDenied = (props) => (
    <DocumentTitle title={false}>
        <div>
            <div className="dp-c-banner-image__403" />
            <section className="dp-main">
                <header>
                    <h1 className="dp-h2">Access Denied</h1>
                </header>
                <div className="gel-layout">
                    <div className="gel-1/1 gel-8/12@l gel-layout__item">
                        <p>
                            You cannot access this resource because you are not a member of the
                            Developer Portal project that owns it. You will need adding to the
                            project to access it.
                        </p>
                        <p>
                            Visit the <Link to="/projects">My Projects</Link> page to see the
                            projects you are a member of.
                        </p>
                        {__DEVELOPMENT__ && (
                            <pre data-testid="debug-props-output">
                                <span>{JSON.stringify(props, null, 2)}</span>
                            </pre>
                        )}
                    </div>
                </div>
            </section>
        </div>
    </DocumentTitle>
);

export default ErrorAccessDenied;
