import React, { useEffect } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProduct } from 'products/services/productsService';
import { fetchAppsForProject } from 'apps/actions';
import { createKey } from 'keys/validation/handlers';

import DocumentTitle from 'common/components/DocumentTitle';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import AppsForUserForm from './../components/RequestProductKeyForm';
import { API_REQUEST_INFO, CREATE_APP, TO_CONTINUE } from 'common/constants';

const RequestProductKey = ({ params }) => {
    const loadingPlaceholderText = 'Loading API data...';

    const productId = params.productId;
    const product = useSelector((state) => state.products.products[productId] || {});
    const projectList = useSelector((state) => state.projects.projects || []);
    const projectNames = Object.keys(projectList);
    const appsForUserList = useSelector((state) => state.apps.apps || []);

    const title = product.displayName
        ? `Requesting an API key for ${product.displayName}`
        : loadingPlaceholderText;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchProduct({ dispatch }, productId);
    }, []);

    useEffect(() => {
        projectNames.forEach((projectName) => {
            const project = projectList[projectName];
            dispatch(fetchAppsForProject(project.id));
        });
    }, [projectNames.length]);

    // form handler
    const _unusedCallback = () => {};
    const _unusedReplaceState = null;
    const onSubmit = (data) => {
        createKey(dispatch, data, _unusedReplaceState, _unusedCallback);
    };

    return (
        <DocumentTitle title={title}>
            <div>
                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-1/1 gel-8/12@l key-view dp-c-key-view">
                            <h1 className="dp-h1">Request an API Key</h1>

                            <LoadingPlaceholder
                                waitFor={product}
                                placeholder={loadingPlaceholderText}
                            >
                                <section>
                                    <p className="dp-h3">
                                        Request an API key for&nbsp;
                                        <strong>{product.displayName}</strong>
                                    </p>
                                </section>
                                {Object.keys(appsForUserList).length ? (
                                    <section>
                                        <p className="dp-h3 dp-info-box">
                                            Please choose an app to assign this key to or&nbsp;
                                            <Link
                                                className="key-view__field app_name"
                                                to={`/apps/new/?redirectUrl=${window.location.pathname}${window.location.search}`}
                                            >
                                                create a new one
                                            </Link>
                                            {TO_CONTINUE}
                                        </p>
                                        <AppsForUserForm
                                            appsForUserList={appsForUserList}
                                            projectList={projectList}
                                            productId={productId}
                                            onSubmit={onSubmit}
                                        />
                                    </section>
                                ) : (
                                    <section>
                                        <p className="dp-h3 dp-info-box">
                                            {API_REQUEST_INFO}
                                            <Link
                                                className="key-view__field app_name"
                                                to={`/apps/new/?redirectUrl=${window.location.pathname}${window.location.search}`}
                                            >
                                                {CREATE_APP}
                                            </Link>
                                            {TO_CONTINUE}
                                        </p>
                                    </section>
                                )}
                            </LoadingPlaceholder>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default RequestProductKey;
