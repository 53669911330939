import React from 'react';
import { Field, change } from 'redux-form';

let nextId = 1;

export class RadioField extends React.Component {
    constructor(props) {
        super(props);
        this.id = nextId++;
        this.state = {
            selected: null,
        };
    }

    render() {
        const { label, input = {}, meta = {}, options = [] } = this.props,
            { touched, error } = meta;

        return (
            <div
                className={'gel-form__field' + (touched && error ? ' is-invalid' : '')}
                role="radiogroup"
                aria-invalid={!!error}
            >
                <div className="gel-form__label" htmlFor={input.name}>
                    {label}
                </div>
                {options.map((option) => (
                    <div key={option.value} className="dp-o-radio gs-u-m+">
                        <input
                            id={option.value}
                            className="gs-u-mr"
                            role="radio"
                            type="radio"
                            name={input.name}
                            value={option.value}
                            onClick={() => this.select(option.value)}
                            defaultChecked={option.value === this.props.defaultSelected}
                            testid={option.label}
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                    </div>
                ))}

                <div className="gel-form-field__errors">
                    {touched && error && (
                        <span
                            role="alert"
                            id={input.name + '-field-alert'}
                            className="gel-form-field__error"
                        >
                            {error}
                        </span>
                    )}
                </div>
            </div>
        );
    }

    select(value) {
        const { input, meta } = this.props,
            { dispatch, form } = meta;
        dispatch(change(form, input.name, value));
        this.editState({ selected: value });
    }

    editState(newState) {
        this.setState((prevState) => Object.assign({}, prevState, newState));
    }
}

const WrappedRadioFieldSimple = (props) => <Field component={RadioField} {...props} />;

export default WrappedRadioFieldSimple;
