import { reducers as commonReducers } from 'common';
import { reducers as messagesReducers } from 'flashMessages';
import { reducers as projectsReducers } from 'projects';
import { reducers as productsReducers } from 'products';
import { reducers as appsReducers } from 'apps';
import { reducers as appAttributesReducers } from 'appAttributes';
import { reducers as keysReducers } from 'keys';
import { reducers as sessionsReducers } from 'sessions';
import { reducers as usersReducers } from 'users';
import { reducers as errorReducers } from 'errors';

// When adding a new module, make sure to use spread syntax to allow additional
// reducers to be added to the module without having to update this file.
export default {
    ...commonReducers,
    ...messagesReducers,
    ...productsReducers,
    ...projectsReducers,
    ...appsReducers,
    ...appAttributesReducers,
    ...keysReducers,
    ...sessionsReducers,
    ...usersReducers,
    ...errorReducers,
};
