import React from 'react';
import PropTypes from 'prop-types';

import { InputField, SubmitButton, HiddenField } from 'forms';
import FormFieldHelp from 'common/components/FormFieldHelp';

const ProjectForm = ({ buttonVerb, submittingText }) => (
    <div>
        <div className="gel-layout">
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <InputField
                    name="displayName"
                    type="text"
                    label="Project Name"
                    describedBy="display-name-field-help"
                    required
                />
            </div>
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <FormFieldHelp id="display-name-field-help">
                    A descriptive name for your project
                </FormFieldHelp>
            </div>
        </div>

        <div className="gel-layout">
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <SubmitButton
                    name="submit_project"
                    submittingText={submittingText}
                    className="dp-o-button--primary dp-o-button--full"
                >
                    {buttonVerb} project
                </SubmitButton>
                <HiddenField name="redirectUrl" />
                {buttonVerb && buttonVerb === 'Update' && (
                    <React.Fragment>
                        <HiddenField name="id" />
                        <HiddenField name="owner" />
                        <HiddenField name="originalSlug" />
                    </React.Fragment>
                )}
            </div>
        </div>
    </div>
);

export default ProjectForm;

ProjectForm.propTypes = {
    buttonVerb: PropTypes.string,
    submittingText: PropTypes.string,
};

ProjectForm.defaultProps = {
    buttonVerb: 'Create',
    submittingText: 'Processing...',
};
