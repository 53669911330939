import { redirect } from 'common/state/redirect';
import { addMessage } from 'flashMessages/state';
import { destroySession } from 'sessions/state';

async function checkSessionValidity(sessionService, store, state, replace, next) {
    next(); // Allow middleware to operate without blocking render

    let isValid;
    try {
        isValid = await sessionService.isActiveSessionValid(store);
    } catch (err) {
        // Announce on client side
    }

    if (!isValid && state.location.pathname !== '/login-required') {
        const redirectAction = redirect({
            pathname: '/login-required',
        });

        const flashMessageAction = addMessage(
            'Your session has expired. Please reauthenticate to continue.',
        );

        store.dispatch(destroySession());
        store.dispatch(flashMessageAction);
        store.dispatch(redirectAction);

        return false;
    }

    return true;
}

export default (sessionService) => {
    return checkSessionValidity.bind(null, sessionService);
};
