import * as appService from 'apps/services/appsService';
import { redirect } from 'common/state/redirect';
import dispatchError from './dispatchError';
import { addMessage, flushAllMessages } from 'flashMessages/state';

export default async function updateApp(
    dispatch,
    appData,
    _replaceState,
    callback,
    message = null,
) {
    const appId = appData.id;

    if (!appId) {
        throw new Error(
            'App data object missing id property. Property required to update application.',
        );
    }

    try {
        const existing = await appService.fetchApp({ dispatch }, appId);
        await appService.updateApp({ dispatch }, appId, Object.assign({}, existing, appData));

        dispatch(redirect({ pathname: `/apps/${appId}` }));

        if (message !== null) {
            dispatch(flushAllMessages());
            dispatch(addMessage(message));
        }
    } catch (error) {
        dispatchError(dispatch, error);
    }

    return callback();
}
