import React from 'react';

import { ErrorMessage } from 'forms';

import FormFieldHelp from 'common/components/FormFieldHelp';
import SelectBasic from './SelectBasic';

const Select = ({ name, description, label, options, includeBlank = true }) => {
    return (
        <div className="gel-layout">
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <label htmlFor={name} className="gel-form__label">
                    {label}
                </label>
                <SelectBasic name={name} includeBlank={includeBlank} options={options} />
                <ErrorMessage field={name} />
            </div>

            {description && description.length > 0 && (
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <FormFieldHelp id={`${name}-field-help`}>{description}</FormFieldHelp>
                </div>
            )}
        </div>
    );
};

export default Select;
