import React, { Fragment } from 'react';
import { Link } from 'react-router';

const ProjectOwnershipInformation = ({ isTeamProjectApp, app, parentProject }) => (
    <Fragment>
        {isTeamProjectApp ? (
            <p>
                This app is in the{' '}
                <a href={`/projects/${parentProject.slug}`}>{parentProject.display_name}</a>{' '}
                project.
            </p>
        ) : (
            <div className="dp-c-flashbox dp-info-box">
                <i className="dp-o-icon dp-o-icon--info"></i> This app is listed as being for your
                personal use (e.g. for making ad-hoc requests in Postman or via a browser). If that
                is incorrect or if the keys listed in this App are owned by a team rather than an
                individual,{' '}
                <Link role="link" to={`/apps/${app.id}/move`}>
                    please assign it to an appropriate project.
                </Link>
            </div>
        )}
    </Fragment>
);

export default ProjectOwnershipInformation;
