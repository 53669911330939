import React from 'react';
import { useFormContext } from 'react-hook-form';

const TextField = (props) => {
    const {
        name,
        label,
        registerOptions,
        labelClass = 'gel-form__label',
        inputClass = 'gel-form__input',
        wrapperClass = 'gel-form__field',
        wrapperErrorClass = 'is-invalid',
        errorsClass = 'gel-form-field__errors',
        errorClass = 'gel-form-field__error',
    } = props;

    const { register, errors } = useFormContext();
    const id = name;
    const wrapperClasses = `${wrapperClass} ${errors[name] ? wrapperErrorClass : ''}`;

    return (
        <div className={wrapperClasses}>
            <label htmlFor={id} className={labelClass}>
                {label || props.children}
            </label>
            <input
                className={inputClass}
                id={id}
                name={name}
                ref={register(registerOptions)}
                type="text"
            />
            {errors[name] && (
                <div className={errorsClass}>
                    <span role="alert" className={errorClass}>
                        {errors[name].message}
                    </span>
                </div>
            )}
        </div>
    );
};

export default TextField;
