import superagent from 'superagent';
import config from 'common/helpers/config';

import { getUiError, getUiErrorByHttpStatus } from 'common/helpers/uiError';

export default class UiApiClient {
    get(path, options = {}) {
        return performRequest('get', path, Object.assign({}, options), this.options);
    }

    post(path, options = {}) {
        return performRequest('post', path, Object.assign({}, options), this.options);
    }

    put(path, options = {}) {
        return performRequest('put', path, Object.assign({}, options), this.options);
    }

    del(path, options = {}) {
        return performRequest('del', path, Object.assign({}, options), this.options);
    }

    head(path, options = {}) {
        return performRequest('head', path, Object.assign({}, options), this.options);
    }
}

function performRequest(method, path, { params, data, fullResponse = false }) {
    return new Promise((resolve, reject) => {
        const requestUrl = formatUrl(path);
        const request = superagent[method](requestUrl);

        if (params) request.query(params);
        if (data) request.send(data);

        request.set('x-requested-with', 'XMLHttpRequest'); // required for partner platform access proxy

        request.end((err, res) => {
            // Uncomment below to expose API errors when debugging tests
            // console.log({ err, res });
            if (err) {
                let responseError;

                if (isKnownAPIError(res)) {
                    responseError = generateKnownAPIError(res, {
                        path,
                        params,
                        data,
                    });
                } else {
                    responseError = generateHTTPError(err, res);
                }

                return reject(responseError);
            }

            return resolve(fullResponse ? res : res.body);
        });
    });
}

function isKnownAPIError(res) {
    return res && res.body && res.body.class && res.body.class.indexOf('error') >= 0;
}

function generateKnownAPIError(res, requestOptions) {
    const error = convertAPIErrorToError(res.body, res.statusCode);
    error.request = requestOptions;
    return error;
}

function generateHTTPError(err, res = {}) {
    return convertAPIErrorToError(getUiErrorByHttpStatus(err.status, res.statusCode));
}

function convertAPIErrorToError(json, statusCode) {
    const uiError = getUiError(json.properties.id);

    const err = new Error((uiError || json.properties).id);

    err.properties = Object.assign({}, json.properties, {
        statusCode,
        entities: json.entities,
    });

    if (uiError) {
        Object.assign(err.properties, uiError.properties);
    }

    return err;
}

function formatUrl(path) {
    const hostUrl = config.uiApiUrl || '/api';
    if (path.substr(0, 1) === '/' && hostUrl.substr(-1) === '/') {
        path = path.substr(1);
    } else if (path.substr(0, 1) !== '/' && hostUrl.substr(-1) !== '/') {
        path = '/' + path;
    }
    return hostUrl + path;
}
