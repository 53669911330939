import errorReducer, { errorsMiddleware } from 'errors/state';

export const reducers = {
    errors: errorReducer,
};

export function storeMiddleware(options = {}) {
    const { skipLocationChangeOnce = false } = options;

    return [errorsMiddleware(skipLocationChangeOnce)];
}
