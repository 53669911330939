import React from 'react';
import { Link } from 'react-router';

import isFeatureActive from 'common/helpers/features';

const createCallToActionItems = (projectId) => {
    return [
        {
            term: 'Create a new Project',
            description:
                "If you want to create a project to store API keys that will be used in production or for other regular traffic, or for any keys that you just don't want to have sole ownership of, then start here.",
            to: '/projects/create',
        },
        {
            term: 'View Projects that I am a member of',
            description:
                'Visit here to see any Projects that you are already in. From here you can see any API keys that those Projects have and request new ones, as well as managing members.',
            to: '/projects',
        },
        {
            term: 'Create a personal use Application',
            description:
                'If you would like to make a new API key request for your personal use, then start here.',
            to: `/apps/new/${projectId}`,
        },
        {
            term: 'View my personal use Applications',
            description:
                'These are the applications & API keys that you have for making ad-hoc requests.',
            to: '/my-apps',
        },
    ];
};

const HomeCallToAction = ({ projectId }) => {
    const callToActionItems = createCallToActionItems(projectId);

    return (
        <nav id="HomeCallToAction">
            {!isFeatureActive('projects') && (
                <Link to="/my-apps" className="dp-o-button--cta gs-u-mt+ dp-e-personal-project">
                    View my apps
                </Link>
            )}

            {isFeatureActive('projects') && (
                <React.Fragment>
                    <h3 className="dp-h3 gs-u-ml- gs-u-mt++">What would you like to do?</h3>
                    <div className="dp-main">
                        <div className="gel-layout gel-layout--auto">
                            {callToActionItems.map(({ description, term, to }) => (
                                <div
                                    className="gel-1/1 gel-1/2@s gel-5/12@m gs-u-mr gs-u-mb gel-layout__item dp-o-home-card dp-o-home-card--block-background gs-o-faux-block-link"
                                    key={term}
                                >
                                    <div className="dp-o-home-card__text">
                                        <h3>
                                            <Link
                                                className="gs-o-faux-block-link__overlay-link"
                                                to={to}
                                            >
                                                {term}
                                            </Link>
                                        </h3>
                                        <p className="dp-o-home-card__text gs-u-ml0">
                                            {description}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </React.Fragment>
            )}

        </nav>
    );
};

export default HomeCallToAction;
