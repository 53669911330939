import React from 'react';
import { useFormContext } from 'react-hook-form';

const RadioButton = (props) => {
    const { name, value, label, labelClass, inputClass, wrapperClass, registerOptions } = props;
    const { register } = useFormContext();
    const id = `${name}-${value}`;

    return (
        <div className={wrapperClass}>
            <input
                className={inputClass}
                id={id}
                name={name}
                ref={register(registerOptions)}
                type="radio"
                value={value}
            />
            &nbsp;
            <label htmlFor={id} className={labelClass}>
                {label || props.children}
            </label>
        </div>
    );
};

export default RadioButton;
