import React from 'react';
import { Link } from 'react-router';
import { RadioField, SubmitButton, HiddenField } from 'forms';

export default class AppMoveProjectForm extends React.Component {
    render() {
        const { submittingText, buttonVerb, projects, app } = this.props;

        const projectOptions = Object.keys(projects).map((key) => {
            const label = projects[key].owner ? 'Personal Project' : projects[key].display_name;

            return { value: projects[key].id, label };
        });

        return (
            <div>
                <RadioField
                    label="Project"
                    name="project"
                    defaultLabel="Loading..."
                    options={projectOptions}
                    selected={app.project}
                />

                <div className="gs-u-float-right gel-layout__item gel-12/12@xs gs-u-mt+">
                    <SubmitButton
                        name="submit_app"
                        submittingText={submittingText}
                        className="gs-u-float-right dp-o-button--primary"
                        style={{ height: 36, marginLeft: 10 }}
                    >
                        {buttonVerb} assignment
                    </SubmitButton>
                    <Link
                        to={`/apps/${app.id}`}
                        id="app-move-project-cancel-button"
                        className="gs-u-float-right dp-o-button--secondary"
                        style={{ height: 36 }}
                    >
                        Cancel
                    </Link>
                </div>

                <HiddenField data-testid="app_id" name="id"></HiddenField>
            </div>
        );
    }
}
