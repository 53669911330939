import UiApiClient from 'common/helpers/uiApiClient';
import cameliseObjKeys from 'common/helpers/cameliseObjKeys';
import { formDataToProduct } from 'products/services/productTransformers';
import { throwError } from 'errors/state';
import { addMessage, flushAllMessages } from 'flashMessages/state';

import {
    storeProducts as storeProductsAction,
    loadingProducts as loadingProductsAction,
    loadedProducts as loadedProductsAction,
} from 'products/state/products';
import { storeKey } from 'keys/state';

export function fetchProducts(store) {
    store.dispatch(loadingProductsAction());

    return new UiApiClient(store).get('products').then((response) => {
        const products = response.entities ? response.entities.map(transformResponse) : [];

        store.dispatch(loadedProductsAction());
        store.dispatch(storeProductsAction(products));

        return products;
    });
}

export function fetchProduct(store, id) {
    store.dispatch(loadingProductsAction());

    return new UiApiClient(store)
        .get('products/' + id)
        .then((response) => {
            const product = transformResponse(response);

            store.dispatch(storeProductsAction([product]));
            store.dispatch(loadedProductsAction());

            return product;
        })
        .catch((err) => {
            store.dispatch(throwError(err.properties.statusCode));
        });
}

export function updateKeyStatus(store, keyAccessChange) {
    store.dispatch(flushAllMessages());

    return new UiApiClient(store)
        .put(`access/${keyAccessChange.id}`, {
            data: {
                status: keyAccessChange.status,
            },
        })
        .then((res) => {
            const key = transformResponse(res);

            store.dispatch(storeKey(key));

            store.dispatch(
                addMessage(
                    `Successfully updated the key status to '${keyAccessChange.status}'. It can take up to an hour for this change to propagate to api-management proxy.`,
                ),
            );
        })
        .catch(() =>
            store.dispatch(
                addMessage(`ERROR: failed to update key status to '${keyAccessChange.status}'`),
            ),
        );
}

export function registerAPIProduct(store, data = {}) {
    const options = { data: formDataToProduct(data) };
    return new UiApiClient(store).post('products', options).then((response) => {
        const product = transformResponse(response);
        const action = storeProductsAction([product]);
        store.dispatch(action);

        return product;
    });
}

export function updateAPIProduct(store, data = {}) {
    const options = { data: formDataToProduct(data) };
    return new UiApiClient(store).put('products/' + options.data.id, options).then((response) => {
        const product = transformResponse(response);
        const action = storeProductsAction([product]);
        store.dispatch(action);

        return product;
    });
}

function transformResponse(entity) {
    return cameliseObjKeys(entity.properties);
}
