import React from 'react';
import { Link } from 'react-router';
import { SubmitButton, HiddenField } from 'forms';

export const DELETE_TEXT = 'Delete app';
export const CANCEL_TEXT = 'Cancel';

const DeleteAppForm = ({ app }) => (
    <div>
        <p className="gs-u-mt++ gs-u-mb+">Are you sure you want to delete this app?</p>

        <Link
            to={`/apps/${app.id}`}
            id="remove-member-cancel-button"
            className="gs-u-float-left dp-o-button gs-u-p+ gs-u-mr+"
        >
            {CANCEL_TEXT}
        </Link>

        <SubmitButton
            name="confirm"
            submittingText="Deleting"
            className="dp-o-button dp-o-button--delete"
        >
            {DELETE_TEXT}
        </SubmitButton>

        <HiddenField name="id" />
        <HiddenField name="redirectUrl" />
    </div>
);

export default DeleteAppForm;
