import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { fetchProduct } from 'products/services/productsService';
import DocumentTitle from 'common/components/DocumentTitle';
import ProductForm from 'products/components/ProductForm';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import { updateAPIProduct } from 'products/handlers';

const VIEW_TITLE = 'Edit Product';

const EditProduct = ({ router }) => {
    const productId = router.params.productId;
    const dispatch = useDispatch();

    useEffect(() => {
        fetchProduct({ dispatch }, productId);
    }, []);

    const product = useSelector((state) => state.products.products[productId]);

    const onSubmit = useCallback((data) => {
        updateAPIProduct(dispatch, data);
    });

    return (
        <div>
            <DocumentTitle title={VIEW_TITLE}>
                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item">
                            <h1 className="gs-u-mb++ dp-h1">{VIEW_TITLE}</h1>
                        </div>
                    </div>
                    <LoadingPlaceholder waitFor={product} loadingPlaceholder="Loading form...">
                        <ProductForm
                            dispatch={dispatch}
                            projectId={product ? product.project : null}
                            product={product}
                            onSubmit={onSubmit}
                        />
                    </LoadingPlaceholder>
                </div>
            </DocumentTitle>
        </div>
    );
};

export default withRouter(EditProduct);
