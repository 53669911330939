const STORE_KEYS = Symbol('STORE_KEYS');
const FORGET_KEY = Symbol('FORGET_KEY');
const LOADING_KEY = Symbol('LOADING_KEY');
const LOADED_KEY = Symbol('LOADED_KEY');
const LOADING_KEY_COLLECTION = Symbol('LOADING_KEY_COLLECTION');
const LOADED_KEY_COLLECTION = Symbol('LOADED_KEY_COLLECTION');

export const initialState = {
    keys: {},
    loading: {},
    loaded: {},
    loadingCollection: false,
    loadedCollection: false,
};

export default function reducer(state = initialState, action = { type: false }) {
    const keys = Object.assign({}, state.keys);

    switch (action.type) {
        case STORE_KEYS:
            action.keys.forEach((key) => (keys[key.id] = key));
            return Object.assign({}, state, {
                keys,
            });

        case FORGET_KEY:
            delete keys[action.keyId];
            return Object.assign({}, state, {
                keys,
            });

        case LOADING_KEY:
            return Object.assign({}, state, {
                loading: Object.assign({}, state.loading, {
                    [action.keyId]: true,
                }),
                loaded: Object.assign({}, state.loaded, {
                    [action.keyId]: false,
                }),
            });

        case LOADING_KEY_COLLECTION:
            return Object.assign({}, state, {
                loadingCollection: true,
                loadedCollection: false,
            });

        case LOADED_KEY:
            return Object.assign({}, state, {
                loading: Object.assign({}, state.loading, {
                    [action.keyId]: false,
                }),
                loaded: Object.assign({}, state.loaded, {
                    [action.keyId]: true,
                }),
            });

        case LOADED_KEY_COLLECTION:
            return Object.assign({}, state, {
                loadingCollection: false,
                loadedCollection: true,
            });

        default:
            return state;
    }
}

export function storeKeys(keys) {
    return {
        type: STORE_KEYS,
        keys,
    };
}

export function storeKey(key) {
    return storeKeys([key]);
}

export function forgetKey(keyId) {
    return {
        type: FORGET_KEY,
        keyId,
    };
}

export function loadingKey(keyId) {
    return {
        type: LOADING_KEY,
        keyId,
    };
}

export function loadedKey(keyId) {
    return {
        type: LOADED_KEY,
        keyId,
    };
}

export function loadingKeyCollection() {
    return {
        type: LOADING_KEY_COLLECTION,
    };
}

export function loadedKeyCollection() {
    return {
        type: LOADED_KEY_COLLECTION,
    };
}
