export const STORE_PRODUCTS = Symbol('STORE_PRODUCTS');
export const LOADING_PRODUCTS = Symbol('LOADING_PRODUCTS');
export const LOADED_PRODUCTS = Symbol('LOADED_PRODUCTS');

export const initialState = {
    products: {},
    loadingProducts: false,
    loadedProducts: false,
};

export default function reducer(state = initialState, action = { type: null }) {
    const newState = Object.assign({}, state, {
        products: {
            ...state.products,
        },
    });

    switch (action.type) {
        case STORE_PRODUCTS:
            action.products.forEach((product) => {
                newState.products[product.id.toString()] = product;
            });
            return newState;

        case LOADING_PRODUCTS:
            return Object.assign({}, state, {
                loadingProducts: true,
                loadedProducts: false,
            });

        case LOADED_PRODUCTS:
            return Object.assign({}, state, {
                loadingProducts: false,
                loadedProducts: true,
            });

        default:
            return state;
    }
}

export function loadingProducts() {
    return {
        type: LOADING_PRODUCTS,
    };
}

export function loadedProducts() {
    return {
        type: LOADED_PRODUCTS,
    };
}

export function storeProducts(products) {
    return {
        type: STORE_PRODUCTS,
        products,
    };
}
