const logger = require('@bbc/developer-portal').logger({
    source: __filename,
});

const errorsByStatusCode = {
    '400': 'ERR_BAD_REQUEST',
    '401': 'ERR_UNAUTHORIZED',
    '403': 'ERR_FORBIDDEN',
    '404': 'ERR_NOT_FOUND',
    '409': 'ERR_CONFLICT',
    '422': 'ERR_UNPROCESSABLE_ENTITY',
    '500': 'ERR_INTERNAL_SERVER_ERROR',
    '501': 'ERR_NOT_IMPLEMENTED',
    '502': 'ERR_BAD_GATEWAY',
    '503': 'ERR_SERVICE_UNAVAILABLE',
};

function getUiError(errorCode, errors, uiErrors) {
    errors = errors || require('@bbc/developer-portal').errors;
    uiErrors = uiErrors || require('../../../config/errors');
    const errorDetails = uiErrors[errorCode] || errors[errorCode];
    if (!errorDetails) return null;

    // Now we have the error definition, build it into a JS Error
    // with a properties object, as though it had come from the API:
    const err = new Error(errorCode);
    err.properties = Object.assign({}, errorDetails, { id: errorCode });
    return err;
}

function getUiErrorByHttpStatus(status) {
    const recognisedStatusCode = errorsByStatusCode[status];
    if (recognisedStatusCode) return getUiError(recognisedStatusCode);

    logger.error('Unrecognised Error during UI API Client request', {
        httpStatus: status,
    });
    return getUiError('ERR_UNKNOWN_ERROR');
}

// Note: This uses ES5 exporting syntax so that it can be
// directly imported into Cucumber test code. It can be
// imported using the following:
//
//     import { getUiError, getUiErrorByHttpStatus } from 'common/helpers/uiError';
//
module.exports = {
    errorsByStatusCode,
    getUiError,
    getUiErrorByHttpStatus,
};
