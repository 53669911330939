export const THROW_ERROR = Symbol('THROW_ERROR');
export const CLEAR_ERROR = Symbol('CLEAR_ERROR');
export const GET_ERROR_CODE = Symbol('GET_ERROR_CODE');

export const initialState = {
    code: false,
    message: false,
    debug: false,
};

export default function reducer(state = initialState, action = { type: false }) {
    switch (action.type) {
        case THROW_ERROR: {
            return {
                code: parseInt(action.code, 10),
                message: action.message,
                debug: action.debug,
            };
        }

        case CLEAR_ERROR: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

export function throwError(code, message, debug = false) {
    return {
        type: THROW_ERROR,
        code,
        message,
        debug,
    };
}

export function getErrorCode() {
    return {
        type: GET_ERROR_CODE,
    };
}

export function clearError() {
    return {
        type: CLEAR_ERROR,
    };
}

export function errorsMiddleware(skipLocationChangeOnce = false) {
    let skippedLocationChange = false;

    return ({ dispatch, getState }) => (next) => (action) => {
        switch (action.type) {
            case GET_ERROR_CODE: {
                const error = getState().errors;
                return error.code;
            }

            case '@@router/LOCATION_CHANGE': {
                next(action);
                if (skipLocationChangeOnce && !skippedLocationChange) {
                    skippedLocationChange = true;
                    break;
                }
                dispatch(clearError());
                break;
            }

            default: {
                return next(action);
            }
        }
    };
}
