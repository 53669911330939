const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

function pad(n, digits) {
    let s = n.toString();
    while (s.length < digits) s = '0' + s;
    return s;
}

function dateFormatter(timestampOrDate) {
    if (!timestampOrDate) return 'Unknown';
    const date = timestampOrDate instanceof Date ? timestampOrDate : new Date(timestampOrDate);
    const day = date.getDate();
    const monthName = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = pad(date.getHours(), 2);
    const minutes = pad(date.getMinutes(), 2);
    return `${day} ${monthName} ${year}, at ${hours}:${minutes}`;
}

export default dateFormatter;
