const logger = require('@bbc/developer-portal').logger({
    source: __filename,
});

async function prepareSessionStore(sessionService, UiApiClient, store, newState, replace, next) {
    if (newState.location.pathname === '/login-required') {
        next();
        return;
    }

    try {
        await sessionService.populateSession(store, { Client: UiApiClient });
    } catch (error) {
        return logger.error('Error fetching projects', {
            message: error.message,
            stack: error.stack,
        });
    }

    next();
}

export default (sessionService, UiApiClient) => {
    return prepareSessionStore.bind(null, sessionService, UiApiClient);
};
