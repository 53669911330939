import React from 'react';
import { reduxForm } from 'redux-form';
import { FormValidation } from 'forms';
import hoistNonReactStatics from 'hoist-non-react-statics';

function buildValidation(options) {
    const validation = new FormValidation(options.formId, options.rules, options.messages);

    if (options.onValidated) {
        validation.onValidated(options.onValidated);
    }

    if (options.onFailedValidation) {
        validation.onFailedValidation(options.onFailedValidation);
    }

    if (options.customValidation) {
        validation.setCustomValidation(options.customValidation);
    }

    if (options.onBefore && Array.isArray(options.onBefore)) {
        options.onBefore.forEach((beforeCallback) => {
            validation.before(beforeCallback);
        });
    }
    return validation;
}

const usedIds = {};

export function enableForm(options) {
    if (!options) {
        throw new Error('options argument is required');
    }

    if (!options.formId) {
        throw new Error('Form ID is blank');
    }

    if (usedIds[options.formId] && !enableForm.ignoreCollisions) {
        throw new Error('Form ID is duplicate');
    }

    usedIds[options.formId] = true;

    const validation = buildValidation(options);

    return (Component) => {
        const DecoratedComponent = reduxForm({
            form: options.formId,
            validate: validation.validate.bind(validation),
        })(
            class DecoratedComponent extends React.Component {
                render() {
                    const onClientSubmit = (...args) => {
                        this.props.handleSubmit(validation.handleOnClient())(...args);
                    };

                    return (
                        <form
                            id={options.formId}
                            data-testid={options.formId}
                            method="POST"
                            onSubmit={options.disableClient ? null : onClientSubmit}
                        >
                            <Component {...this.props}>{this.props.children}</Component>
                            <input type="hidden" name="FORM_ID" value={options.formId} />
                        </form>
                    );
                }
            },
        );

        hoistNonReactStatics(DecoratedComponent, Component);

        DecoratedComponent.validation = validation;

        return DecoratedComponent;
    };
}
