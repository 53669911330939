import React from 'react';

// React Components
import { Link } from 'react-router';

const Header = () => (
    <header className="dp-c-header gs-u-pt+ gs-u-pb+">
        <div className="gel-wrap gs-u-clearfix">
            <div className="gs-u-float-left">
                <Link to={'/'} className="dp-c-header__link">
                    <div className="dp-o-bbc-logo" title="BBC"></div>
                    <div className="dp-c-header__title gs-u-mt gel-trafalgar">
                        <span className="dp-c-header__link">Developer Portal</span>
                    </div>
                </Link>
            </div>
        </div>
    </header>
);

export default Header;
