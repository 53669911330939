import * as yup from 'yup';

const createSchema = yup.object().shape({
    display_name: yup.string().required('Product name is required'),
    product_identifier: yup.string().required('Product identifier is required'),
    description: yup.string().required('Product description is required'),
    authorisation: yup.string().required('Approval type is required'),
    environments: yup.array().min(1, 'You must choose at least 1 environment'),
    email: yup.string().when('authorisation', {
        is: 'manual',
        then: yup
            .string()
            .required('Notification Email(s) is required when approval type is manual'),
    }),
});

export { createSchema };
