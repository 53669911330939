import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

export const CheckboxFieldGroup = (passedProps) => {
    const { label, input = {}, meta = {}, options = [], defaultLabel } = passedProps;
    const { touched, error } = meta;

    return (
        <div className={'gel-form__field' + (touched && error ? ' is-invalid' : '')}>
            <label htmlFor={input.name} className="gel-form__label">
                {label}
            </label>
            <div>
                {!options ||
                    (options.length === 0 && <p>{defaultLabel || 'No options available'}</p>)}

                {options.map((option) => {
                    return (
                        <div className="gs-u-mb" key={option.label}>
                            <label htmlFor={`${input.name}[${option.value}]`}>
                                <Field
                                    className="gs-u-mr"
                                    name={`${input.name}[${option.value}]`}
                                    id={`${input.name}[${option.value}]`}
                                    value={true}
                                    component="input"
                                    type="checkbox"
                                />
                                {option.label}
                            </label>
                        </div>
                    );
                })}

                <div className="gel-form-field__errors">
                    {touched && error && (
                        <span
                            role="alert"
                            id={input.name + '-field-alert'}
                            className="gel-form-field__error"
                        >
                            {error}
                        </span>
                    )}
                    <span>&nbsp;</span>
                </div>
            </div>
        </div>
    );
};

const WrappedCheckboxField = (props) => <Field component={CheckboxFieldGroup} {...props} />;

export default WrappedCheckboxField;

CheckboxFieldGroup.propTypes = {
    select: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object,
    options: PropTypes.array,
};
