import React, { useEffect } from 'react';
import { useSelector, useStore } from 'react-redux';

import { Link } from 'react-router';
import DocumentTitle from 'common/components/DocumentTitle';
import ErrorNotFound from 'errors/components/ErrorNotFound';
const { Fragment } = React;

import { enableForm } from 'forms/helpers/enableForm';
import RemoveMemberForm from 'projects/components/RemoveMemberForm';
const formOptions = {
    formId: 'remove-member',
    rules: {},
    onValidated: removeMember,
};
const FormEnabledRemoveMember = enableForm(formOptions)(RemoveMemberForm);

import { fetchProjectWithMembers } from 'projects/services/projectService';
import { removeMember } from 'projects/handlers/projectsFormHandler';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';

const RemoveMember = ({ params }) => {
    const store = useStore();

    useEffect(() => {
        fetchProjectWithMembers(store, params.slug);
    }, []);

    const currentUserId = useSelector((state) => {
        return state.session.session.user.id;
    });

    const project = useSelector((state) => {
        return state.projects.projects[params.slug] || {};
    });

    const members = useSelector((state) => {
        return state.projects.members[params.slug] || [];
    });

    const member = members.find((member) => member.id === params.userId) || {};

    const isOnlyMember = members.length === 1;

    if (!project || !member) return <ErrorNotFound />;

    const initialValues = {
        projectSlug: project.slug,
        projectId: project.id,
        userId: params.userId,
        currentUserId,
    };

    const title = isOnlyMember === true ? 'Cannot remove member' : 'Confirm member removal';

    const userName =
        initialValues.currentUserId === initialValues.userId ? 'yourself' : member.email;

    const noActionLinkPath = `/projects/${project.slug}`;

    return (
        <DocumentTitle title={title}>
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item gel-1/1 gel-8/12@l">
                        <h1 className="gs-u-mb0 dp-h1">{title}</h1>

                        {isOnlyMember ? (
                            <Fragment>
                                <p className="gs-u-mt++ gs-u-mb+">
                                    You cannot remove yourself from this project as you are the only
                                    member.
                                </p>

                                <Link
                                    to={noActionLinkPath}
                                    id="cannot-remove-only-member-ok-button"
                                    className="gs-u-float-left dp-o-button gs-u-p+ gs-u-mr+"
                                >
                                    OK
                                </Link>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <p className="gs-u-mt++ gs-u-mb+">
                                    {`Are you sure you want to remove ${userName} from '${project.display_name}'?`}
                                </p>
                                <LoadingPlaceholder waitFor={project.slug}>
                                    <Link
                                        to={noActionLinkPath}
                                        id="remove-member-cancel-button"
                                        className="gs-u-float-left dp-o-button gs-u-p+ gs-u-mr+"
                                    >
                                        Cancel
                                    </Link>
                                </LoadingPlaceholder>

                                <LoadingPlaceholder waitFor={member}>
                                    <FormEnabledRemoveMember initialValues={initialValues} />
                                </LoadingPlaceholder>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default RemoveMember;
