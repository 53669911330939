import React from 'react';
import { InputField, SubmitButton, HiddenField } from 'forms';

const { Fragment } = React;

const AddMemberForm = () => (
    <Fragment>
        <div className="gel-layout">
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <InputField
                    name="emailAddress"
                    type="text"
                    label="Add project member by email address"
                    placeholder="project.member@bbc.co.uk"
                    required
                />
            </div>

            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl gs-u-pt++@l">
                <SubmitButton
                    name="add member"
                    submittingText="Adding member..."
                    className="dp-o-button--primary dp-o-button--submit"
                >
                    Add Member
                </SubmitButton>
            </div>

            <HiddenField name="projectId" />
            <HiddenField name="projectSlug" />
        </div>
    </Fragment>
);

export default AddMemberForm;
