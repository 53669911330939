import React, { useEffect } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { fetchProductKey } from 'keys/actions';
import { fetchProject, fetchProjectMembers } from 'projects/actions';
import { fetchAppAttributes } from 'appAttributes/actions';
import { fetchProduct } from 'products/services/productsService';

import dateFormatter from 'common/helpers/dateFormatter';
import DocumentTitle from 'common/components/DocumentTitle';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import AppAttributesTable from 'products/components/appAttributes/AppAttributesTable';

import AddAttributeForm from '../../appAttributes/components/AddAttributeForm';
import isFeatureActive from 'common/helpers/features';

import NewKeyStatusForm from '../components/NewKeyStatusForm';
import KeyOwnership from '../components/KeyOwnership';

const ViewProductKey = ({ params }) => {
    const loadingPlaceholderText = 'Loading API key data...';

    const keyId = params.keyId;
    const productId = params.productId;

    const { key, keyLoaded } = useSelector((state) => {
        return {
            key: state.keys.keys[keyId] || {},
            keyLoaded: state.keys.loaded[keyId] || false,
        };
    });

    const { app, appLoaded } = useSelector((state) => {
        return {
            app: state.apps.apps[key.app] || {},
            appLoaded: state.apps.apps[key.app] || false,
        };
    });

    const { project, projectLoaded } = useSelector((state) => {
        const projectFiltered = Object.values(state.projects.projects).filter((project) => {
            return project.id === app.project;
        });

        return {
            project: projectFiltered[0] || {},
            projectLoaded: projectFiltered[0] || false,
        };
    });

    const { projectMembers, projectMembersLoaded } = useSelector((state) => {
        const unassignedProjectId = '00000000-0000-0000-0000-000000000002';
        let projectMembers;

        if (project) {
            if (project.id === unassignedProjectId) {
                return {
                    projectMembers: [],
                    projectMembersLoaded: true,
                };
            }
            projectMembers = state.projects.members[project.slug];
        }

        return {
            projectMembers: projectMembers || [],
            projectMembersLoaded: projectMembers && projectMembers.length > 0,
        };
    });

    const product = useSelector((state) => state.products.products[productId] || {});

    const { attributesLoaded, attributes } = useSelector((state) => {
        const allAttributes = state.appAttributes.attributes;
        const attributeIds = Object.keys(allAttributes);

        const keyAttributes = [];

        attributeIds.forEach((attributeId) => {
            const attribute = allAttributes[attributeId];
            if (attribute.app === app.id) {
                keyAttributes.push(allAttributes[attributeId]);
            }
        });

        return {
            attributesLoaded: state.appAttributes.loadedCollection,
            attributes: keyAttributes,
        };
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProductKey(productId, keyId));
    }, []);

    useEffect(() => {
        fetchProduct({ dispatch }, productId);
    }, []);

    useEffect(() => {
        if (app.id) {
            dispatch(fetchAppAttributes(app.id));
        }
    }, [app]);

    useEffect(() => {
        if (app.project) {
            dispatch(fetchProject(app.project));
        }
    }, [app]);

    useEffect(() => {
        if (project.id) {
            dispatch(fetchProjectMembers(project));
        }
    }, [project]);

    const dataLoaded =
        keyLoaded && appLoaded && attributesLoaded && projectLoaded && projectMembersLoaded;

    const title =
        product.displayName && app.displayName
            ? `${product.displayName} key for ${app.displayName}`
            : loadingPlaceholderText;

    return (
        <DocumentTitle title={title}>
            <div>
                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-1/1 gel-8/12@l key-view dp-c-key-view">
                            <h1 className="dp-h1">
                                <LoadingPlaceholder
                                    waitFor={app}
                                    placeholder={loadingPlaceholderText}
                                >
                                    {app.displayName} Key Details
                                </LoadingPlaceholder>
                            </h1>

                            <LoadingPlaceholder
                                waitFor={dataLoaded}
                                placeholder={loadingPlaceholderText}
                            >
                                <React.Fragment>
                                    <p className="gs-u-mt0 gs-u-mb++">
                                        for{' '}
                                        <Link
                                            className="key-view__field app_name"
                                            to={`/products/${product.id}`}
                                        >
                                            {product.displayName}
                                        </Link>
                                    </p>

                                    <section>
                                        <h2 className="dp-h3">API Key</h2>
                                        <span className="key-view__field api_key">
                                            {key.apiKey}
                                        </span>

                                        <label htmlFor="status">
                                            <h2 className="dp-h3">Status</h2>
                                        </label>

                                        <NewKeyStatusForm keyObject={key} />

                                        <h2 className="dp-h3">API Key ownership</h2>
                                        <KeyOwnership
                                            project={project}
                                            projectMembers={projectMembers}
                                        />

                                        {!project.owner && (
                                            <React.Fragment>
                                                <h4 className="dp-h4">Project Members</h4>
                                                <ul
                                                    className="gs-u-ml+"
                                                    data-testid="project-members-email-list"
                                                >
                                                    {projectMembers.map((projectMember) => {
                                                        return (
                                                            <li key={projectMember.id}>
                                                                <a
                                                                    href={`mailto:${projectMember.email}`}
                                                                >
                                                                    {projectMember.email}
                                                                </a>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </React.Fragment>
                                        )}

                                        <h2 className="dp-h3">Issued</h2>
                                        <span className="key-view__field issued">
                                            {dateFormatter(key.issued)}
                                        </span>
                                    </section>

                                    <section>
                                        <h2 className="dp-h3">Attributes</h2>

                                        {isFeatureActive('appAttributes') ? (
                                            <p>
                                                These attributes are shared with other products this
                                                API key has access to. Please do not edit/remove
                                                attributes you do not recognise.
                                            </p>
                                        ) : null}

                                        <AppAttributesTable attributes={attributes} />

                                        {isFeatureActive('appAttributes') ? (
                                            <AddAttributeForm app={app} />
                                        ) : null}
                                    </section>
                                </React.Fragment>
                            </LoadingPlaceholder>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default ViewProductKey;
