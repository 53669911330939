import getRandomCode from 'home/helpers/home-code';

export default function clientBannerMagic() {
    const container = document.getElementsByClassName('dp-c-banner-3d')[0];
    if (!container) return;
    let text = document.getElementById('dp-c-banner-3d__on-screen');
    if (!text) {
        const code = getRandomCode();
        container.innerHTML = `<textarea id="dp-c-banner-3d__on-screen" contenteditable tabindex="-1" aria-hidden="true" class="dp-c-banner-3d__on-screen">\n${code}</textarea>`;
        text = document.getElementById('dp-c-banner-3d__on-screen');
        // Chrome moves things around sometimes if you type overflowing text into them
        text.addEventListener('change', clientBannerMagic);
        text.addEventListener('paste', clientBannerMagic);
        container.addEventListener('scroll', (e) => {
            e.preventDefault();
            container.scrollTop = 0;
        });
    }
    const scale = window.innerWidth / 5488;
    const imgHeight = 3659 * scale;
    const bannerHeight = container.clientHeight;
    // CSS transforms are applied in reverse order because of matrix maths.
    // The matrix is designed to put the image on the screen at default scale.
    // The scale command then shrinks the transformed layer to match the width of the window.
    // The translateY command moves it up to match the cropping of the image as the banner aspect ratio changes.
    text.style.transform = `
		translateY(${(bannerHeight - imgHeight) * 0.25}px)
		scale(${scale})
		matrix3d(1.777635, -0.61685, 0, 0.00018, 
			0.749355, 1.919381, 0, 0.000069, 
			0, 0, 1, 0,
			1944, 967, 0, 1)`;
    container.scrollTop = 0;
}
