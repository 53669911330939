import React from 'react';

const ContentBlock = ({ children }) => {
    return (
        <div className="dp-main">
            <div className="gel-layout">
                <div className="gel-layout__item gel-1/1 gel-8/12@l">{children}</div>
            </div>
        </div>
    );
};

export default ContentBlock;
