import React from 'react';

import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import dateFormatter from 'common/helpers/dateFormatter';

const keyLoadingPlaceholder = 'Loading key ...';
const productLoadingPlaceholder = 'Loading product ...';

const KeyAttributes = ({ keyEntity, productEntity }) => {
    return (
        <section>
            <LoadingPlaceholder waitFor={keyEntity} placeholder={keyLoadingPlaceholder}>
                <LoadingPlaceholder waitFor={productEntity} placeholder={productLoadingPlaceholder}>
                    <h2 className="dp-h3">API Key</h2>
                    <span className="key-view__field api_key">{keyEntity.apiKey}</span>

                    <h2 className="dp-h3">API</h2>
                    <span className="key-view__field product">{productEntity.displayName}</span>

                    <h2 className="dp-h3">Status</h2>
                    <span className="key-view__field status">
                        {keyEntity.status
                            ? keyEntity.status[0].toUpperCase() + keyEntity.status.substr(1)
                            : null}
                    </span>
                    <h2 className="dp-h3">Issued</h2>
                    <span className="key-view__field issued">
                        {dateFormatter(keyEntity.issued)}
                    </span>

                    <h2 className="dp-h3">Expires</h2>
                    <span className="key-view__field expiry">
                        {dateFormatter(keyEntity.expires)}
                    </span>
                </LoadingPlaceholder>
            </LoadingPlaceholder>
        </section>
    );
};

export default KeyAttributes;
