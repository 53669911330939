import {
    loadedAttributeCollection,
    loadingAttributeCollection,
    storeAttributes,
    forgetAttribute,
} from 'appAttributes/state';
import { throwError } from 'errors/state';
import { addMessage, flushAllMessages } from 'flashMessages/state';

import * as appAttributesServices from '../services';

export function fetchAppAttributes(appId) {
    return async (dispatch) => {
        dispatch(loadingAttributeCollection());

        try {
            const attributes = await appAttributesServices.fetchAppAttributes(appId);

            dispatch(storeAttributes(attributes));
        } catch (error) {
            dispatch(throwError(error.properties.statusCode, error.properties.message));
        }

        dispatch(loadedAttributeCollection());
    };
}

export function updateAppAttribute(appAttribute) {
    return async (dispatch) => {
        let error;
        let message;

        try {
            const updatedAttribute = await appAttributesServices.updateAppAttribute(appAttribute);

            dispatch(storeAttributes([updatedAttribute]));
            message = `Attribute '${updatedAttribute.key}' set to '${updatedAttribute.value}'. It can take up to an hour for this change to propagate to api-management proxy.`;
        } catch (err) {
            error = err;

            if (appAttribute.key !== '' && appAttribute.value !== '') {
                message = `Failed to update '${appAttribute.key}' to '${appAttribute.value}'`;
            } else {
                message = 'Failed to update the attribute';
            }
        }

        dispatch(flushAllMessages());
        dispatch(addMessage(message, error));

        if (errorHasContextMessage(error)) {
            dispatch(addMessage(error.properties.message));
        }
    };
}

export function addAppAttribute(appAttribute) {
    return async (dispatch) => {
        let error;
        let message;

        try {
            const addedAttribute = await appAttributesServices.addAppAttribute(appAttribute);
            dispatch(storeAttributes([addedAttribute]));

            message = `Attribute '${addedAttribute.key}' set to '${addedAttribute.value}'. It can take up to an hour for this change to propagate to api-management proxy.`;
        } catch (err) {
            error = err;

            if (appAttribute.key !== '' && appAttribute.value !== '') {
                message = `Failed to add '${appAttribute.key}' with '${appAttribute.value}'`;
            } else {
                message = 'Failed to add attribute';
            }
        }
        dispatch(flushAllMessages());
        dispatch(addMessage(message, error));

        if (errorHasContextMessage(error)) {
            dispatch(addMessage(error.properties.message));
        }
    };
}

export function deleteAppAttribute(appAttribute) {
    return async (dispatch) => {
        let error;
        let message;

        try {
            await appAttributesServices.deleteAppAttribute(appAttribute.id);
            message = `Attribute '${appAttribute.key}' has been deleted. It can take up to an hour for this change to propagate to api-management proxy.`;
            dispatch(forgetAttribute(appAttribute.id));
        } catch (err) {
            error = err;
            message = `Failed to delete '${appAttribute.key}'`;
        }

        dispatch(flushAllMessages());

        dispatch(addMessage(message, error));

        if (errorHasContextMessage(error)) {
            dispatch(addMessage(error.properties.message));
        }
    };
}

function errorHasContextMessage(error) {
    return error && error.properties && error.properties.message;
}
