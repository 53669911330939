import React from 'react';
import { Field } from 'redux-form';

export const SubmitButton = (passedProps) => {
    const {
        meta: { submitting = false },
        children,
        submittingText = 'Processing...',
        ...props
    } = passedProps;

    return (
        <div>
            <button type="submit" {...props} disabled={submitting}>
                {submitting && <span>{submittingText}</span>}

                {!submitting && <span>{children}</span>}
            </button>
        </div>
    );
};

const WrappedSubmitButton = (props) => <Field component={SubmitButton} {...props} />;

export default WrappedSubmitButton;
