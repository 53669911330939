import React, { useEffect } from 'react';
import { Link } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { fetchApp } from 'apps/services/appsService';
import { fetchKey } from 'keys/services/keysService';
import { fetchProduct } from 'products/services/productsService';

import DocumentTitle from 'common/components/DocumentTitle';
import DangerZone from 'common/components/DangerZone';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import KeyAttributes from 'keys/components/KeyAttributes';

const ViewKey = ({ params }) => {
    const keyId = params.id;
    const dispatch = useDispatch();

    const key = useSelector((state) => state.keys.keys[keyId] || {});

    const app = useSelector((state) => state.apps.apps[key.app] || {});

    const product = useSelector((state) => state.products.products[key.apiProduct] || {});

    useEffect(() => {
        fetchKey({ dispatch }, keyId);
    }, []);

    useEffect(() => {
        key.app && fetchApp({ dispatch }, key.app);
    }, [key]);

    useEffect(() => {
        key.apiProduct && fetchProduct({ dispatch }, key.apiProduct);
    }, [key]);

    const dataLoaded = key.id && app.id && product.id;

    const title = dataLoaded
        ? `${product.displayName} key for ${app.displayName}`
        : 'Loading data ...';

    const heading = dataLoaded ? `${product.displayName} Key` : '';

    return (
        <DocumentTitle title={title}>
            <div>
                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-1/1 gel-8/12@l key-view dp-c-key-view">
                            <h1 className="gs-u-mb0 dp-h1">{heading}</h1>

                            <LoadingPlaceholder waitFor={dataLoaded} placeholder="Loading data...">
                                <p className="gs-u-mt0 gs-u-mb++">
                                    for{' '}
                                    <Link
                                        className="key-view__field app_name"
                                        to={`/apps/${app.id}`}
                                    >
                                        {app.displayName}
                                    </Link>
                                </p>

                                <KeyAttributes keyEntity={key} productEntity={product} />
                            </LoadingPlaceholder>
                        </div>
                    </div>
                </div>

                <hr className="dp-hr" />

                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-1/1 gel-8/12@l key-view dp-c-key-view">
                            <LoadingPlaceholder waitFor={key.id} placeholder="Loading key...">
                                <DangerZone
                                    isDeletable={true}
                                    deleteWarning="Deleting this API key will break any clients using it. API keys cannot be restored after deletion."
                                    deleteUrl={`/keys/${key.id}/delete`}
                                    deleteButtonText="Delete API key"
                                />
                            </LoadingPlaceholder>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default ViewKey;
