import React from 'react';

function KeyOwnership({ project = {}, projectMembers = [] }) {
    const fallbackOwnershipText = 'This API Key is owned by an individual.';

    return (
        whenMissingProjectDetails(project) ||
        whenProjectIsUnassigned(project) ||
        whenOwnedByTeam(project) ||
        whenOwnedByIndividual(project, projectMembers) ||
        fallbackOwnershipText
    );
}

const whenMissingProjectDetails = (project) => {
    return isEmptyObject(project) ? 'No project details available.' : false;
};

const whenProjectIsUnassigned = (project) => {
    const unassignedProjectId = '00000000-0000-0000-0000-000000000002';

    return project.id === unassignedProjectId
        ? "This API key doesn't have an owner currently."
        : false;
};

const whenOwnedByTeam = (project) => {
    return !project.owner ? `This API Key is owned by the ${project.display_name} project.` : false;
};

const whenOwnedByIndividual = (project, projectMembers) => {
    const ownerAsMember = projectMembers.find(findByIdField(project.owner)) || {};
    const ownerEmail = ownerAsMember.email;

    return ownerEmail ? (
        <React.Fragment>
            This API Key is owned by <a href={`mailto:${ownerEmail}`}>{ownerEmail}</a>.
        </React.Fragment>
    ) : (
        false
    );
};

const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const findByIdField = (idValue) => {
    return (object) => {
        return object.id === idValue;
    };
};

export default KeyOwnership;
