export const GET_SESSION = 'GET_SESSION';
export const SET_SESSION = 'SET_SESSION';
export const DESTROY_SESSION = 'DESTROY_SESSION';

export const initialState = {
    session: null,
};

export default function reducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case SET_SESSION: {
            return Object.assign({}, state, {
                session: action.session,
            });
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

// Pure actions

export function setSession(session) {
    return {
        type: SET_SESSION,
        session,
    };
}

export function destroySession() {
    return {
        type: DESTROY_SESSION,
    };
}

export function getSession() {
    return {
        type: GET_SESSION,
    };
}

export function sessionsMiddleware() {
    return function({ getState }) {
        return function(next) {
            return function(action) {
                switch (action.type) {
                    case GET_SESSION: {
                        return getState().session.session;
                    }
                }
                return next(action);
            };
        };
    };
}
