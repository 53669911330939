import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from 'forms';

import FormFieldHelp from 'common/components/FormFieldHelp';

const Input = ({ name, description, label, onChange, placeholder }) => {
    const { register } = useFormContext();

    return (
        <div className="gel-layout">
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <label htmlFor={name} className="gel-form__label">
                    {label}
                </label>
                <input
                    id={name}
                    name={name}
                    type="text"
                    ref={register}
                    className="gel-form__input"
                    aria-describedby={`${name}-field-help`}
                    onChange={onChange}
                    placeholder={placeholder}
                />
                <ErrorMessage field={name} />
            </div>
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <FormFieldHelp id={`${name}-field-help`}>{description}</FormFieldHelp>
            </div>
        </div>
    );
};

export default Input;
