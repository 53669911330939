import updateApp from './updateApp';

export default function moveAppProject(dispatch, appData, replaceState, callback) {
    return updateApp(
        dispatch,
        appData,
        replaceState,
        callback,
        'Your application has been assigned to a project.',
    );
}
