import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { pageChangeAnnouncement } from 'common/helpers/accessibility';

const DocumentTitle = ({ title, children }) => {
    const docTitle = title ? `${title} | BBC Developer Portal` : 'BBC Developer Portal';

    useEffect(() => {
        const originalTitle = document.title;
        document.title = docTitle;

        return () => {
            document.title = originalTitle;
        };
    }, [docTitle]);

    setTimeout(pageChangeAnnouncement, 1000);

    return <div>{children}</div>;
};

export default DocumentTitle;

DocumentTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    children: PropTypes.element,
};
