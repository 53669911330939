import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const EditAttributeRow = ({ attribute, handleClear }) => {
    const methods = useFormContext();

    useEffect(() => {
        methods.setValue('key', attribute.key);
        methods.setValue('value', attribute.value);
        methods.setValue('app', attribute.app);
        methods.setValue('id', attribute.id);
    }, []);

    return (
        <tr>
            <td className="dp-table__cell">
                <input
                    id="key"
                    name="key"
                    type="text"
                    ref={methods.register}
                    className="gel-form__input"
                    data-testid="editKey"
                />
            </td>
            <td className="dp-table__cell">
                <input
                    id="value"
                    name="value"
                    type="text"
                    ref={methods.register}
                    className="gel-form__input"
                    data-testid="editValue"
                />
            </td>
            <td className="dp-table__cell">
                <button className="dp-o-button--primary dp-o-button--submit" type="submit" href="#">
                    Update
                </button>
                <a className="gs-u-ml" href="#" onClick={(e) => handleClear(e)}>
                    Cancel
                </a>
                <input name="id" type="hidden" ref={methods.register} />
                <input name="app" type="hidden" ref={methods.register} />
            </td>
        </tr>
    );
};

export default EditAttributeRow;
