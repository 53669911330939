import ApplicationLayout from 'layout/containers/ApplicationLayout';
import Home from 'home/containers/Home';
import ViewPersonalProject from 'projects/containers/ViewPersonalProject';
import ViewProject from 'projects/containers/ViewProject';
import ViewProduct from 'products/containers/ViewProduct';
import CreateApp from 'apps/containers/CreateApp';
import CreateKey from 'keys/containers/CreateKey';
import AppView from 'apps/containers/AppView';
import AppMoveProject from 'apps/containers/AppMoveProject';
import EditApp from 'apps/containers/EditApp';
import DeleteApp from 'apps/containers/DeleteApp';
import ViewKey from 'keys/containers/ViewKey';
import DeleteKey from 'keys/containers/DeleteKey';
import CreateProject from 'projects/containers/CreateProject';
import EditProject from 'projects/containers/EditProject';
import ListProjects from 'projects/containers/ListProjects';
import RemoveMember from 'projects/containers/RemoveMember';
import CreateProduct from 'products/containers/CreateProduct';
import MoveProduct from 'products/containers/MoveProduct';
import EditProduct from 'products/containers/EditProduct';
import ViewProductKey from 'products/containers/ViewProductKey';
import RequestProductKey from 'products/containers/RequestProductKey';

import ErrorPage from 'errors/components/ErrorInternalServer';
import NotFoundError, { dispatch404OnEnter } from 'errors/components/ErrorNotFound';

import UiApiClient from './app/common/helpers/uiApiClient';
import * as projectService from 'projects/services/projectService';
import * as sessionService from 'sessions/services';

import prepareSessionStore from './routing-middleware/prepareSessionStore';
import prepareProjectsStore from './routing-middleware/prepareProjectsStore';
import checkSessionValidity from './routing-middleware/checkSessionValidity';

import isFeatureActive from 'common/helpers/features';

export default function(store) {
    const routes = {
        path: '/',
        component: ApplicationLayout,
        onChangeOrEnter: [checkSessionValidity(sessionService)],
        onEnter: [
            prepareSessionStore(sessionService, UiApiClient),
            prepareProjectsStore(projectService),
        ],
        indexRoute: { component: Home },
        childRoutes: [
            {
                path: 'login-required',
                component: Home,
            },
            {
                path: 'error',
                component: ErrorPage,
            },
            {
                path: 'my-apps',
                component: ViewPersonalProject,
            },
            {
                path: 'apps/new/:projectId',
                component: CreateApp,
            },
            {
                path: 'apps/new',
                component: CreateApp,
            },
            {
                path: 'apps/:id/edit',
                component: EditApp,
            },
            {
                path: 'apps/:id',
                component: AppView,
            },
            isFeatureActive('projects') && {
                path: 'apps/:id/move',
                component: AppMoveProject,
            },
            {
                path: 'apps/:id/delete',
                component: DeleteApp,
            },
            {
                path: 'keys/new',
                component: CreateKey,
            },
            {
                path: 'keys/:id',
                component: ViewKey,
            },
            {
                path: 'keys/:id/delete',
                component: DeleteKey,
            },
            {
                path: 'products',
            },
            {
                path: 'products/create/:projectId',
                component: CreateProduct,
            },
            {
                path: 'products/:productId/move',
                component: MoveProduct,
            },
            {
                path: 'products/:productId/edit',
                component: EditProduct,
            },
            {
                path: 'products/:productId/request_key',
                component: RequestProductKey,
            },
            {
                path: 'products/:productId/keys/:keyId',
                component: ViewProductKey,
            },
            {
                path: 'products/:productId/keys/:keyId/view',
                onEnter: function(_store, route, replaceRoute) {
                    replaceRoute(`/products/${route.params.productId}/keys/${route.params.keyId}`);
                },
            },
            isFeatureActive('products') && {
                path: 'products/:productId',
                component: ViewProduct,
            },
            isFeatureActive('projects') && {
                path: 'projects',
                component: ListProjects,
            },
            isFeatureActive('projects') && {
                path: 'projects/create',
                component: CreateProject,
            },
            isFeatureActive('projects') && {
                path: 'projects/:slug',
                component: ViewProject,
            },
            isFeatureActive('projects') && {
                path: 'projects/:slug/edit',
                component: EditProject,
            },
            isFeatureActive('projects') && {
                path: 'projects/:slug/members/:userId/remove',
                component: RemoveMember,
            },
            {
                path: '*',
                components: NotFoundError,
                onEnter: [dispatch404OnEnter],
            },
        ].filter((childRoute) => childRoute),
    };

    transformRoutes(routes);
    return routes;

    // TODO: it should be possible to do the more complex parts of this
    // once, up front, then simply feed the 'store' object into it on
    // every request. That would be faster (in theory).
    function transformRoutes(route) {
        // Put callbacks into arrays if they're not already:
        ['onEnter', 'onChange', 'onLeave', 'onChangeOrEnter'].forEach((key) => {
            if (!route[key]) route[key] = [];
            else if (!(route[key] instanceof Array)) route[key] = [route[key]];
        });

        // TODO - this should run BEFORE anything on onChange and onEnter
        // currently it goes (say) onEnter, this, onChangeOrEnter.
        // Add onChangeOrEnter to the onChange and onEnter arrays.
        // (This somewhat clunky construction is there as a way of
        // *prepending* this array to the existing ones.)
        while (route.onChangeOrEnter.length)
            ((handler) => {
                route.onEnter.unshift((store, newState, replace, callback) => {
                    if (handler.length > 3) handler(store, newState, replace, callback);
                    else {
                        handler(store, newState, replace);
                        callback();
                    }
                });
                route.onChange.unshift((store, prevState, newState, replace, callback) => {
                    if (handler.length > 3) handler(store, newState, replace, callback);
                    else {
                        handler(store, newState, replace);
                        callback();
                    }
                });
            })(route.onChangeOrEnter.pop());

        // Flatten arrays and store parameter to routing callbacks:
        const onEnters = route.onEnter,
            onChanges = route.onChange,
            onLeaves = route.onLeave;

        route.onEnter = (state, replace, callback) => {
            let i = 0;
            next(false);
            function next(abort) {
                let handler = onEnters[i++];
                if (abort || !handler) callback();
                else if (handler.length > 3) handler(store, state, replace, next);
                else {
                    handler(store, state, replace);
                    next();
                }
            }
        };
        route.onChange = (prevState, newState, replace, callback) => {
            let i = 0;
            next(false);
            function next(abort) {
                let handler = onChanges[i++];
                if (abort || !handler) callback();
                else if (handler.length > 4) handler(store, prevState, newState, replace, next);
                else {
                    handler(store, prevState, newState, replace);
                    next();
                }
            }
        };
        // onLeave is very simple so we can just run them all naïvely.
        route.onLeave = (prevState) => onLeaves.forEach((onLeave) => onLeave(store, prevState));

        // Transform the children
        if (route.childRoutes) for (let child of route.childRoutes) transformRoutes(child);

        return route;
    }
}
