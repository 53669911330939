export const createProjectRules = {
    displayName: {
        required: true,
        ofLength: {
            min: 0,
            max: 100,
        },
        notBlank: true,
    },
};

export const addMemberToProjectRules = {
    emailAddress: {
        required: true,
        ofLength: {
            min: 0,
            max: 100,
        },
        notBlank: true,
    },
};
