// TODO: this is clearly a copy-paste of determinePersonalProject
// (which is in app/projects/state/index.js)
// and they should be combined to reduce the duplication
// and create more consistency in our code.
export default function getPersonalProjectSlug(session = false, projects = {}) {
    if (!session || !projects) {
        return false;
    }

    const foundPersonalProject = Object.keys(projects)
        .map((projectSlug) => projects[projectSlug])
        .filter((project) => project.owner == session.user.id);

    if (foundPersonalProject.length === 0 || !foundPersonalProject[0].slug) {
        return false;
    }

    return foundPersonalProject[0].slug;
}
