import React from 'react';
import PropTypes from 'prop-types';

import { InputField, TextareaField, SubmitButton, HiddenField } from 'forms';
import FormFieldHelp from 'common/components/FormFieldHelp';

const AppEditForm = ({ submittingText = 'Processing...', buttonVerb = 'Create' }) => {
    return (
        <div>
            <div className="gel-layout">
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <InputField
                        name="displayName"
                        type="text"
                        label="Application Name"
                        describedBy="display-name-field-help"
                        required
                    />
                </div>
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <FormFieldHelp id="display-name-field-help">
                        A descriptive name for your application.
                    </FormFieldHelp>
                </div>
            </div>

            <div className="gel-layout">
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <TextareaField
                        name="description"
                        type="text"
                        label="Application Description"
                        describedBy="description-field-help"
                        required
                    />
                </div>
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <FormFieldHelp id="description-field-help">
                        Briefly explain the purpose of your application. When requesting API keys
                        this will help the API owner understand what your application does. Add a
                        link to the project if you have one.
                    </FormFieldHelp>
                </div>
            </div>

            <div className="gel-layout">
                <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                    <SubmitButton
                        name="submit_app"
                        submittingText={submittingText}
                        className="dp-o-button--primary dp-o-button--full"
                    >
                        {buttonVerb} app
                    </SubmitButton>

                    <HiddenField name="projectId" />
                    <HiddenField name="id" />
                </div>
            </div>
        </div>
    );
};

export default AppEditForm;

AppEditForm.propTypes = {
    submittingText: PropTypes.string.isRequired,
    buttonVerb: PropTypes.string.isRequired,
};
