import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router';

import DocumentTitle from 'common/components/DocumentTitle';
import ProjectListing from 'projects/components/ProjectListing';
import { fetchMyProjects } from 'projects/services/projectService';
import doTeamProjectsExist from 'projects/helpers/doTeamProjectsExist';
import ContentBlock from 'common/components/ContentBlock';

const VIEW_TITLE = 'My Projects';

function ListProjects() {
    const dispatch = useDispatch();

    useEffect(() => {
        const store = { dispatch };
        fetchMyProjects(store);
    }, []);

    const myProjects = useSelector((state) => {
        return state.projects.projects || [];
    });

    const projectsMine = useSelector((state) => {
        return state.projects.mine || {};
    });

    const loadingProjects = useSelector((state) => {
        return state.projects.loadingProjects;
    });

    const teamProjectsExist = doTeamProjectsExist(myProjects, projectsMine);

    return (
        <DocumentTitle title={VIEW_TITLE}>
            <React.Fragment>
                <ContentBlock>
                    <div className="gs-u-align-right">
                        {teamProjectsExist && (
                            <Link
                                to="/projects/create"
                                className="dp-o-button--secondary dp-e-add-projects gs-u-ml"
                                title="Create new project"
                            >
                                Create project
                            </Link>
                        )}
                    </div>
                    <h1 className="dp-h1 project-view__field display_name">{VIEW_TITLE}</h1>
                    <div className="project-view__field description">
                        <p className="gel-pica">
                            Below you will find a list of the projects that you are a member of. A
                            project can have many members who can all access the apps contained
                            within.
                        </p>
                    </div>
                    <div className="project-view__field warning">
                        <p className="gel-pica">
                            If you are unexpectedly missing a project, please contact the API
                            Management team via email -{' '}
                            <a href="mailto:accessfoundations@bbc.co.uk">accessfoundations@bbc.co.uk</a>
                        </p>
                    </div>
                </ContentBlock>
                <ContentBlock>
                    {!loadingProjects && teamProjectsExist && (
                        <ProjectListing
                            className="gel-10/12"
                            myProjects={myProjects}
                            projectsMine={projectsMine}
                        />
                    )}

                    {loadingProjects && (
                        <div className="project-view__field" id="projects-loading">
                            <p>Loading your projects...</p>
                        </div>
                    )}

                    {!teamProjectsExist && (
                        <div>
                            <p className="no-apps gel-pica">You do not have any projects.</p>

                            <Link
                                to="/projects/create"
                                className="dp-o-button--cta gs-u-mt+ dp-e-add-projects"
                            >
                                Create project
                            </Link>
                        </div>
                    )}
                </ContentBlock>
            </React.Fragment>
        </DocumentTitle>
    );
}

//TODO: remove ?
export { VIEW_TITLE };

export default ListProjects;
