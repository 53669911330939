import React from 'react';
import { Link } from 'react-router';

import KeyStatusUpdateOptions from './KeyStatusUpdateOptions';

const KeysAndAppsList = ({ keyList }) => {
    return (
        <table className="gs-o-list-ui dp-table">
            <thead>
                <tr className="dp-table__row gs-o-list-ui__item">
                    <th className="dp-table__cell">App name</th>
                    <th className="dp-table__cell">API key</th>
                    <th className="dp-table__cell">Issued date</th>
                    <th className="dp-table__cell">Status</th>
                    <th className="dp-table__cell">Options</th>
                </tr>
            </thead>
            <tbody>
                {keyList.map((keyItem) => {
                    const issuedDate = new Date(keyItem.issued);

                    return (
                        <tr className="dp-table__row gs-o-list-ui__item" key={keyItem.id}>
                            <td className="dp-table__cell">
                                <Link to={`/products/${keyItem.apiProduct}/keys/${keyItem.id}`}>
                                    {keyItem.app.displayName}
                                </Link>
                            </td>
                            <td className="dp-table__cell">{keyItem.apiKey}</td>
                            <td className="dp-table__cell">{issuedDate.toDateString()}</td>
                            <td className="dp-table__cell">{keyItem.status}</td>
                            <td className="dp-table__cell">
                                <KeyStatusUpdateOptions id={keyItem.id} status={keyItem.status} />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default KeysAndAppsList;
