export const STORE_SEARCH_RESULTS = Symbol('STORE_SEARCH_RESULTS');

export const initialState = {};

export default function reducer(state = initialState, action = { type: null }) {
    switch (action.type) {
        case STORE_SEARCH_RESULTS:
            return Object.assign({}, action);

        default:
            return state;
    }
}

export function storeSearchResults(search, results) {
    return {
        type: STORE_SEARCH_RESULTS,
        search,
        results,
    };
}
