import React from 'react';

import { useForm, FormContext } from 'react-hook-form';
import { HiddenField, RadioButton, TextArea, TextField } from 'form-components';
import FormFieldHelp from 'common/components/FormFieldHelp';
import SharedProjectList from './SharedProjectList';
import { createApp } from 'apps/handlers';
import { useDispatch } from 'react-redux';

const AppForm = (props) => {
    const { projectId, personalProject, sharedProjects, redirectUrl } = props;

    const defaultValues = { projectId };
    if (projectId) {
        const ownership = projectId === personalProject.id ? 'personal' : 'team';
        defaultValues.ownership = ownership;
    }

    const form = useForm({
        defaultValues,
    });

    const selectedOwnership = form.watch('ownership');
    const selectedProjectId = form.watch('projectId');

    const canSubmit = selectedOwnership == 'personal' || selectedProjectId != personalProject.id;

    const dispatch = useDispatch();
    const onSubmit = (data) => {
        if (selectedOwnership === 'personal') {
            data.projectId = personalProject.id;
        }
        createApp(dispatch, data);
    };
    return (
        <FormContext {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <fieldset className="p-3 mb-3">
                    <legend>
                        <strong>Ownership</strong>
                    </legend>
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-7/12@m gel-6/12@xxl">
                            <div className="mb-3">
                                Will the API keys in this app be for your personal, ad-hoc use (e.g.
                                postman, curl) or will they be used and owned by your team?
                            </div>

                            <div className="gel-form__field">
                                <RadioButton name="ownership" value="team">
                                    Team: this app's API keys will be used and managed by my team
                                </RadioButton>

                                <RadioButton name="ownership" value="personal">
                                    Personal: this app's API keys are for ad-hoc/expiremental
                                    use&nbsp;
                                    <strong>by me only</strong>
                                </RadioButton>
                            </div>

                            {selectedOwnership == 'team' ? (
                                <div className="mt-3">
                                    <SharedProjectList
                                        sharedProjects={sharedProjects}
                                        selected={projectId}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <div style={{ color: '#888' }}>
                                        ⚠️ Ownership of personal keys will be lost if you leave the
                                        BBC. Proceed with care!
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </fieldset>

                <fieldset className="p-3 mb-3">
                    <legend>
                        <strong>Details</strong>
                    </legend>
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                            <TextField
                                name="displayName"
                                label="Application Name"
                                registerOptions={{ required: 'This field is required.' }}
                            />
                        </div>
                        <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                            <FormFieldHelp id="display-name-field-help">
                                A descriptive name for your application.
                            </FormFieldHelp>
                        </div>
                    </div>

                    <div className="gel-layout">
                        <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                            <TextArea
                                name="description"
                                label="Application Description"
                                registerOptions={{ required: 'This field is required.' }}
                            />
                        </div>
                        <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                            <FormFieldHelp id="description-field-help">
                                Briefly explain the purpose of your application. When requesting API
                                keys this will help the API owner understand what your application
                                does. Add a link to the project if you have one.
                            </FormFieldHelp>
                        </div>
                    </div>
                </fieldset>

                <div className="gel-layout px-3">
                    <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                        <button
                            className="dp-o-button--primary dp-o-button--full"
                            disabled={!canSubmit}
                        >
                            Create app
                        </button>
                    </div>
                </div>
                <HiddenField name="redirectUrl" value={redirectUrl} />
            </form>
        </FormContext>
    );
};

export default AppForm;
