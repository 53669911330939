import UiApiClient from 'common/helpers/uiApiClient';
import cameliseObjKeys from 'common/helpers/cameliseObjKeys';

import {
    storeKey,
    storeKeys,
    forgetKey,
    loadingKey as loadingKeyAction,
    loadedKey as loadedKeyAction,
    loadingKeyCollection as loadingKeyCollectionAction,
    loadedKeyCollection as loadedKeyCollectionAction,
} from 'keys/state';

import { EntityNotFound } from 'errors/errorTypes';

import { storeApps } from 'apps/state';

export function createAPIKey(store, apiKey) {
    return new UiApiClient(store)
        .post('keys', {
            data: apiKeyToEntity(apiKey),
        })
        .then((response) => {
            const apiKey = responseToAPIKey(response);
            return apiKey;
        });
}

export function fetchKeysForApp(store, app) {
    return new UiApiClient(store)
        .get('keys?app=' + app.id)
        .then((response) => {
            const keys = response.entities ? response.entities.map(responseToAPIKey) : [];
            store.dispatch(storeKeys(keys));
            return keys;
        })
        .catch((_error) => {
            // We're swallowing the error here because before it was never caught in the first place.
            // This was causing issues with Tests.
            // We don't have ErrorBoundaries in place at the moment, something we want to look into to
            // catch errors like this.
            // TODO: refactor error handling in services.
        });
}

function extractAndFlattenAppsArray(entities) {
    const apps = entities.map((key) => {
        return key.entities.map((entity) => cameliseObjKeys(entity.properties));
    });

    return [].concat.apply([], apps);
}

export async function fetchKeysForProductWithApps(store, productId) {
    const path = `keys?api_product=${productId}&include=app`;

    store.dispatch(loadingKeyCollectionAction());

    return new UiApiClient(store)
        .get(path)
        .then((response) => {
            let keys = [];
            let apps = [];

            if (response.entities) {
                keys = response.entities.map(responseToAPIKey);
                apps = extractAndFlattenAppsArray(response.entities);
            }

            store.dispatch(storeApps(apps));
            store.dispatch(storeKeys(keys));
        })
        .catch((_error) => {
            // We're swallowing the error here because before it was never caught in the first place.
            // This was causing issues with Tests.
            // We don't have ErrorBoundaries in place at the moment, something we want to look into to
            // catch errors like this.
            // TODO: refactor error handling in services.
        })
        .finally(() => {
            store.dispatch(loadedKeyCollectionAction());
        });
}

export async function fetchKeyForProductWithApp(productId, keyId) {
    const path = `keys?api_product=${productId}&include=app`;

    const response = await new UiApiClient().get(path);

    let requestedKey;

    try {
        requestedKey = response.entities.find((entity) => {
            return entity.properties.id === keyId;
        });

        if (!requestedKey) {
            throw new EntityNotFound('Requested key was not found');
        }
    } catch (err) {
        const error = {};

        error.properties = {
            statusCode: err.statusCode || 404,
            message: err.message,
        };

        throw error;
    }

    const key = requestedKey.properties;
    const app = requestedKey.entities.find((entity) => {
        return entity.class.includes('app');
    }).properties;

    return {
        app: cameliseObjKeys(app),
        key: cameliseObjKeys(key),
    };
}

export function fetchKey(store, keyId) {
    store.dispatch(loadingKeyAction(keyId));

    return new UiApiClient(store)
        .get('keys/' + keyId)
        .then((response) => {
            const key = responseToAPIKey(response);
            store.dispatch(storeKey(key));

            return key;
        })
        .catch((_error) => {
            // We're swallowing the error here because before it was never caught in the first place.
            // This was causing issues with Tests.
            // We don't have ErrorBoundaries in place at the moment, something we want to look into to
            // catch errors like this.
            // TODO: refactor error handling in services.
        })
        .finally(() => {
            store.dispatch(loadedKeyAction(keyId));
        });
}

export function deleteKey(store, keyId) {
    return new UiApiClient(store).del('keys/' + keyId).then(() => store.dispatch(forgetKey(keyId)));
}

export function updateKeyStatus(key) {
    const data = { status: key.status };
    return new UiApiClient().put(`access/${key.id}`, { data }).then((res) => {
        return responseToAPIKey(res);
    });
}

export function fetchKeysForProject(projectId) {
    return new UiApiClient().get(`keys?app_project=${projectId}`).then((response) => {
        const keys = response.entities ? response.entities.map(responseToAPIKey) : [];
        return keys;
    });
}

function responseToAPIKey(response) {
    return cameliseObjKeys(response.properties);
}

function apiKeyToEntity(apiKey) {
    return {
        api_product: apiKey.apiProduct,
        app: apiKey.app,
    };
}
