import React, { useEffect } from 'react';
import DocumentTitle from 'common/components/DocumentTitle';
import { Link } from 'react-router';
import { useSelector, useStore, useDispatch } from 'react-redux';
import { mapToArray } from 'common/helpers/utils';
import { enableForm } from 'forms/helpers/enableForm';
import { fetchKeysForApp } from 'keys/services/keysService';
import { handleDeleteConfirmation } from 'apps/handlers';
import { fetchApp } from 'apps/services/appsService';
import ErrorNotFound from 'errors/components/ErrorNotFound';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import DeleteAppForm from 'apps/components/DeleteAppForm';
import { fetchProject } from 'projects/actions';

const formOptions = {
    formId: 'delete-app',
    rules: {},
    onValidated: handleDeleteConfirmation,
};

const FormEnabledDeleteApp = enableForm(formOptions)(DeleteAppForm);

const findAppProject = (state, appId) => {
    const project = Object.values(state.projects.projects).find((project) => {
        return project.id === state.apps.apps[appId].project;
    });
    return project;
};

const DeleteApp = ({ params }) => {
    const store = useStore();

    const { app, keys, appLoaded } = useSelector((state) => {
        const app = state.apps.apps && state.apps.apps[params.id] ? state.apps.apps[params.id] : {};
        const appLoaded = state.apps.loaded[params.id];

        return {
            app,
            appLoaded,
            keys: state.keys.keys
                ? mapToArray(state.keys.keys).filter((key) => key.app == params.id)
                : [],
        };
    });

    const project = useSelector((state) => {
        let appProject;
        if (state.apps.apps && state.apps.apps[params.id]) {
            appProject = findAppProject(state, params.id);
        }
        if (appProject) {
            return appProject;
        } else {
            return {};
        }
    });

    const dispatch = useDispatch();

    useEffect(() => {
        fetchApp(store, params.id);
        fetchKeysForApp(store, { id: params.id });
    }, []);

    useEffect(() => {
        if (app.project) {
            dispatch(fetchProject(app.project));
        }
    }, [app]);

    if (appLoaded && !app.id) return <ErrorNotFound />; // TODO: bigger conversation to be had about error management

    const appHasKeys = keys.length > 0;
    const title = !appHasKeys
        ? `Confirm deletion of ${app.displayName}`
        : `You cannot delete ${app.displayName}`;

    const redirectUrl = project.owner ? '/my-apps/' : `/projects/${project.slug}`;

    return (
        <DocumentTitle title={`${app.displayName || 'Loading'} app`}>
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item gel-1/1 gel-8/12@l key-delete dp-c-key-delete">
                        <LoadingPlaceholder waitFor={app.id}>
                            <h1 className="gs-u-mb0 dp-h1">{title}</h1>
                            <LoadingPlaceholder waitFor={project.id}>
                                {!appHasKeys && (
                                    <FormEnabledDeleteApp
                                        app={app}
                                        initialValues={{ id: params.id, redirectUrl }}
                                    />
                                )}

                                {appHasKeys && (
                                    <div>
                                        <p className="gs-u-mt++">
                                            You cannot delete the {app.displayName} app whilst there
                                            are still API keys associated with it.
                                        </p>
                                        <p className="gs-u-mb+">
                                            Please delete the remaining{' '}
                                            {keys.length > 1 ? keys.length + ' keys' : 'key'}, and
                                            try again.
                                        </p>
                                        <Link
                                            to={'/apps/' + app.id}
                                            className="dp-o-button--primary"
                                        >
                                            Return to app
                                        </Link>
                                    </div>
                                )}
                            </LoadingPlaceholder>
                        </LoadingPlaceholder>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};

export default DeleteApp;
