import React from 'react';
import PropTypes from 'prop-types';

// Error components
import InternalServerError from 'errors/components/ErrorInternalServer';
import NotFound from 'errors/components/ErrorNotFound';
import AccessDenied from 'errors/components/ErrorAccessDenied';

const ErrorHandler = ({ code, message, debug, children }) => {
    if (code && code >= 400) {
        return renderErrorContent({ code, message, debug });
    }

    return <div>{children}</div>;
};

function renderErrorContent(props) {
    switch (props.code) {
        case 404:
            return <NotFound {...props} />;
        case 403:
            return <AccessDenied {...props} />;
        default:
            return <InternalServerError {...props} />;
    }
}

ErrorHandler.propTypes = {
    code: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    debug: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

export default ErrorHandler;
