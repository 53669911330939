import React from 'react';
import DocumentTitle from 'common/components/DocumentTitle';
import { throwError } from 'errors/state';

const ErrorNotFound = (props) => (
    <DocumentTitle title={false}>
        <div>
            <div className="dp-c-banner-image__404" />
            <section className="dp-main">
                <header>
                    <h1 className="dp-h2">How strange</h1>
                </header>
                <div className="gel-layout">
                    <div className="gel-1/1 gel-8/12@l gel-layout__item">
                        <p>There doesn’t seem to be anything here...</p>
                        {__DEVELOPMENT__ && <pre>{JSON.stringify(props, null, 2)}</pre>}
                    </div>
                </div>
            </section>
        </div>
    </DocumentTitle>
);

export function dispatch404OnEnter(store) {
    store.dispatch(throwError(404));
}

export default ErrorNotFound;
