import { addMessage, flushAllMessages } from 'flashMessages/state';
import * as productsService from 'products/services/productsService';
import { redirect } from 'common/state/redirect';

export function updateKeyStatus(dispatch, rawData, _replaceState, callback) {
    const newStatus = rawData['change-key-status'];
    return productsService
        .updateKeyStatus(
            { dispatch },
            {
                id: rawData['key-id'],
                status: newStatus,
            },
        )
        .then(callback);
}

export function handleError(err, dispatch) {
    let errorMessage = `Failed to register API product, please try again.`;

    switch (err.properties.statusCode) {
        case 409:
            errorMessage =
                'Product Identifier already exists. Please provide a different Product Identifier';
            break;

        case 422: {
            const filteredErrors = err.properties.entities.filter((err) => err.properties.field);
            const error = filteredErrors[0].properties;

            if (
                error.id === 'ERR_VALIDATE_IS_NOT_EMPTY_ARRAY' &&
                error.field === 'default_notify_emails'
            ) {
                errorMessage =
                    'Notification subscribers field requires at least one email address when approval type is manual.';
            }
        }
    }

    dispatch(flushAllMessages());
    dispatch(addMessage(errorMessage));

    window.scrollTo(0, 0);
}

export async function registerAPIProduct(dispatch, data) {
    try {
        const registeredProduct = await productsService.registerAPIProduct({ dispatch }, data);

        dispatch(
            redirect({
                pathname: `/products/${registeredProduct.id}`,
            }),
        );
    } catch (err) {
        handleError(err, dispatch);
    }
}

export async function updateAPIProduct(dispatch, data) {
    try {
        const updatedProduct = await productsService.updateAPIProduct({ dispatch }, data);

        dispatch(
            redirect({
                pathname: `/products/${updatedProduct.id}`,
            }),
        );
    } catch (err) {
        handleError(err, dispatch);
    }
}
