import React from 'react';

const ErrorInternalServer = (props) => (
    <div className="dp-main">
        <p>Internal server error.</p>
        <pre>{JSON.stringify(props, null, 2)}</pre>
    </div>
);

export default ErrorInternalServer;
