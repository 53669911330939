import React from 'react';
import DocumentTitle from 'common/components/DocumentTitle';
import { enableForm } from 'forms/helpers/enableForm';
import ProjectForm from 'projects/components/ProjectForm';
import { createProjectRules } from 'projects/validation/rules';
import { createProject } from 'projects/handlers/projectsFormHandler';
import customErrorMessages from 'projects/validation/messages';

const VIEW_TITLE = 'Create New Project';

const formOptions = {
    formId: 'create-project',
    rules: createProjectRules,
    messages: customErrorMessages,
    onValidated: createProject,
};

const FormEnabledProjectForm = enableForm(formOptions)(ProjectForm);

const CreateProject = (props) => {
    const initialValues = {
        redirectUrl: props.location.query.redirectUrl,
    };
    return (
        <DocumentTitle title={VIEW_TITLE}>
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item">
                        <h1 className="gs-u-mb++ dp-h1">{VIEW_TITLE}</h1>
                    </div>
                </div>
                <FormEnabledProjectForm initialValues={initialValues} />
            </div>
        </DocumentTitle>
    );
};

export default CreateProject;
