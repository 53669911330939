export function cameliseObjKeys(props) {
    const camel = {};
    for (let key in props) camel[key.replace(/_([a-z])/g, (t, g) => g.toUpperCase())] = props[key];
    return camel;
}

export function snakifyObjKeys(props) {
    const snake = {};
    for (let key in props)
        snake[key.replace(/([A-Z])/g, (t, g) => '_' + g.toLowerCase())] = props[key];
    return snake;
}

// TODO - remove this and rename the module:
export default cameliseObjKeys;

// TODO - in fact this whole section should just pull in the (de)serialisation code from the API project because it really makes no sense to implement all this stuff twice and after all that is supposed to be the beauty of running your servers on a language invented to move buttons around a webpage
