import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { fetchApp } from 'apps/services/appsService';
import { fetchKeysForApp } from 'keys/services/keysService';
import { fetchProducts } from 'products/services/productsService';
import { fetchMyProjects } from 'projects/services/projectService';

import DocumentTitle from 'common/components/DocumentTitle';
import DangerZone from 'common/components/DangerZone';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import { mapToArray } from 'common/helpers/utils';

import { Link } from 'react-router';
import KeyList from 'keys/components/KeyList';
import MoveProjectButton from 'apps/components/MoveProjectButton';
import ProjectOwnershipInformation from 'apps/components/ProjectOwnershipInformation';
import ContentBlock from 'common/components/ContentBlock';

function determineLoadingProps(state, appId) {
    const loadingContent =
        state.apps.loading[appId] ||
        state.products.loadingProducts ||
        state.projects.loadingProjects ||
        false;

    const loadedContent =
        (state.apps.loaded[appId] &&
            state.products.loadedProducts &&
            state.projects.loadedProjects) ||
        false;

    return {
        loadingContent,
        loadedContent,
    };
}

const AppView = ({ params }) => {
    const loadingPlaceholder = 'Loading app...';

    const appId = params.id;
    const dispatch = useDispatch();

    const app = useSelector((state) => {
        return state.apps.apps[appId] || {};
    });

    const keys = useSelector((state) => {
        return mapToArray(state.keys.keys).filter((key) => key.app == appId);
    });

    const products = useSelector((state) => {
        return mapToArray(state.products.products);
    });

    const { projects, projectsMine, projectForApp } = useSelector((state) => {
        const projectId = app && app.project;
        const projectForApp = Object.values(state.projects.projects).find(
            (project) => project.id === projectId,
        );
        return {
            projectForApp,
            projects: state.projects.projects,
            projectsMine: state.projects.mine,
        };
    });

    const { loadedContent, loadingContent } = useSelector((state) => {
        return {
            ...determineLoadingProps(state, appId),
        };
    });

    useEffect(() => {
        const store = { dispatch };

        fetchApp(store, params.id);
        fetchKeysForApp(store, { id: params.id });
        fetchProducts(store);
        fetchMyProjects(store);
    }, []);

    const isTeamProjectApp = projectForApp && projectForApp.owner === null;

    return (
        <DocumentTitle title={app.displayName || 'Loading...'}>
            <React.Fragment>
                <ContentBlock>
                    <LoadingPlaceholder waitFor={app} placeholder="">
                        <LoadingPlaceholder waitFor={projects} placeholder="">
                            <div className="gs-u-align-right">
                                <MoveProjectButton
                                    appId={app.id}
                                    projects={projects}
                                    projectsMine={projectsMine}
                                    isPersonalProject={!isTeamProjectApp}
                                />
                                <Link
                                    to={`/apps/${app.id}/edit`}
                                    className="dp-o-button--secondary gs-u-ml"
                                    title="Edit application name and description"
                                >
                                    Edit
                                </Link>
                            </div>
                        </LoadingPlaceholder>
                    </LoadingPlaceholder>
                    <h1 className="dp-h1 app-view__field displayName">
                        <LoadingPlaceholder waitFor={app} placeholder={loadingPlaceholder}>
                            {app.displayName}
                        </LoadingPlaceholder>
                    </h1>

                    <div className="app-view__field description gel-great-primer">
                        <LoadingPlaceholder waitFor={app} placeholder={loadingPlaceholder}>
                            {app.description}
                        </LoadingPlaceholder>
                    </div>

                    <div>
                        <br />
                        <div
                            id="project-membership-info"
                            className="app-view__field project_membership"
                        >
                            <LoadingPlaceholder waitFor={app} placeholder={loadingPlaceholder}>
                                <ProjectOwnershipInformation
                                    isTeamProjectApp={isTeamProjectApp}
                                    app={app}
                                    parentProject={projectForApp}
                                />
                            </LoadingPlaceholder>
                        </div>
                    </div>
                </ContentBlock>

                <hr className="dp-hr" />

                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-1/1 gel-8/12@l">
                            {(Object.keys(keys).length > 0 || loadedContent) && (
                                <KeyList
                                    app={app}
                                    keys={keys}
                                    products={products}
                                    projects={mapToArray(projects)}
                                />
                            )}

                            {loadingContent && <p>Loading API keys...</p>}
                        </div>
                    </div>
                </div>

                <hr className="dp-hr" />

                <div className="dp-main">
                    <div className="gel-layout">
                        <div className="gel-layout__item gel-1/1 gel-8/12@l">
                            <DangerZone
                                isDeletable={keys.length === 0}
                                deleteWarning="Apps cannot be restored after deletion."
                                undeletableReason="You cannot delete this app because it contains API keys."
                                deleteUrl={`/apps/${app.id}/delete`}
                                deleteButtonText="Delete app"
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default AppView;
