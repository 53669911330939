const STORE_APPS = Symbol('STORE_APPS');
const STORE_APP = Symbol('STORE_APP');
const FORGET_APP = Symbol('FORGET_APP');
const STORE_PROJECT_APPS = Symbol('STORE_PROJECT_APPS');
const GET_PROJECT_APPS = Symbol('GET_PROJECT_APPS');

const LOADING_APPS = Symbol('LOADING_APPS');
const LOADING_APP = Symbol('LOADING_APP');
const LOADED_APPS = Symbol('LOADED_APPS');
const LOADED_APP = Symbol('LOADED_APP');

export const initialState = {
    apps: {},
    projectApps: {},
    loadingApps: false,
    loadedApps: false,
    loading: {},
    loaded: {},
};

export function arrayUnion(...arrays) {
    const mergedArrays = [].concat(...arrays);
    return [...new Set([...mergedArrays])];
}

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case STORE_APP: {
            const app = action.app;
            const appId = `${app.id}`
            const existingProjectApps = state.projectApps[app.project] || [];

            const projectApps = {
                ...state.projectApps,
                [app.project]: arrayUnion(existingProjectApps, [appId]),
            };

            const newState = {
                ...state,
                apps: {
                    ...state.apps,
                    [appId]: action.app,
                },
                projectApps,
            };

            return newState;
        }

        case STORE_APPS: {
            const newApps = {};

            action.apps.forEach((app) => {
                newApps[`${app.id}`] = app;
            });

            return {
                ...state,
                apps: Object.assign({}, state.apps, newApps),
            };
        }

        case STORE_PROJECT_APPS: {
            const newProjectApps = {};
            const projectAppIds = [];

            action.apps.forEach((app) => {
                const appId = `${app.id}`;
                newProjectApps[appId] = app;
                projectAppIds.push(appId);
            });

            return {
                ...state,
                apps: Object.assign({}, state.apps, newProjectApps),
                projectApps: {
                    ...state.projectApps,
                    [action.projectId]: projectAppIds,
                },
            };
        }

        case FORGET_APP: {
            const deletedAppId = `${action.id}`;
            const updateProjectApps = {};

            Object.keys(state.projectApps).forEach((projectId) => {
                updateProjectApps[projectId] = state.projectApps[projectId].filter((appId) => {
                    return deletedAppId !== appId;
                });
            });

            return Object.assign({}, state, {
                apps: Object.keys(state.apps).reduce((result, key) => {
                    if (key !== action.id) {
                        result[key] = state.apps[key];
                    }
                    return result;
                }, {}),
                projectApps: updateProjectApps,
            });
        }

        case LOADING_APPS: {
            return Object.assign({}, state, {
                loadingApps: true,
                loadedApps: false,
            });
        }

        case LOADED_APPS: {
            return Object.assign({}, state, {
                loadingApps: false,
                loadedApps: true,
            });
        }

        case LOADING_APP: {
            return Object.assign({}, state, {
                loading: Object.assign({}, state.loading, {
                    [action.appId]: true,
                }),
                loaded: Object.assign({}, state.loaded, {
                    [action.appId]: false,
                }),
            });
        }

        case LOADED_APP: {
            return Object.assign({}, state, {
                loading: Object.assign({}, state.loading, {
                    [action.appId]: false,
                }),
                loaded: Object.assign({}, state.loaded, {
                    [action.appId]: true,
                }),
            });
        }

        default: {
            return state;
        }
    }
}

export function loadingApps() {
    return {
        type: LOADING_APPS,
    };
}

export function loadedApps() {
    return {
        type: LOADED_APPS,
    };
}

export function loadingApp(appId) {
    return {
        type: LOADING_APP,
        appId,
    };
}

export function loadedApp(appId) {
    return {
        type: LOADED_APP,
        appId,
    };
}

export function storeApps(apps) {
    return {
        type: STORE_APPS,
        apps,
    };
}

export function storeApp(app) {
    return {
        type: STORE_APP,
        app,
    };
}

export function forgetApp(appId) {
    return {
        type: FORGET_APP,
        id: `${appId}`
    };
}

export function storeProjectApps(projectId, apps) {
    return {
        type: STORE_PROJECT_APPS,
        projectId: `${projectId}`,
        apps,
    };
}

export function getProjectApps(projectId) {
    return {
        type: GET_PROJECT_APPS,
        projectId,
    };
}

export function appsMiddleware() {
    return function({ getState }) {
        return function(next) {
            return function(action) {
                switch (action.type) {
                    case GET_PROJECT_APPS: {
                        const state = getState();
                        const apps = state.apps;
                        const projectAppIds = state.projectApps[action.projectId] || [];

                        return projectAppIds
                            .map((appId) => {
                                return apps[appId];
                            })
                            .filter((item) => item);
                    }

                    default: {
                        return next(action);
                    }
                }
            };
        };
    };
}
