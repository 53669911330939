import React from 'react';
import { useDispatch } from 'react-redux';
import { updateKeyStatus } from 'products/services/productsService';

const KeyStatusUpdateOption = ({ id, newStatus, text }) => {
    const dispatch = useDispatch();

    const onClick = () => updateKeyStatus({ dispatch }, { id, status: newStatus });

    return <button onClick={onClick}>{text}</button>;
};

const KeyStatusUpdateOptions = ({ id, status }) => {
    switch (status) {
        case 'approved':
            return <KeyStatusUpdateOption id={id} newStatus="revoked" text="Revoke" />;

        case 'pending':
            return (
                <React.Fragment>
                    <KeyStatusUpdateOption id={id} newStatus="approved" text="Approve" />
                    <KeyStatusUpdateOption id={id} newStatus="rejected" text="Reject" />
                </React.Fragment>
            );

        case 'revoked':
        case 'rejected':
            return <KeyStatusUpdateOption id={id} newStatus="approved" text="Grant Access" />;
        default:
            return null;
    }
};

export default KeyStatusUpdateOptions;
