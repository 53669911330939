import React from 'react';
import { Link } from 'react-router';

const compare = new Intl.Collator('en', { numeric: true }).compare;

const separateNames = (myProjects) => (acc, slug) => {
    acc[isNaN(myProjects[slug].display_name) ? 'stringNames' : 'numberNames'].push(
        myProjects[slug],
    );

    return acc;
};

const compareNames = (a, b) => compare(a.display_name, b.display_name);

const sortByProjectDisplayName = (teamProjects, myProjects) => {
    const { numberNames, stringNames } = teamProjects.reduce(separateNames(myProjects), {
        numberNames: [],
        stringNames: [],
    });

    return [].concat(numberNames.sort(compareNames), stringNames.sort(compareNames));
};

function renderListItem(project) {
    return (
        <li id={project.slug} key={project.id} className="gs-o-list-ui__item">
            <Link to={`/projects/${project.slug}`}>{project.display_name}</Link>
        </li>
    );
}

const renderTeamProjects = (myProjects, projectsMine) => {
    const teamProjects = Object.keys(myProjects).filter((slug) => {
        return myProjects[slug].owner === null && projectsMine[slug] === true;
    });

    return sortByProjectDisplayName(teamProjects, myProjects).map(renderListItem);
};

const ProjectListing = ({ myProjects = {}, projectsMine = {} } = {}) => (
    <ul className="gs-o-list-ui dp-c-project-list">
        {renderTeamProjects(myProjects, projectsMine)}
    </ul>
);

export default ProjectListing;
