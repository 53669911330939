import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DocumentTitle from 'common/components/DocumentTitle';
import { enableForm } from 'forms/helpers/enableForm';
import ProjectForm from 'projects/components/ProjectForm';
import { createProjectRules } from 'projects/validation/rules';
import { updateProject } from 'projects/handlers/projectsFormHandler';
import customErrorMessages from 'projects/validation/messages';
import { fetchProjectBySlug, fetchProjectMembersWithAccessCheck } from 'projects/actions';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';

const VIEW_TITLE = 'Edit Project';

export const formOptions = {
    formId: 'edit-project',
    rules: createProjectRules,
    messages: customErrorMessages,
    onValidated: updateProject,
};

const FormEnabledProjectForm = enableForm(formOptions)(ProjectForm);

const EditProject = ({ params }) => {
    const projectSlug = params.slug;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProjectBySlug(projectSlug));
    }, []);

    const { project, projectLoaded, projectMembersLoaded } = useSelector((state) => {
        return {
            ...determineLoadingProps(state, projectSlug),
        };
    });

    useEffect(() => {
        if (project.id) {
            dispatch(fetchProjectMembersWithAccessCheck(project));
        }
    }, [project]);

    const dataLoaded = project.id && projectLoaded && projectMembersLoaded;

    return (
        <DocumentTitle title={VIEW_TITLE}>
            <div className="dp-main">
                <div className="gel-layout">
                    <div className="gel-layout__item">
                        <h1 className="gs-u-mb++ dp-h1">{VIEW_TITLE}</h1>
                    </div>
                </div>
                <LoadingPlaceholder waitFor={dataLoaded}>
                    <FormEnabledProjectForm
                        buttonVerb="Update"
                        submittingText="Updating project..."
                        initialValues={{
                            displayName: project.display_name,
                            id: project.id,
                            owner: project.owner,
                            originalSlug: project.slug,
                        }}
                    />
                </LoadingPlaceholder>
            </div>
        </DocumentTitle>
    );
};

function determineLoadingProps(state, myProjectSlug) {
    return {
        project: state.projects.projects[myProjectSlug] || {},
        projectLoaded: state.projects.loaded[myProjectSlug] || false,
        projectMembersLoaded: state.projects.loadedProjectMembers,
    };
}

export default EditProject;
