export default {
    display_name: {
        ERR_VALIDATE_MIN_LENGTH:
            'Please provide an application name (at least 10 characters in length).',
    },
    description: {
        ERR_VALIDATE_NOT_BLANK: 'Please provide a short description for your application.',
    },
    project: {
        ERR_VALIDATE_IS_REQUIRED:
            'Please select a project you would like your application to be in.',
    },
};
