import React from 'react';
import { useFormContext } from 'react-hook-form';

const HiddenField = (props) => {
    const { name, value } = props;

    const { register } = useFormContext();
    const id = name;

    return <input id={id} name={name} value={value} ref={register()} type="hidden" />;
};

export default HiddenField;
