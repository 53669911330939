import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router';

import DocumentTitle from 'common/components/DocumentTitle';
import AppList from 'apps/components/AppList';
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder';

import { fetchMyProjects } from 'projects/services/projectService';
import { fetchProducts } from 'products/services/productsService';
import getPersonalProjectSlug from 'projects/helpers/getPersonalProjectSlug';
import ContentBlock from 'common/components/ContentBlock';
import { fetchProjectMembers } from 'projects/actions';
import { fetchAppsForProject } from 'apps/actions';
import { fetchKeysForProject } from 'keys/actions';

export function determineLoadingProps(state) {
    const loadingContent =
        state.apps.loadingApps ||
        state.projects.loadingProjects ||
        state.products.loadingProducts ||
        false;

    const loadedContent =
        (state.apps.loadedApps && state.projects.loadedProjects && state.products.loadedProducts) ||
        false;

    return {
        loadingContent,
        loadedContent,
    };
}

const ViewPersonalProject = () => {
    const dispatch = useDispatch();

    const store = { dispatch };

    useEffect(() => {
        fetchMyProjects(store);
    }, []);

    const projectSlug = useSelector((state) => {
        return getPersonalProjectSlug(state.session.session, state.projects.projects);
    });

    const project = useSelector((state) => {
        return state.projects.projects[projectSlug] || {};
    });

    useEffect(() => {
        if (project.id) {
            fetchProducts(store);
            fetchProjectMembers(project)(dispatch);
            fetchAppsForProject(project.id)(dispatch);
            fetchKeysForProject(project.id)(dispatch);
        }
    }, [project]);

    const apps = useSelector((state) => {
        if (project) {
            return Object.values(state.apps.apps).filter((app) => app.project == project.id);
        }
    });

    const products = useSelector((state) => {
        return Object.values(state.products.products);
    });

    const keys = useSelector((state) => {
        return Object.values(state.keys.keys);
    });

    const { loadedContent, loadingContent } = useSelector((state) => {
        return {
            ...determineLoadingProps(state),
        };
    });

    return (
        <DocumentTitle title="My Apps">
            <React.Fragment>
                <ContentBlock>
                    <LoadingPlaceholder waitFor={project.id} placeholder="Loading project...">
                        <div className="gs-u-align-right">
                            {apps.length > 0 && (
                                <Link
                                    to={`/apps/new/${project.id}`}
                                    className="dp-o-button--secondary dp-e-add-apps gs-u-ml"
                                >
                                    Create app
                                </Link>
                            )}
                        </div>
                    </LoadingPlaceholder>

                    <h1 className="dp-h1 project-view__field display_name">My Applications</h1>

                    <LoadingPlaceholder waitFor={project.id} placeholder="Loading project...">
                        <div className="project-view__field description">
                            <p className="gel-pica">
                                Below you will find a list of your applications. An app is anything
                                which consumes a BBC API. This may be a phone app, a desktop app, a
                                website or another API.
                            </p>
                        </div>

                        <div className="project-view__field warning">
                            <p className="gel-pica">
                                If you are unexpectedly missing an app, please contact the API
                                Management team via email -{' '}
                                <a href="mailto:accessfoundations@bbc.co.uk">accessfoundations@bbc.co.uk</a>
                            </p>
                        </div>
                    </LoadingPlaceholder>
                </ContentBlock>
                <ContentBlock>
                    <LoadingPlaceholder waitFor={project.id} placeholder="Loading project...">
                        {apps.length > 0 && <AppList apps={apps} keys={keys} products={products} />}

                        {loadedContent && apps.length === 0 && (
                            <div>
                                <p className="no-apps gel-pica">
                                    You do not have any apps. You will need to create one before you
                                    can use any BBC APIs or services.
                                </p>

                                <Link
                                    to={`/apps/new/${project.id}`}
                                    className="dp-o-button--cta gs-u-mt+ dp-e-add-apps"
                                >
                                    Create app
                                </Link>
                            </div>
                        )}

                        {loadingContent && (
                            <div>
                                <p>Loading your apps...</p>
                            </div>
                        )}
                    </LoadingPlaceholder>
                </ContentBlock>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ViewPersonalProject;
