import React from 'react';
import PropTypes from 'prop-types';
import { clearMessages } from 'flashMessages/state';

/**
 * Display Messages
 *
 * Display messages component grabs the latest messages, returned by the action
 * that is intercepted by the messages middleware, and presents on screen.
 * The full message array is then cleared by dispatching the clearMessages action.
 *
 * The state isn't subscribed to using @connect. This allows us to display
 * the messages to the user, and clear them out for the next transition, without
 * clearing the messages whilst still being viewed.
 */

const DisplayMessage = ({ id, dispatch, messages }) => {
    setTimeout(() => dispatch(clearMessages())); // Clear during next tick.

    return (
        <div className="gel-wrap">
            {Boolean(messages) && messages.length > 0 && (
                <table id={id}>
                    <tbody>
                        {messages.map((message, index) => (
                            <React.Fragment key={message.id}>
                                <tr>
                                    <td className="gs-u-p-">
                                        {index === 0 && (
                                            <i className="dp-o-icon dp-o-icon--info"></i>
                                        )}
                                    </td>
                                    <td className="dp-c-flashbox__message gs-u-p-">
                                        {message.message}
                                    </td>
                                </tr>

                                {message.context && (
                                    <tr>
                                        <td></td>
                                        <td className="dp-c-flashbox__context-message gs-u-p-">
                                            {message.context}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default DisplayMessage;

DisplayMessage.propTypes = {
    dispatch: PropTypes.func.isRequired,
    messages: PropTypes.array,
};
