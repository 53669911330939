import * as projectService from 'projects/services/projectService';
import { redirect } from 'common/state/redirect';
import { addMessage, flushAllMessages } from 'flashMessages/state';
import { reset } from 'redux-form';

import { removeProject } from 'projects/state';

function dispatchError(dispatch, error) {
    dispatch(flushAllMessages());

    const message =
        'ERROR: ' +
        ({
            ERR_PROJECT_UPDATE_CONFLICT: 'project name is already taken.',
            ERR_PROJECT_CONFLICT: 'project name is already taken.',
            ERR_INPUT_VALIDATION: 'please enter a valid email address',
            ERR_USER_IS_MEMBER: 'user is already a member of this project',
            ERR_USER_NOT_FOUND_FOR_EMAIL:
                'user cannot be added as they do not already have a Developer Portal account',
        }[error.message] || 'an unspecified error has occurred');

    dispatch(addMessage(message, error));
}

export function createProject(dispatch, data, replaceState, callback) {
    const redirectUrl = data.redirectUrl;
    const projectData = Object.assign({}, data);
    delete projectData.redirectUrl;

    return projectService
        .createProject({ dispatch }, projectData)
        .then((result) => {
            const defaultLocation = { pathname: `/projects/${result.slug}` };
            const location = redirectUrl || defaultLocation;
            dispatch(redirect(location));
        })
        .catch((error) => {
            return dispatchError(dispatch, error);
        })
        .then(() => callback());
}

export function updateProject(dispatch, rawData, replaceState, callback) {
    const { originalSlug, ...data } = rawData;

    return projectService
        .updateProject({ dispatch }, data)
        .then((result) => {
            dispatch(
                redirect({
                    pathname: `/projects/${result.slug}`,
                }),
            );

            if (originalSlug !== result.slug) {
                dispatch(removeProject(originalSlug));
            }
        })
        .catch((error) => {
            return dispatchError(dispatch, error);
        })
        .then(() => callback());
}

export async function addMember(dispatch, data, replaceState, callback) {
    try {
        await projectService.addMember({ dispatch }, data);
        dispatch(reset('add-member-form'));
        dispatch(flushAllMessages());
        dispatch(addMessage('Member has been added to project.'));
    } catch (error) {
        dispatchError(dispatch, error);
    }
    callback();
}

export async function removeMember(dispatch, data, replaceState, callback) {
    let redirectPath = `/projects/${data.projectSlug}`;

    let message = 'Member has been removed from project.';
    if (data.userId === data.currentUserId) {
        message = 'You have been removed from the project.';
        redirectPath = '/projects';
    }

    try {
        dispatch(
            redirect({
                pathname: redirectPath,
            }),
        );

        await projectService.removeMember({ dispatch }, data);

        dispatch(addMessage(message));
    } catch (error) {
        dispatchError(dispatch, error);
    }
    callback();
}

export function onFailedAddMemberValidation(dispatch, data, errors) {
    const action = addMessage(
        'Something went wrong when attempting to add this user. Please review below.',
        {
            data,
            errors,
        },
    );
    dispatch(action);
}
