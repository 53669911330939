import React from 'react';
import { useForm, FormContext } from 'react-hook-form';

import slugify from 'common/helpers/slugify';
import { validateOnCreate } from 'products/handlers/validation/resolvers';

import { CheckboxGroup, Input, Textarea, Select } from 'forms';
import QuotaDescription from 'products/components/QuotaDescription';

import { productToFormData } from '../services/productTransformers';

const EnvironmentsOptions = [
    {
        value: 'int',
        label: 'Int',
    },
    {
        value: 'test',
        label: 'Test',
    },
    {
        value: 'stage',
        label: 'Stage',
    },
    {
        value: 'prod',
        label: 'Live',
    },
];

const HTTPVerbOptions = [
    {
        value: 'GET',
        label: 'GET',
    },
    {
        value: 'POST',
        label: 'POST',
    },
    {
        value: 'PUT',
        label: 'PUT',
    },
    {
        value: 'DELETE',
        label: 'DELETE',
    },
    {
        value: 'TRACE',
        label: 'TRACE',
    },
    {
        value: 'CONNECT',
        label: 'CONNECT',
    },
    {
        value: 'PATCH',
        label: 'PATCH',
    },
];

const AuthorisationOptions = [
    {
        label: 'Manual',
        value: 'manual',
    },
    {
        label: 'Automatic',
        value: 'automatic',
    },
];

const QuotaTimeUnitOptions = [
    {
        label: 'Minute',
        value: 'minute',
    },
    {
        label: 'Hour',
        value: 'hour',
    },
    {
        label: 'Day',
        value: 'day',
    },
    {
        label: 'Month',
        value: 'month',
    },
];

const ProductForm = ({ projectId, product, onSubmit }) => {
    const defaultValues = product ? productToFormData(product) : { quota_interval: 1 };

    const methods = useForm({
        validationResolver: validateOnCreate,
        defaultValues: defaultValues,
    });

    const watchedFields = methods.watch(['quota', 'quota_interval', 'quota_time_unit']);

    const { getValues, setValue } = methods;

    let previousDisplayName;

    const onDisplayNameChange = (e) => {
        const newDisplayName = e.target.value;
        const newSlug = slugify(newDisplayName);
        const currentSlug = getValues('product_identifier') || '';
        if (currentSlug === '' || currentSlug === slugify(previousDisplayName)) {
            setValue('product_identifier', newSlug);
        }
        previousDisplayName = newDisplayName;
    };

    return (
        <FormContext {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Input
                    name="display_name"
                    description="A descriptive name for your product."
                    label="Product Name*"
                    onChange={onDisplayNameChange}
                    placeholder="Product Name (required)"
                />

                <Input
                    name="product_identifier"
                    label="Product Identifier*"
                    placeholder="product-name (required)"
                    description={
                        <span>
                            Value of the Product Identifier field will be entered in the
                            api-management proxy&nbsp;
                            <a
                                href="https://github.com/bbc/api-management/wiki/Required-Cosmos-Config#apiman-allowed-api-products"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                apiman-allowed-api-products
                            </a>
                            &nbsp;config option.
                        </span>
                    }
                />

                <Textarea
                    name="description"
                    description="Briefly explain the purpose of your product."
                    label="Product Description*"
                    placeholder="Product Description (required)"
                />

                <CheckboxGroup
                    name="environments"
                    label="Environments*"
                    description="Deployment environments this product is valid for."
                    options={EnvironmentsOptions}
                />

                <Select
                    name="authorisation"
                    label="Approval Type*"
                    options={AuthorisationOptions}
                    description={
                        <span>
                            <strong>Manual</strong> - keys require approval before they can be used.
                            <br />
                            <strong>Automatic</strong> - keys are automatically approved when
                            requested and can be used immediately.
                        </span>
                    }
                />

                <Textarea
                    name="email"
                    label="Notification Email(s)"
                    description={
                        <span>
                            <strong>Required when approval type is manual.</strong>
                            <br />A list of email addresses that will be notified when an API key is
                            requested. Separated by a new line.
                        </span>
                    }
                    placeholder="product.owner@bbc.co.uk&#10;developer@bbc.co.uk"
                />

                <h2 className="dp-h2 gs-u-mb+">Request quotas</h2>
                <Input
                    name="quota"
                    label="Request Count"
                    description="How many requests your clients are allowed in one period (eg, 1000)."
                    placeholder="Request Count"
                />

                <Select
                    name="quota_time_unit"
                    label="Time Unit"
                    options={QuotaTimeUnitOptions}
                    description="What unit the period is measured in."
                />

                <Input
                    name="quota_interval"
                    label="Unit Intervals"
                    placeholder="Unit Intervals"
                    description="How many of that unit make up one period. Usually 1."
                />

                <div className="gel-layout">
                    <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                        <QuotaDescription
                            className="gs-u-mb+"
                            requestCount={watchedFields.quota}
                            timeUnit={watchedFields.quota_time_unit}
                            timePeriods={watchedFields.quota_interval}
                        />
                    </div>
                </div>

                <h2 className="dp-h2 gs-u-mb+">Resource whitelists</h2>

                <CheckboxGroup
                    name="http_verbs"
                    label="Allowed HTTP Verbs"
                    description="HTTP verbs the requesting clients are restricted to. Not selecting any will
                    allow clients access to all HTTP verbs."
                    options={HTTPVerbOptions}
                />

                <Textarea
                    name="resource_paths"
                    label="Allowed Resource Paths"
                    placeholder="/resources&#10;/resources/**&#10;/resources/**/subresources"
                    description="A list of resource paths separated by a new line. Leaving this field empty will
                    allow clients access to all resource paths."
                />

                <input
                    type="hidden"
                    data-testid="project"
                    name="project"
                    value={projectId}
                    ref={methods.register}
                />

                {product ? (
                    <input
                        type="hidden"
                        data-testid="productUUID"
                        name="productUUID"
                        ref={methods.register}
                    />
                ) : null}

                <div className="gel-layout">
                    <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                        <button type="submit" className="dp-o-button--primary dp-o-button--full">
                            {product ? 'Update product' : 'Register product'}
                        </button>
                    </div>
                </div>
            </form>
        </FormContext>
    );
};

export default ProductForm;
