import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import DocumentTitle from 'common/components/DocumentTitle';

import { fetchProducts } from 'products/services/productsService';
import AppList from 'apps/components/AppList';
import { Link } from 'react-router';
import MemberList from 'projects/components/MemberList';
import ProductList from 'projects/components/ProductList';
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder';
import AddMemberForm from '../components/AddMemberForm';
import { enableForm } from 'forms/helpers/enableForm';
import { addMemberToProjectRules } from 'projects/validation/rules';
import { addMember, onFailedAddMemberValidation } from 'projects/handlers/projectsFormHandler';
import messages from 'projects/validation/messages';
import isFeatureActive from 'common/helpers/features';
import ErrorAccessDenied from 'errors/components/ErrorAccessDenied';
import ContentBlock from 'common/components/ContentBlock';
import { fetchProjectBySlug, fetchProjectMembers } from 'projects/actions';
import { fetchAppsForProject } from 'apps/actions';
import { fetchKeysForProject } from 'keys/actions';

export function determineLoadingProps(state, myProjectSlug) {
    //TODO: check additional entities like keys ?
    const loadingContent =
        state.apps.loadingApps ||
        state.projects.loading[myProjectSlug] ||
        state.products.loadingProducts ||
        false;

    const loadedContent =
        (state.apps.loadedApps &&
            state.projects.loaded[myProjectSlug] &&
            state.products.loadedProducts) ||
        false;

    return {
        loadingContent,
        loadedContent,
    };
}

const formOptions = {
    formId: 'add-member-form',
    rules: addMemberToProjectRules,
    messages,
    onValidated: addMember,
    onFailedValidation: onFailedAddMemberValidation,
};

const FormEnabledAddMemberForm = enableForm(formOptions)(AddMemberForm);

const ViewProject = ({ params }) => {
    const projectSlug = params.slug;
    const dispatch = useDispatch();
    const store = { dispatch };

    useEffect(() => {
        fetchProjectBySlug(projectSlug)(dispatch);
        fetchProducts(store);
    }, []);

    const project = useSelector((state) => {
        return state.projects.projects[projectSlug] || {};
    });

    useEffect(() => {
        if (project.id) {
            fetchProjectMembers(project)(dispatch);
            fetchAppsForProject(project.id)(dispatch);
            fetchKeysForProject(project.id)(dispatch);
        }
    }, [project]);

    const apps = useSelector((state) => {
        if (project) {
            return values(state.apps.apps).filter((app) => app.project == project.id);
        }
    });

    const products = useSelector((state) => {
        return values(state.products.products);
    });

    const keys = useSelector((state) => {
        return values(state.keys.keys);
    });

    const projectMembers = useSelector((state) => {
        if (project) {
            return state.projects.members[project.slug];
        }
    });
    const projectProducts = useSelector((state) => {
        if (project) {
            return values(state.products.products).filter(
                (product) => product.project == project.id,
            );
        }
    });

    const { loadedContent, loadingContent } = useSelector((state) => {
        return {
            ...determineLoadingProps(state, projectSlug),
        };
    });

    //TODO: Remove this once proper error boundaries are in place
    if (loadedContent && !projectMembers) return <ErrorAccessDenied />;

    return (
        <DocumentTitle title={project.display_name || 'Loading ...'}>
            <React.Fragment>
                <ContentBlock>
                    <LoadingPlaceholder waitFor={project.id}>
                        <div className="gs-u-align-right">
                            <Link
                                to={`/projects/${projectSlug}/edit`}
                                className="dp-o-button--secondary gs-u-ml"
                                title="Edit project details"
                            >
                                Edit
                            </Link>
                        </div>
                    </LoadingPlaceholder>

                    <h1 className="dp-h1 project-view__field display_name">
                        <LoadingPlaceholder waitFor={project.id}>
                            {project.display_name}
                        </LoadingPlaceholder>
                    </h1>

                    <div className="project-view__field description">
                        <p className="gel-pica">
                            Below you will find a list of your applications. An app is anything
                            which consumes a BBC API. This may be a phone app, a desktop app, a
                            website or another API.
                        </p>
                    </div>

                    <div className="project-view__field warning">
                        <p className="gel-pica">
                            If you are unexpectedly missing an app, please contact the API
                            Management team via email -{' '}
                            <a href="mailto:accessfoundations@bbc.co.uk">accessfoundations@bbc.co.uk</a>
                        </p>
                    </div>
                </ContentBlock>

                <hr className="dp-hr" />

                <ContentBlock>
                    <LoadingPlaceholder waitFor={project.id} placeholder="Loading project...">
                        <LoadingPlaceholder
                            waitFor={projectMembers}
                            placeholder="Loading project members..."
                        >
                            <LoadingPlaceholder
                                waitFor={products}
                                placeholder="Loading products..."
                            >
                                {apps.length > 0 && (
                                    <Link
                                        to={`/apps/new/${project.id}`}
                                        className="gs-u-float-right dp-o-button--secondary"
                                        title="Create new application"
                                    >
                                        Create app
                                    </Link>
                                )}

                                <h2 className="dp-h2 gs-u-mt">Applications</h2>

                                {apps.length > 0 && (
                                    <AppList apps={apps} keys={keys} products={products} />
                                )}

                                {loadedContent && apps.length === 0 && (
                                    <div>
                                        <p className="no-apps gel-pica">
                                            You do not have any apps. You will need to create one
                                            before you can use any BBC APIs or services.
                                        </p>

                                        <Link
                                            to={`/apps/new/${project.id}`}
                                            className="dp-o-button--cta gs-u-mt+ dp-e-add-apps"
                                        >
                                            Create app
                                        </Link>
                                    </div>
                                )}

                                {loadingContent && (
                                    <div>
                                        <p>Loading your apps...</p>
                                    </div>
                                )}
                            </LoadingPlaceholder>
                        </LoadingPlaceholder>
                    </LoadingPlaceholder>
                </ContentBlock>

                <hr className="dp-hr" />

                <ContentBlock>
                    <LoadingPlaceholder waitFor={project.id} placeholder="">
                        <LoadingPlaceholder waitFor={projectMembers} placeholder="">
                            <LoadingPlaceholder waitFor={products} placeholder="">
                                <h2 className="dp-h2 gs-u-mt">Members</h2>
                                <LoadingPlaceholder
                                    waitFor={project}
                                    placeholder="Loading project details..."
                                >
                                    {projectMembers && projectMembers.length > 0 && (
                                        <React.Fragment>
                                            <MemberList
                                                members={projectMembers}
                                                projectSlug={projectSlug}
                                            />
                                        </React.Fragment>
                                    )}

                                    <div className="gs-u-mt++">
                                        <FormEnabledAddMemberForm
                                            initialValues={{
                                                projectId: project.id,
                                                projectSlug,
                                            }}
                                        />
                                    </div>
                                </LoadingPlaceholder>
                            </LoadingPlaceholder>
                        </LoadingPlaceholder>
                    </LoadingPlaceholder>
                </ContentBlock>

                {isFeatureActive('products') && (
                    <React.Fragment>
                        <hr className="dp-hr" />

                        <ContentBlock>
                            <LoadingPlaceholder waitFor={project.id} placeholder="">
                                <LoadingPlaceholder waitFor={projectMembers} placeholder="">
                                    <LoadingPlaceholder waitFor={products} placeholder="">
                                        <React.Fragment>
                                            {isFeatureActive('products') &&
                                                projectProducts.length > 0 && (
                                                    <Link
                                                        to={`/products/create/${project.id}`}
                                                        className="gs-u-float-right dp-o-button--secondary dp-e-register-a-product gs-u-ml"
                                                    >
                                                        Register an API product
                                                    </Link>
                                                )}

                                            <h2 className="dp-h2 gs-u-mt">API Products</h2>

                                            {projectProducts.length > 0 ? (
                                                <ProductList products={projectProducts} />
                                            ) : (
                                                <React.Fragment>
                                                    <p className="no-products">
                                                        This project does not have any API products.
                                                    </p>
                                                    <Link
                                                        to={`/products/create/${project.id}`}
                                                        className="dp-o-button--cta gs-u-mt+ dp-e-add-apps"
                                                    >
                                                        Register an API product
                                                    </Link>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    </LoadingPlaceholder>
                                </LoadingPlaceholder>
                            </LoadingPlaceholder>
                        </ContentBlock>
                    </React.Fragment>
                )}
            </React.Fragment>
        </DocumentTitle>
    );
};

ViewProject.propTypes = {
    apps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        }),
    ),
    keys: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        }),
    ),
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
        }),
    ),
    displayName: PropTypes.string,
};

const values = (object) => Object.keys(object).map((key) => object[key]);

export default ViewProject;
