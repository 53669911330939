import UiApiClient from 'common/helpers/uiApiClient';

export async function fetchAppAttributes(appId) {
    const response = await new UiApiClient().get(`/app_attributes?app_id=${appId}`);
    return response.entities ? response.entities.map((entity) => entity.properties) : [];
}

export async function updateAppAttribute(data = {}) {
    const updatedAttribute = await new UiApiClient().put(`app_attributes/${data.id}`, { data });

    if (!updatedAttribute.properties) {
        throw new Error('Missing attribute properties');
    }

    return updatedAttribute.properties;
}

export async function addAppAttribute(data) {
    const addedAttribute = await new UiApiClient().post(`app_attributes`, { data });

    if (!addedAttribute.properties) {
        throw new Error('Missing attribute properties');
    }

    return addedAttribute.properties;
}

export function deleteAppAttribute(appAttributeId) {
    const path = `app_attributes/${appAttributeId}`;
    return new UiApiClient().del(path);
}
