import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import timeAgo from 'time-ago';

const KeyList = ({ app, keys, products }) => {
    const tableRows = keys.map((key) => renderKeyRow(key, products));

    return (
        <div>
            <div>
                {keys.length > 0 && (
                    <Link
                        to={`/keys/new?app_id=${app.id}`}
                        className="gs-u-float-right dp-o-button--secondary dp-e-add-key"
                        title={`Request new API key for ${app.displayName}`}
                    >
                        Request API key
                    </Link>
                )}
                <h2 className="dp-h2 gs-u-mt0">API Keys</h2>
            </div>

            {keys.length > 0 && (
                <table className="gs-o-list-ui dp-c-key-list dp-table gs-u-mb0">
                    <thead>
                        <tr className="dp-table__header">
                            <th className="dp-table__cell">API Key</th>
                            <th className="dp-table__cell">API Product</th>
                            <th className="dp-table__cell">Issued</th>
                            <th className="dp-table__cell">Status</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            )}

            {keys.length == 0 && (
                <div className="no-keys">
                    <p className="gel-pica">This is where your API Keys will appear.</p>
                    <p className="gel-pica">
                        In order for {app.displayName} to access any BBC APIs, it will need an API
                        key.
                    </p>
                    <Link
                        to={`/keys/new?app_id=${app.id}`}
                        className="dp-o-button--cta gs-u-mt+ dp-e-add-key"
                    >
                        Request API key
                    </Link>
                </div>
            )}
        </div>
    );
};

function renderKeyRow(key, products) {
    const product = getById(products, key.apiProduct);

    return (
        <tr id={'key-' + key.id} key={'key-' + key.id} className="dp-table__row gs-o-list-ui__item">
            <td className="dp-table__cell api_key">
                <Link to={`/keys/${key.id}`}>View</Link>
            </td>
            <td className="dp-table__cell product">
                {product ? product.displayName : 'No Product Detected'}
            </td>
            <td className="dp-table__cell issued">{timeAgo.ago(key.issued)}</td>
            <td className={`dp-table__cell status dp-c-key__${key.status}`}>
                {capitalise(key.status)}
            </td>
        </tr>
    );
}

function getById(list, id) {
    for (let item of list) if (item.id == id) return item;
}

function capitalise(txt) {
    return txt[0].toUpperCase() + txt.substr(1);
}

export default KeyList;

KeyList.propTypes = {
    app: PropTypes.object,
    keys: PropTypes.array,
    products: PropTypes.array,
};
