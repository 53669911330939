import React, { useEffect } from 'react';
import DocumentTitle from 'common/components/DocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router';
import LoadingPlaceholder from '../../common/components/LoadingPlaceholder';
import { fetchProduct } from 'products/services/productsService';
import { fetchProject } from 'projects/actions';
import { fetchKeysForProductWithApps } from 'keys/services/keysService';
import sortKeysByStatusAndDisplayName from './lib/sortKeysByStatusAndDisplayName';
import KeyAndAppsList from '../components/KeysAndAppsList';

const formatAttribute = (attribute, type) => {
    const noValue = <span className="dp-color--text-muted">Not set</span>;

    const filterFalseyValues = (value) => value;

    switch (type) {
        case 'array':
            return attribute === null ||
                attribute === undefined ||
                (Array.isArray(attribute) && attribute.filter(filterFalseyValues).length === 0)
                ? noValue
                : attribute.join(', ');
        case 'boolean':
            return attribute === null || attribute === undefined ? 'false' : attribute.toString();
        default:
            return attribute === null || attribute === undefined ? noValue : attribute.toString();
    }
};

const ContentBlock = ({ children }) => {
    return (
        <div className="dp-main">
            <div className="gel-layout">
                <div className="gel-layout__item gel-1/1 gel-8/12@l">{children}</div>
            </div>
        </div>
    );
};

const ViewProduct = ({ params }) => {
    const productId = params.productId;
    const dispatch = useDispatch();

    const product = useSelector((state) => state.products.products[productId] || {});

    const project = useSelector((state) => {
        let project;

        if (product.project) {
            project = Object.values(state.projects.projects).find(
                (project) => project.id === product.project,
            );
        }

        return project || {};
    });

    const projectCount = useSelector((state) => {
        const projects = state.projects.projects || [];
        return Object.values(projects).filter((project) => !project.owner).length;
    });

    const { keyList, keysLoaded } = useSelector((state) => {
        const keys = state.keys.keys || {};
        const productKeys = Object.values(keys).filter((key) => key.apiProduct === productId);

        let keyList = productKeys.map((key) => {
            const keyWithApp = Object.assign({}, key);
            keyWithApp.app = state.apps.apps[key.app];
            return keyWithApp;
        });

        keyList = sortKeysByStatusAndDisplayName(keyList);

        return {
            keyList,
            keysLoaded: state.keys.loadedCollection,
        };
    });

    useEffect(() => {
        fetchProduct({ dispatch }, productId);
    }, []);

    useEffect(() => {
        if (product.id && product.project) {
            dispatch(fetchProject(product.project));
            fetchKeysForProductWithApps({ dispatch }, product.id);
        }
    }, [product]);

    return (
        <DocumentTitle title={product.displayName || 'Product'}>
            <React.Fragment>
                <ContentBlock>
                    <LoadingPlaceholder waitFor={product.id} placeholder="">
                        <div className="gs-u-align-right">
                            <Link
                                to={`/products/${product.id}/move`}
                                className="dp-o-button--secondary dp-e-edit-app gs-u-ml"
                                title="Move project"
                                disabled={projectCount < 2}
                            >
                                Move project
                            </Link>
                            <Link
                                to={`/products/${product.id}/edit`}
                                className="dp-o-button--secondary dp-e-edit-app gs-u-ml"
                            >
                                Edit
                            </Link>
                        </div>

                        <h1 className="dp-h1 product-view__field display_name">
                            {product.id && product.displayName}
                        </h1>

                        <p>{product.description}</p>

                        <p>
                            This product is in the&nbsp;
                            <Link to={'/projects/' + project.slug}>{project.display_name}</Link>
                            &nbsp;project.
                        </p>
                    </LoadingPlaceholder>
                </ContentBlock>

                <ContentBlock>
                    <LoadingPlaceholder waitFor={product.id} placeholder="Loading product ...">
                        <div className="gel-3/4@xs">
                            <h3>Deployment Details</h3>

                            <table className="gs-o-list-ui dp-table dp-table__two_column_30/70">
                                <tbody>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Identifier</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.slug)}
                                        </td>
                                    </tr>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Environments</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.environments, 'array')}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3>Request Approval</h3>

                            <table className="gs-o-list-ui dp-table dp-table__two_column_30/70">
                                <tbody>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Approval Type</td>
                                        <td className="dp-table__cell">
                                            {product.autoApprove ? 'Automatic' : 'Manual'}
                                        </td>
                                    </tr>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Notification Email(s)</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.defaultNotifyEmails, 'array')}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3>Request Quotas</h3>

                            <table className="gs-o-list-ui dp-table dp-table__two_column_30/70">
                                <tbody>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Request Count</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.quota)}
                                        </td>
                                    </tr>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Time Unit</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.quotaTimeUnit)}
                                        </td>
                                    </tr>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Unit Intervals</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.quotaInterval)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3>Resource Whitelists</h3>

                            <table className="gs-o-list-ui dp-table dp-table__two_column_30/70">
                                <tbody>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Allowed HTTP Verbs</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.httpVerbs, 'array')}
                                        </td>
                                    </tr>
                                    <tr className="dp-table__row gs-o-list-ui__item">
                                        <td className="dp-table__cell">Allowed Resource paths</td>
                                        <td className="dp-table__cell">
                                            {formatAttribute(product.resourcePaths, 'array')}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </LoadingPlaceholder>
                </ContentBlock>

                <hr className="dp-hr" />

                <ContentBlock>
                    <h2 className="dp-h2 gs-u-mt">Keys for this product</h2>
                    <LoadingPlaceholder waitFor={keysLoaded} placeholder="Loading keys...">
                        {keysLoaded && keyList.length === 0 ? (
                            <p>There are no keys associated with this product.</p>
                        ) : (
                            <KeyAndAppsList keyList={keyList} />
                        )}
                    </LoadingPlaceholder>
                </ContentBlock>
            </React.Fragment>
        </DocumentTitle>
    );
};

export default ViewProduct;
