import React from 'react';

const HomeHelp = () => (
    <p id="HomeHelp">
        If you have any questions or need any support then you can contact us at{' '}
        <a href="mailto:accessfoundations@bbc.co.uk">accessfoundations@bbc.co.uk</a> or in the <strong>#access-foundations-help</strong>{' '}
        Slack channel.
    </p>
);

export default HomeHelp;
