export default (projects, projectsMine) => {
    const mineProjects = Object.keys(projects)
        .map((key) => {
            return projects[key];
        })
        .filter((project) => {
            return projectsMine[project.slug] !== undefined;
        });

    return mineProjects;
};
