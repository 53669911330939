import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

export const TextareaField = (passedProps) => {
    const { input, label, required = false, describedBy = false, meta, placeholder } = passedProps;
    const { touched, error } = meta;

    const describedByProp = (describedBy ? describedBy + ' ' : '') + input.name + '-field-alert';
    const fieldInvalid = error ? true : false;

    return (
        <div className={'gel-form__field' + (touched && error ? ' is-invalid' : '')}>
            <label htmlFor={input.name} className="gel-form__label">
                {label}
            </label>
            <div>
                <textarea
                    {...input}
                    id={input.name}
                    placeholder={placeholder || label}
                    className="gel-form__input"
                    aria-required={required}
                    aria-describedby={describedByProp}
                    aria-invalid={fieldInvalid}
                >
                    {input.value}
                </textarea>
                <div className="gel-form-field__errors">
                    {touched && error && (
                        <span
                            role="alert"
                            id={input.name + '-field-alert'}
                            className="gel-form-field__error"
                        >
                            {error}
                        </span>
                    )}
                    <span>&nbsp;</span>
                </div>
            </div>
        </div>
    );
};

const WrappedTextareaField = (props) => <Field component={TextareaField} {...props} />;

export default WrappedTextareaField;

TextareaField.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    meta: PropTypes.object,
};
