import React from 'react';
import { Field, change } from 'redux-form';

let nextId = 1;

export class RadioField extends React.Component {
    constructor(props) {
        super(props);
        this.id = nextId++;
        this.searchBoxId = 'radio-search_' + this.id;
        this.state = {
            selected: null,
            filter: '',
        };
    }

    componentDidMount() {
        window.addEventListener('DOMContentLoaded', () =>
            document.getElementById(this.searchBoxId).focus(),
        );
    }

    render() {
        const { label, input = {}, meta = {}, options = [], selected } = this.props,
            { touched, error } = meta,
            { filter } = this.state,
            filteredOptions = options.filter((option) => {
                if (!filter) return true;
                const terms = filter
                        .toLowerCase()
                        .split(' ')
                        .filter((x) => x),
                    haystack = option.label.toLowerCase();
                const ok = terms.every((term) => haystack.indexOf(term) >= 0);
                if (!ok && this.state.selected == option.value) setTimeout(() => this.select(null));
                return ok;
            });

        return (
            <div
                className={'gel-form__field' + (touched && error ? ' is-invalid' : '')}
                role="radiogroup"
                aria-invalid={!!error}
            >
                <div className="gel-form__label">{label}</div>

                <input
                    type="text"
                    aria-label="Filter product list"
                    className="gel-form__input gel-form__search dp-o-radio--search"
                    id={this.searchBoxId}
                    onChange={() =>
                        this.editState({
                            filter: document.getElementById(this.searchBoxId).value,
                        })
                    }
                />

                <div className="gel-form-field__errors">
                    {touched && error && (
                        <span
                            role="alert"
                            id={input.name + '-field-alert'}
                            className="gel-form-field__error"
                        >
                            {error}
                        </span>
                    )}
                </div>

                {options.length > 0
                    ? filteredOptions.length > 0
                        ? filteredOptions.map((option) => (
                              <div key={option.value} className="dp-o-radio gs-u-m">
                                  <input
                                      id={option.value}
                                      className="gs-u-mr"
                                      role="radio"
                                      type="radio"
                                      name={input.name}
                                      value={option.value}
                                      onClick={() => this.select(option.value)}
                                      disabled={option.value === selected}
                                      defaultChecked={option.value === selected}
                                  />
                                  <label htmlFor={option.value}>{option.label}</label>
                              </div>
                          ))
                        : 'No options available'
                    : 'Loading...'}
            </div>
        );
    }

    select(value) {
        const { input, meta } = this.props,
            { dispatch, form } = meta;
        dispatch(change(form, input.name, value));
        this.editState({ selected: value });
    }

    editState(newState) {
        this.setState((prevState) => Object.assign({}, prevState, newState));
    }
}

const WrappedRadioField = (props) => <Field component={RadioField} {...props} />;

export default WrappedRadioField;
