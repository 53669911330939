import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

export const SelectField = (passedProps) => {
    const {
        label,
        required = false,
        describedBy = false,
        input = {},
        meta = {},
        options = [],
        defaultLabel,
        firstOptionLabel = false,
        ...props
    } = passedProps;
    const { touched, error } = meta;

    const describedByProp = (describedBy ? describedBy + ' ' : '') + input.name + '-field-alert';
    const fieldInvalid = error ? true : false;

    const optionTags = renderOptions(options);
    const firstOptionText = firstOptionLabel ? firstOptionLabel : 'Select an option';

    return (
        <div className={'gel-form__field' + (touched && error ? ' is-invalid' : '')}>
            <label htmlFor={input.name} className="gel-form__label">
                {label}
            </label>
            <div>
                <select
                    {...input}
                    id={input.name}
                    className="gel-form__input"
                    {...props}
                    aria-required={required}
                    aria-describedby={describedByProp}
                    aria-invalid={fieldInvalid}
                >
                    {options && options.length > 0 && <option value="">{firstOptionText}</option>}

                    {!options ||
                        (options.length === 0 && (
                            <option value="">{defaultLabel || 'No options available'}</option>
                        ))}

                    {optionTags}
                </select>

                <div className="gel-form-field__errors">
                    {touched && error && (
                        <span
                            role="alert"
                            id={input.name + '-field-alert'}
                            className="gel-form-field__error"
                        >
                            {error}
                        </span>
                    )}
                    <span>&nbsp;</span>
                </div>
            </div>
        </div>
    );
};

function renderOptions(options = []) {
    return options.map((option, index) => {
        return (
            <option key={index} value={option.value}>
                {option.label}
            </option>
        );
    });
}

const WrappedSelectField = (props) => <Field component={SelectField} {...props} />;

export default WrappedSelectField;

SelectField.propTypes = {
    select: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string.isRequired,
    meta: PropTypes.object,
    options: PropTypes.array,
};
