import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import filterMineProjects from 'projects/helpers/filterMineProjects';

function shouldRender(projects, projectsMine) {
    const filteredMineProjects = filterMineProjects(projects, projectsMine);

    return filteredMineProjects.length > 1;
}

const MoveProjectButton = ({ appId, isPersonalProject, projects, projectsMine }) => {
    if (!shouldRender(projects, projectsMine)) {
        return null;
    }

    return (
        <Link
            to={`/apps/${appId}/move`}
            className="dp-o-button--secondary dp-e-move-project gs-u-ml"
        >
            {isPersonalProject ? 'Assign to a Project' : 'Move to another project'}
        </Link>
    );
};

MoveProjectButton.propTypes = {
    projects: PropTypes.objectOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    projectsMine: PropTypes.objectOf(PropTypes.bool).isRequired,
    appId: PropTypes.string,
    isPersonalProject: PropTypes.bool,
};

MoveProjectButton.defaultProps = {
    isPersonalProject: false,
};

export default MoveProjectButton;
