import UiApiClient from 'common/helpers/uiApiClient';
import { snakifyObjKeys, cameliseObjKeys } from 'common/helpers/cameliseObjKeys';
import slugify from 'common/helpers/slugify';
import { getSession as getSessionAction } from 'sessions/state';
import {
    storeProject as storeProjectAction,
    storeProjects as storeProjectsAction,
    storeMyProject as storeMyProjectAction,
    storeMyProjects as storeMyProjectsAction,
    storeProjectMembers as storeProjectMembersAction,
    loadingProjects as loadingProjectsAction,
    loadedProjects as loadedProjectsAction,
    removeProject as removeProjectAction,
} from 'projects/state';

export function fetchProject(id) {
    return new UiApiClient().get(`projects/${id}`).then((response) => {
        const projectProperties = response.properties;
        return projectProperties;
    });
}

export function fetchProjectBySlug(projectSlug) {
    return new UiApiClient().get(`projects?slug=${projectSlug}`).then((response) => {
        const projectProperties = response.properties;

        return projectProperties;
    });
}

export function fetchProjects(store) {
    store.dispatch(loadingProjectsAction());

    return new UiApiClient(store).get('projects').then((response) => {
        const projectEntities = response.entities
            ? response.entities.map((entity) => entity.properties)
            : [];

        store.dispatch(storeProjectsAction(projectEntities));
        store.dispatch(loadedProjectsAction());

        return projectEntities;
    });
}

export function fetchApiProjects(store) {
    store.dispatch(loadingProjectsAction());

    return new UiApiClient(store).get('projects?with_apis=true').then((response) => {
        const projectEntities = response.entities
            ? response.entities.map((entity) => entity.properties)
            : [];

        store.dispatch(storeProjectsAction(projectEntities));
        store.dispatch(loadedProjectsAction());

        return projectEntities;
    });
}

export function fetchMyProjects(store) {
    const hasSession = store.dispatch(getSessionAction());
    if (!hasSession) return new Promise((resolve) => resolve([]));

    store.dispatch(loadingProjectsAction());

    return new UiApiClient(store).get('projects?mine=true').then((response) => {
        const projectEntities = response.entities
            ? response.entities.map((entity) => entity.properties)
            : [];

        store.dispatch(storeMyProjectsAction(projectEntities));
        store.dispatch(loadedProjectsAction());
    });
}

export function fetchProjectMembers(project) {
    return new UiApiClient().get(`projects/${project.id}/members`).then((response) => {
        let members = response.entities || [];

        if (members.length > 0) {
            members = members
                .filter((entity) => {
                    return entity.class.length == 1 && entity.class[0] == 'user';
                })
                .map((entity) => entity.properties);
        }

        return members;
    });
}

// This should fetch not only a project's properties, but
// also its member list.

// TODO: Refactor to extract dispatching into an action, or remove this function altogether and
// orchestrate separate action calls in the container.
export function fetchProjectWithMembers(store, slug) {
    let project;

    return fetchProjectBySlug(slug)
        .then((_project) => {
            store.dispatch(storeProjectAction(_project));
            project = _project;
            return fetchProjectMembers(project);
        })
        .then((members) => {
            store.dispatch(storeProjectMembersAction(slug, members));

            return {
                project,
                members,
            };
        });
}

export function createProject(store, project) {
    const projectWithDescriptionAndSlug = Object.assign(
        {
            description: 'DEPRECATED',
            slug: slugify(project.displayName),
        },
        project,
    );

    return new UiApiClient(store)
        .post('projects', { data: snakifyObjKeys(projectWithDescriptionAndSlug) })
        .then((response) => {
            store.dispatch(storeMyProjectAction(response.properties));
            return cameliseObjKeys(response.properties);
        });
}

export function updateProject(store, project) {
    const updatedProject = Object.assign({}, project, {
        description: 'DEPRECATED',
        slug: slugify(project.displayName),
    });

    return new UiApiClient(store)
        .put(`projects/${updatedProject.id}`, { data: snakifyObjKeys(updatedProject) })
        .then((response) => {
            store.dispatch(storeMyProjectAction(response.properties));
            return cameliseObjKeys(response.properties);
        });
}

export function addMember(store, { projectId, projectSlug, emailAddress }) {
    const data = {
        email: emailAddress,
    };

    const client = new UiApiClient(store);

    return client.post(`projects/${projectId}/members`, { data }).then(() => {
        return fetchProjectMembers({ id: projectId, slug: projectSlug }).then((members) => {
            store.dispatch(storeProjectMembersAction(projectSlug, members));
        });
    });
}

export function removeMember(store, formData) {
    const path = `/projects/${formData.projectId}/members/${formData.userId}`;
    return new UiApiClient(store).del(path, {}).then(() => {
        if (formData.userId === formData.currentUserId) {
            store.dispatch(removeProjectAction(formData.projectSlug));
        }
    });
}
