import { addMessage, flushAllMessages } from 'flashMessages/state';
import { redirect } from 'common/state/redirect';
import { deleteApp } from 'apps/services/appsService';

export default async function handleDeleteConfirmation(dispatch, data) {
    try {
        await deleteApp({ dispatch }, data.id);
        dispatch(
            redirect({
                pathname: data.redirectUrl,
            }),
        );
        dispatch(flushAllMessages());
        dispatch(addMessage('Your application has been deleted.'));
    } catch (error) {
        dispatch(flushAllMessages());
        dispatch(addMessage('There was an error deleting the app.', error));
    }
}
