import React, { useEffect } from 'react';

import { FormContext, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { addAppAttribute } from 'appAttributes/actions';

const { Fragment } = React;

const AddAttributeForm = ({ app }) => {
    const methods = useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        methods.setValue('app', app.id);
    }, []);

    function onSubmit(data) {
        dispatch(addAppAttribute(data));
        methods.reset();
    }

    return (
        <Fragment>
            <FormContext {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <table className="dp-table__no-margin-no-top-border">
                        <tbody>
                            <tr>
                                <td className="dp-table__cell gel-4/10">
                                    <input
                                        id="key"
                                        name="key"
                                        type="text"
                                        aria-label="Attribute Name"
                                        ref={methods.register}
                                        className="gel-form__input"
                                        data-testid="addKey"
                                    />
                                </td>
                                <td className="dp-table__cell gel-4/10">
                                    <input
                                        id="value"
                                        name="value"
                                        type="text"
                                        aria-label="Attribute Value"
                                        ref={methods.register}
                                        className="gel-form__input"
                                        data-testid="addValue"
                                    />
                                </td>
                                <td className="dp-table__cell gel-2/10">
                                    <button
                                        name="Add attribute"
                                        className="dp-o-button--primary dp-o-button--submit"
                                        data-testid="addAttributeButton"
                                    >
                                        Add Attribute
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <input name="app" type="hidden" ref={methods.register} />
                </form>
            </FormContext>
        </Fragment>
    );
};

export default AddAttributeForm;
