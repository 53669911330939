import React from 'react';

// React Components
import AuthButton from 'sessions/components/AuthButton';
import PropTypes from 'prop-types';
import NavButton from 'menu/components/NavButton';
import NavItem from 'menu/components/NavItem';

// Helper
import {
    getFirstNavigationListItem,
    itemFocus,
    toggleMenu,
    closeMenu,
    checkKeyBindingsFirstMenuItem,
    checkKeyBindingsLastMenuItem,
    checkKeyBindingsAnyMenuItem,
} from 'menu/helpers/navBarController';

// Feature toggle
import isFeatureActive from 'common/helpers/features';

const appsNewWithProjectId = /^\/apps\/new\/[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/i;

const navLinkMyProjectsConfig = {
    label: 'My Projects',
    href: '/projects',
    altBasepathHrefs: [
        '/projects/create',
        /^\/projects\/.+/,
        /^\/products\/.+/,
        appsNewWithProjectId,
    ],
};

const navLinkHomeConfig = {
    label: 'Home',
    href: '/',
    id: 'navigation-list__first-item',
    altBasepathHrefs: ['/login-required'],
};
const navLinkMyAppsConfig = {
    label: 'My Apps',
    href: '/my-apps',
    altBasepathHrefs: [
        '/apps',
        '/apps/new',
        /^\/apps\/(?!new).+/, // match /apps/*** but not /apps/new/*** because that matches 'My Projects'
        '/keys',
        /^\/keys\/.+/,
    ],
};

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuHidden: true,
        };

        this.navLinkMyAppsConfig = navLinkMyAppsConfig;
        this.navLinkHomeConfig = navLinkHomeConfig;
        this.navLinkMyProjectsConfig = navLinkMyProjectsConfig;

        this.toggleMenu = toggleMenu.bind(this);
        this.closeMenu = closeMenu.bind(this);
        this.checkKeyBindingsFirstMenuItem = checkKeyBindingsFirstMenuItem.bind(this);
        this.checkKeyBindingsLastMenuItem = checkKeyBindingsLastMenuItem.bind(this);
        this.checkKeyBindingsAnyMenuItem = checkKeyBindingsAnyMenuItem.bind(this);
    }

    componentDidUpdate() {
        const firstNavigationItem = getFirstNavigationListItem();

        if (!this.state.menuHidden) {
            itemFocus(firstNavigationItem);
        }
    }

    render() {
        const { activePathname, loggedIn, redirectUrl } = this.props;

        return (
            <nav id="navigation" aria-labelledby="accessibility-main-navigation-label">
                <div className="gs-u-vh" id="accessibility-main-navigation-label">
                    BBC developer portal
                </div>

                <NavButton
                    buttonId="navigation-menu-button"
                    menuId="navigation-menu"
                    menuHidden={this.state.menuHidden}
                    buttonClass="gel-menu-link"
                    onClick={this.toggleMenu}
                >
                    Menu
                </NavButton>

                <div className="navigation-list__container">
                    <ul
                        id="navigation-menu"
                        aria-labelledby="navigation-menu-button"
                        className={
                            'gel-wrap navigation-list' +
                            (this.state.menuHidden ? ' navigation-list--hidden' : '')
                        }
                    >
                        <li className="gel-nav-item">
                            <NavItem
                                navLinkConfig={this.navLinkHomeConfig}
                                activePathname={activePathname}
                                linkClass="gel-wrap gel-nav-item__link"
                                linkActiveClass="gel-nav-item__active"
                                onClick={this.closeMenu}
                                onKeyDown={(event) => {
                                    this.checkKeyBindingsAnyMenuItem(event);
                                    this.checkKeyBindingsFirstMenuItem(event);
                                }}
                            />
                        </li>

                        {loggedIn && (
                            <li className="gel-nav-item">
                                <NavItem
                                    id="nav-bar-button-my-apps"
                                    navLinkConfig={this.navLinkMyAppsConfig}
                                    activePathname={activePathname}
                                    linkClass="gel-wrap gel-nav-item__link"
                                    linkActiveClass="gel-nav-item__active"
                                    onClick={this.closeMenu}
                                    onKeyDown={this.checkKeyBindingsAnyMenuItem}
                                />
                            </li>
                        )}

                        {isFeatureActive('projects') && loggedIn && (
                            <li className="gel-nav-item">
                                <NavItem
                                    id="nav-bar-button-my-projects"
                                    navLinkConfig={this.navLinkMyProjectsConfig}
                                    activePathname={activePathname}
                                    linkClass="gel-wrap gel-nav-item__link"
                                    linkActiveClass="gel-nav-item__active"
                                    onClick={this.closeMenu}
                                    onKeyDown={this.checkKeyBindingsAnyMenuItem}
                                />
                            </li>
                        )}

                        <li className="gel-nav-item gs-u-float-right@m">
                            <AuthButton
                                loggedIn={loggedIn}
                                redirectUrl={redirectUrl}
                                linkClass="gel-wrap gel-nav-item__link"
                                onClick={this.closeMenu}
                                onKeyDown={(event) => {
                                    this.checkKeyBindingsLastMenuItem(event);
                                    this.checkKeyBindingsAnyMenuItem(event);
                                }}
                            />
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

NavBar.propTypes = {
    loggedIn: PropTypes.bool,
    redirectUrl: PropTypes.string,
    personalProject: PropTypes.string,
    activePathname: PropTypes.string,
};

export default NavBar;
