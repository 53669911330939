const logger = require('@bbc/developer-portal').logger({
    source: __filename,
});

async function prepareProjectsStore(projectService, store, newState, replace, next) {
    next(); // Allow middleware to operate without blocking render

    try {
        await projectService.fetchMyProjects(store);
    } catch (error) {
        return logger.error('Error fetching projects', {
            message: error.message,
            stack: error.stack,
        });
    }
}

export default (projectService) => {
    return prepareProjectsStore.bind(null, projectService);
};
