import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

const RemoveMember = (props) => {
    validateProps(props);

    return <Link to={createToPath(props)}>Remove</Link>;
};

const validateProps = ({ userId, slug }) => {
    if (!userId) {
        throw new TypeError('Required prop "userId" has not been set');
    }

    if (!slug) {
        throw new TypeError('Required prop "slug" has not been set');
    }
};

const createToPath = ({ userId, slug }) => `/projects/${slug}/members/${userId}/remove`;

RemoveMember.propTypes = {
    userId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
};

export default RemoveMember;
