import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from 'forms';

import FormFieldHelp from 'common/components/FormFieldHelp';

const CheckboxGroup = ({ name, description, label, options }) => {
    const { register } = useFormContext();

    return (
        <div className="gel-layout gs-u-mb+ gs-u-mt+">
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <div className="gel-form__field">
                    <label className="gel-form__label">{label}</label>
                    {options.map((option, index) => {
                        const inputName = `${name}[${option.value}]`;
                        const inputId = `${name}_${option.value}`;
                        return (
                            <div key={index} className="gs-u-mb">
                                <input
                                    value={option.value}
                                    ref={register}
                                    id={inputId}
                                    name={inputName}
                                    className="gs-u-mr"
                                    type="checkbox"
                                />
                                <label htmlFor={inputId}>{option.label}</label>
                            </div>
                        );
                    })}
                </div>
                <ErrorMessage field={name} />
            </div>
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <FormFieldHelp id={`${name}-field-help`}>{description}</FormFieldHelp>
            </div>
        </div>
    );
};

export default CheckboxGroup;
