import UiApiClient from 'common/helpers/uiApiClient';
import { cameliseObjKeys, snakifyObjKeys } from 'common/helpers/cameliseObjKeys';
import {
    storeApp,
    storeProjectApps,
    forgetApp,
    loadingApps,
    loadedApps,
    loadingApp,
    loadedApp,
} from 'apps/state';

export function createApp(store, appData) {
    return new UiApiClient(store)
        .post('apps', {
            data: snakifyObjKeys(appData),
        })
        .then((response) => {
            const app = responseToApp(response);
            const action = storeApp(app);
            store.dispatch(action);
            return app;
        });
}

export function updateApp(store, appId, appData) {
    return new UiApiClient(store)
        .put('apps/' + appId, {
            data: snakifyObjKeys(appData),
        })
        .then((response) => {
            const app = responseToApp(response);
            const action = storeApp(app);
            store.dispatch(action);
            return app;
        });
}

export function deleteApp(store, appId) {
    return new UiApiClient(store).del('apps/' + appId).then(() => {
        store.dispatch(forgetApp(appId));
    });
}

export function fetchApp(store, appId) {
    store.dispatch(loadingApp(appId));

    return new UiApiClient(store)
        .get('apps/' + appId)
        .then((response) => {
            const app = responseToApp(response);

            store.dispatch(storeApp(app));
            store.dispatch(loadedApp(appId));

            return app;
        })
        .catch((_error) => {
            // We're swallowing the error here because before it was never caught in the first place.
            // This was causing issues with Tests.
            // We don't have ErrorBoundaries in place at the moment, something we want to look into to
            // catch errors like this.
            // TODO: refactor error handling in services.
            store.dispatch(loadedApp(appId));
        });
}

export function fetchAppsForProject(projectId) {
    return new UiApiClient().get('apps?project=' + projectId).then((response) => {
        const apps = response.entities ? response.entities.map(responseToApp) : [];
        return apps;
    });
}

function responseToApp(response) {
    return cameliseObjKeys(response.properties);
}
