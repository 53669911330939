import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import clientBannerMagic from '../helpers/clientBannerMagic';
import isFeatureActive from 'common/helpers/features';

import DocumentTitle from 'common/components/DocumentTitle';
import LoadingPlaceholder from 'common/components/LoadingPlaceholder';
import HomeCallToAction from 'home/components/HomeCallToAction';
import HomeHelp from 'home/components/HomeHelp';
import HomeResourcesSection from 'home/components/HomeResourcesSection';
import HomeSecondmentAnnouncement from 'home/components/HomeSecondmentAnnouncement';

import getPersonalProjectSlug from 'projects/helpers/getPersonalProjectSlug';

const Home = () => {
    const loadingPlaceholderText = 'Loading data...';

    useEffect(() => {
        setTimeout(clientBannerMagic);
        window.addEventListener('resize', clientBannerMagic);

        return () => {
            window.removeEventListener('resize', clientBannerMagic);
        };
    });

    const projectSlug = useSelector((state) => {
        return getPersonalProjectSlug(state.session.session, state.projects.projects);
    });

    const project = useSelector((state) => {
        return state.projects.projects[projectSlug] || {};
    });

    const session = useSelector((state) => state.session.session);

    return (
        <DocumentTitle title={false}>
            <div>
                <div className="dp-c-banner-3d" />

                <section className="dp-main">
                    <header>
                        <h1 className="dp-h2 dp-o-header__welcome">
                            Welcome to the Developer Portal
                        </h1>
                    </header>

                    <div className="gel-layout">
                        <div className="gel-1/1 gel-10/12@l gel-layout__item">
                            {(!session || !isFeatureActive('projects')) && (
                                <p className="gel-long-primer">
                                    The BBC Developer Portal is a hub for developers across the BBC
                                    to access and contribute to APIs and relevant documentation.
                                </p>
                            )}

                            {!session && (
                                <React.Fragment>
                                    <p className="gs-u-mt+">
                                        <strong>
                                            Access is currently only available to registered BBC
                                            employees. If you require access please visit&nbsp;
                                            <a href="https://bbc-platform.slack.com/archives/C01GXS6PSLA">
                                                #access-foundations-help on Slack
                                            </a>
                                        </strong>
                                    </p>

                                    <a
                                        href="/_ppap/login/bbclogin"
                                        className="dp-o-button--cta gs-u-mt+ dp-e-login"
                                    >
                                        <i className="dp-o-icon dp-o-icon--signin gs-u-mr"></i>
                                        Log in
                                    </a>
                                </React.Fragment>
                            )}

                            {session && (
                                <LoadingPlaceholder
                                    waitFor={project.id}
                                    placeholder={loadingPlaceholderText}
                                >
                                    <HomeCallToAction projectId={project.id} />
                                </LoadingPlaceholder>
                            )}

                            {session && isFeatureActive('projects') && <HomeHelp />}
                        </div>
                    </div>
                </section>

                {session && !isFeatureActive('projects') && <HomeSecondmentAnnouncement />}

                <hr className="dp-hr" />

                <HomeResourcesSection />
            </div>
        </DocumentTitle>
    );
};

export default Home;
