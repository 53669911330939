export const createAppRules = {
    displayName: {
        required: true,
        ofLength: {
            min: 1,
            max: 100,
        },
        // TODO - does this block all-whitespace names?
        notBlank: true,
    },
    description: {
        required: true,
        // TODO - I don't know if these rules are right; can we import them from the API project
        ofLength: {
            min: 1,
            max: 5000,
        },
        notBlank: true,
    },
    projectId: {
        required: true,
        notBlank: true,
    },
};

export const editAppRules = {
    displayName: {
        required: true,
        ofLength: {
            min: 1,
            max: 100,
        },
        // TODO - does this block all-whitespace names?
        notBlank: true,
    },
    description: {
        required: true,
        // TODO - I don't know if these rules are right; can we import them from the API project
        ofLength: {
            min: 1,
            max: 5000,
        },
        notBlank: true,
    },
};

export const moveAppProjectRules = {
    project: {
        required: true,
        notBlank: true,
    },
};
