import React from 'react';
import PropTypes from 'prop-types';

const NavButton = ({ menuHidden, buttonClass, children, onClick }) => {
    return (
        <button
            id="navigation-menu-button"
            aria-controls="navigation-menu"
            aria-expanded={!menuHidden}
            className={buttonClass + (!menuHidden ? ' active' : '')}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default NavButton;

NavButton.propTypes = {
    menuHidden: PropTypes.bool.isRequired,
    buttonClass: PropTypes.string,
};
