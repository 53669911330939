import { addMessage, flushAllMessages } from 'flashMessages/state';

export default function dispatchError(dispatch, error) {
    dispatch(flushAllMessages());

    dispatch(
        addMessage('An error occurred when attempting to create or update your application', error),
    );

    const errorMessage = error.message || error.properties.message;

    errorMessage && dispatch(addMessage(errorMessage));
}
