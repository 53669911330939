// React
import React from 'react';
import { render } from 'react-dom';

// Routing
import routes from './routes';
import { Router, browserHistory } from 'react-router';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux';

// Stores
import { createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';

// Store reducers and middleware
import reducers from './state/reducers';
import clientMiddleware from './state/middleware';
import { reducer as formReducer } from 'redux-form';

document.addEventListener('DOMContentLoaded', () => {
    const reduxMiddleware = clientMiddleware();

    const composeEnhancers =
        __DEVELOPMENT__ && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  serialize: true,
              })
            : compose;

    const composedMiddleware = composeEnhancers(reduxMiddleware);

    const store = createStore(
        combineReducers({
            ...reducers,
            routing: routerReducer,
            form: formReducer,
        }),
        window.__store,
        composedMiddleware,
    );

    if (__DEVELOPMENT__) window.__store = store;

    const history = syncHistoryWithStore(browserHistory, store);
    let ignoreFirstLoad = true;

    const onRouterUpdate = () => {
        window.scrollTo(0, 0);

        if (ignoreFirstLoad) {
            ignoreFirstLoad = false;
        } else {
            let resetFocusItem = document.getElementsByTagName('h1')[0];
            resetFocusItem.setAttribute('tabindex', '-1');
            resetFocusItem.style.outline = 'none';
            resetFocusItem.focus();
        }
    };

    render(
        <Provider store={store}>
            <Router history={history} onUpdate={onRouterUpdate}>
                {routes(store)}
            </Router>
        </Provider>,
        document.getElementById('content'),
    );

    document.body.className += 'js';

    // Make "buttons" that are really links act a bit more like buttons:
    document.addEventListener('keypress', (e) => {
        if (e.which != 32) return;
        const el = document.activeElement; // not e.target;
        if (el.tagName != 'A') return;
        if (
            el.classList.contains('dp-o-button') ||
            Array.from(el.classList).some((c) => /\bbutton\b/i.test(c))
        ) {
            e.preventDefault();
            el.click();
        }
    });
});
