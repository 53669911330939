import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

const matchFoundForPattern = (activePathName, matchPattern) => {
    if (matchPattern instanceof RegExp) {
        return matchPattern.test(activePathName);
    } else {
        return activePathName === matchPattern;
    }
};

const checkNavLinkActiveClass = (
    activePathname,
    href,
    altBasepathHrefs,
    linkClass,
    linkActiveClass,
) => {
    const classNames = [linkClass];

    if (href === activePathname) {
        classNames.push(linkActiveClass);
    }

    if (altBasepathHrefs) {
        const matchFound = altBasepathHrefs.some((altHref) => {
            return matchFoundForPattern(activePathname, altHref);
        });

        if (matchFound === true) {
            classNames.push(linkActiveClass);
        }
    }

    return classNames.join(' ');
};

const NavItem = ({ navLinkConfig, activePathname, linkClass, linkActiveClass, ...props }) => {
    const { label, href, id, altBasepathHrefs } = navLinkConfig;
    const classNames = checkNavLinkActiveClass(
        activePathname,
        href,
        altBasepathHrefs,
        linkClass,
        linkActiveClass,
    );

    return (
        <Link id={id} className={classNames} to={href} {...props}>
            {label}
        </Link>
    );
};

export default NavItem;

NavItem.propTypes = {
    navLinkConfig: PropTypes.object.isRequired,
    activePathname: PropTypes.string.isRequired,
    linkClass: PropTypes.string.isRequired,
    linkActiveClass: PropTypes.string.isRequired,
};
