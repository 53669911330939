import React from 'react';
import PropTypes from 'prop-types';

const FormFieldHelp = ({ children, hideLabel, ...props }) => (
    <div {...props}>
        {!hideLabel && (
            <div
                role="presentation"
                className="gel-form__label gs-u-display-none gs-u-display-block@m gs-u-display-block@m"
            >
                &nbsp;
            </div>
        )}
        <div className="dp-c-form-field-help gs-u-mb++">
            <div className="dp-c-form-field-help__col--left">
                <i className="dp-o-icon dp-o-icon--info"></i>
            </div>
            <div className="dp-c-form-field-help__col--right">{children}</div>
        </div>
    </div>
);

export default FormFieldHelp;

FormFieldHelp.propTypes = {
    hideLabel: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element]),
};
