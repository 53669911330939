import React from 'react';

const HomeSecondmentAnnouncement = () => (
    <aside>
        <section className="dp-main gs-u-mt+">
            <header>
                <h3 className="gel-great-primer">Help improve this Developer Portal</h3>
            </header>

            <div className="gel-layout">
                <div className="gel-1/1 gel-8/12@l gel-layout__item">
                    <p className="gel-long-primer">
                        Want to help us improve this Developer Portal and the future of API
                        Management at the BBC?
                    </p>
                    <p className="gel-long-primer">
                        Access Foundations are currently offering secondments to interested engineers
                        across the BBC, wherever you are based. Contact{' '}
                        <a href="mailto:david.glover@bbc.co.uk">David Glover</a> for more
                        information.
                    </p>
                </div>
            </div>
        </section>
    </aside>
);

export default HomeSecondmentAnnouncement;
