export function formDataToProduct(data) {
    const separateByNewLine = (inputString) =>
        inputString
            .split('\n')
            .map((s) => s.trim())
            .filter(Boolean);

    const default_notify_emails = data.email ? separateByNewLine(data.email) : [];
    const resource_paths = data.resource_paths ? separateByNewLine(data.resource_paths) : [];

    const product = {
        description: data.description,
        display_name: data.display_name,
        environments: data.environments ? Object.values(data.environments).filter(Boolean) : [],
        http_verbs: data.http_verbs ? Object.values(data.http_verbs).filter(Boolean) : [],
        project: data.project,
        slug: data.product_identifier,
        auto_approve: data.authorisation !== 'manual',
        default_notify_emails,
        resource_paths,
    };

    const isPresent = (value) => {
        return value && value.length > 0;
    };

    if (
        isPresent(data.quota) &&
        isPresent(data.quota_time_unit) &&
        isPresent(data.quota_interval)
    ) {
        product.quota = parseInt(data.quota);
        product.quota_time_unit = data.quota_time_unit;
        product.quota_interval = parseInt(data.quota_interval);
    }

    if (isPresent(data.productUUID)) {
        product.id = data.productUUID;
    }

    return product;
}

export function productToFormData(product) {
    function arrayToObject(obj, currentValue) {
        obj[currentValue] = currentValue;
        return obj;
    }

    const data = {
        display_name: product.displayName,
        description: product.description,
        project: product.project,
        product_identifier: product.slug,
        productUUID: product.id,
        authorisation: product.autoApprove ? 'automatic' : 'manual',
        environments: product.environments ? product.environments.reduce(arrayToObject, {}) : {},
        http_verbs: product.httpVerbs
            ? product.httpVerbs.map((verb) => verb.toUpperCase()).reduce(arrayToObject, {})
            : {},
        quota: product.quota,
        quota_interval: product.quotaInterval,
        quota_time_unit: product.quotaTimeUnit,
        resource_paths: product.resourcePaths ? product.resourcePaths.join('\n') : '',
        email: product.defaultNotifyEmails ? product.defaultNotifyEmails.join('\n') : '',
    };

    return data;
}
