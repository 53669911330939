import React from 'react';
import { SubmitButton, HiddenField } from 'forms';

const RemoveMemberForm = () => {
    return (
        <React.Fragment>
            <SubmitButton
                name="confirm"
                submittingText="removing member"
                className="dp-o-button dp-o-button--delete"
            >
                Remove member
            </SubmitButton>

            <HiddenField name="projectSlug" />
            <HiddenField name="projectId" />
            <HiddenField name="userId" />
            <HiddenField name="currentUserId" />
        </React.Fragment>
    );
};

export default RemoveMemberForm;
