import { createSchema } from './schemas';

const validateOnCreate = async (data) => {
    try {
        const formattedData = Object.assign({}, data);
        formattedData.environments = Object.values(data.environments || {}).filter(Boolean);

        await createSchema.validate(formattedData, { abortEarly: false });

        return {
            errors: {},
            values: data,
        };
    } catch (err) {
        return {
            errors: err.inner.reduce((previousErrors, currentError) => {
                return {
                    ...previousErrors,
                    [currentError.path]: currentError,
                };
            }, {}),
            values: {},
        };
    }
};

export { validateOnCreate };
