import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from 'forms';

import FormFieldHelp from 'common/components/FormFieldHelp';

const Textarea = ({ name, description, label, placeholder }) => {
    const { register } = useFormContext();

    return (
        <div className="gel-layout">
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <div className="gel-form__field">
                    <label htmlFor={name} className="gel-form__label">
                        {label}
                    </label>
                    <textarea
                        id={name}
                        name={name}
                        ref={register}
                        className="gel-form__input"
                        aria-describedby={`${name}-field-help`}
                        placeholder={placeholder}
                    ></textarea>
                </div>
                <ErrorMessage field={name} />
            </div>
            <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                <FormFieldHelp id={`${name}-field-help`}>{description}</FormFieldHelp>
            </div>
        </div>
    );
};

export default Textarea;
