import { createAPIKey } from 'keys/services/keysService';
import { addMessage, flushAllMessages } from 'flashMessages/state';
import { redirect } from 'common/state/redirect';
import { deleteKey } from 'keys/services/keysService';

export function createKey(dispatch, data, replaceState, callback) {
    return createAPIKey(
        { dispatch },
        {
            app: data.app,
            apiProduct: data.product,
        },
    )
        .then((key) => {
            const redirectAction = redirect({
                pathname: `/keys/${key.id}`,
            });

            dispatch(redirectAction);
            dispatch(addMessage('Thanks. Your request has been submitted.'));
        })
        .catch((error) => {
            dispatch(flushAllMessages());
            dispatch(
                addMessage('An error occurred when attempting to create your new API key', error),
            );
        })
        .then(() => callback());
}

export function handleDeleteConfirmation(dispatch, data, replaceState, callback) {
    const displayError = (err) => {
        dispatch(flushAllMessages());
        dispatch(addMessage('There was an error deleting this key.', err));
    };

    deleteKey({ dispatch }, data.id).then(() => {
        dispatch(
            redirect({
                pathname: `/apps/${data.appId}`,
            }),
        );
        dispatch(flushAllMessages());
        dispatch(addMessage('Your API key has been deleted.'));
        callback();
    }, displayError);
}
