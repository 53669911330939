const TAB_KEY = 9;
const ESC_KEY = 27;

export function getFirstNavigationListItem() {
    return document.getElementById('navigation-list__first-item');
}

export function getNavButton() {
    return document.getElementById('navigation-menu-button');
}

export function getNavMenu() {
    return document.getElementById('navigation-menu');
}

export function getOpenHamburgerMenu() {
    return document.getElementsByClassName('gel-menu-link active');
}

export function itemFocus(item) {
    if (item) {
        let savedTabIndex = item.getAttribute('tabindex') || 0;
        let savedOutlineStyle = item.style.outline;
        item.setAttribute('tabindex', '-1');
        item.style.outline = 'none';
        item.focus();
        item.setAttribute('tabindex', savedTabIndex);
        item.style.outline = savedOutlineStyle;
    }
}

export function toggleMenu() {
    this.setState((prevState) => ({
        menuHidden: !prevState.menuHidden,
    }));
}

export function closeMenu() {
    this.setState(() => ({
        menuHidden: true,
    }));
}

export function checkKeyBindingsFirstMenuItem(event) {
    if ((event.shiftKey && event.key === 'Tab') || (event.shiftKey && event.keyCode === TAB_KEY)) {
        closeMenu.call(this);
    }
}

export function checkKeyBindingsLastMenuItem(event) {
    if (
        (event.key === 'Tab' && event.shiftKey !== true) ||
        (event.keyCode === TAB_KEY && event.shiftKey !== true)
    ) {
        closeMenu.call(this);
    }
}

export function checkKeyBindingsAnyMenuItem(event) {
    const navButton = getNavButton();

    if (event.keyCode === ESC_KEY) {
        closeMenu.call(this);
        itemFocus(navButton);
    }
}
