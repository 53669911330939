import React from 'react';
import { Link } from 'react-router';

const ProductList = ({ products }) => {
    const sortByName = (a, b) => {
        return a.displayName.localeCompare(b.displayName);
    };

    const renderRow = (product) => (
        <tr className="dp-table__row gs-o-list-ui__item" key={product.id}>
            <td className="dp-table__cell">
                <Link to={`/products/${product.id}`}>{product.displayName}</Link>
            </td>
            <td className="dp-table__cell">{product.slug || 'Unknown'}</td>
            <td className="dp-table__cell">
                {(product.environments && Object.values(product.environments).join(', ')) ||
                    'No environments'}
            </td>
        </tr>
    );

    return (
        <table className="gs-o-list-ui dp-table">
            <thead>
                <tr className="dp-table__header">
                    <td className="dp-table__cell">Product Name</td>
                    <td className="dp-table__cell">Product Identifier</td>
                    <td className="dp-table__cell">Deployment Environment(s)</td>
                </tr>
            </thead>
            <tbody>{products.sort(sortByName).map((product) => renderRow(product))}</tbody>
        </table>
    );
};

export default ProductList;
