import React from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Select } from 'forms';

import { updateAPIProduct } from 'products/handlers';
import { productToFormData } from 'products/services/productTransformers';

const MoveProductForm = ({ product, projects }) => {
    const projectOptions = Object.values(projects)
        .filter((project) => !project.owner)
        .map((project) => ({
            value: project.id,
            label: project.display_name,
        }));

    const methods = useForm({
        defaultValues: {
            project: product.project,
        },
    });

    const dispatch = useDispatch();

    const formattedProduct = productToFormData(product);

    const onSubmit = (data) => {
        const movedProduct = Object.assign({}, formattedProduct, {
            project: data.project,
        });

        updateAPIProduct(dispatch, movedProduct);
    };

    return (
        <FormContext {...methods}>
            <div>
                <h1 className="dp-h1 product-view__field display_name">{product.displayName}</h1>
            </div>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Select
                    name="project"
                    label="New project"
                    options={projectOptions}
                    description="The new project for this product"
                    includeBlank={false}
                />
                <div className="gel-layout">
                    <div className="gel-layout__item gel-6/12@m gel-5/12@xxl">
                        <button type="submit" className="dp-o-button--primary dp-o-button--full">
                            Move product into this project
                        </button>
                    </div>
                </div>
            </form>
        </FormContext>
    );
};
export default MoveProductForm;
